import { computed } from "mobx"
import ProductDriver from "../drivers/product.driver"
import { CanvasContextMenuPosition } from "../types/ui.types"
import { ViewType } from "../libs/products-render-config/types"

export class CanvasSpaceContextMenuUiController {
  private readonly productDriver: ProductDriver

  constructor(services: { productDriver: ProductDriver }) {
    this.productDriver = services.productDriver
  }

  public async showSpace(): Promise<void> {
    const { activeContext, activeSpace } = this.productDriver.state

    if (!activeSpace) {
      return
    }

    await this.productDriver.setView(ViewType.SPACE, activeContext, activeSpace)
  }

  public clearHighlight(): void {
    this.productDriver.clearHighlight()
  }

  @computed
  public get popoverPosition(): CanvasContextMenuPosition | undefined {
    const { activeViewType, activeSpace } = this.productDriver.state

    if (activeViewType !== "dieline" || !activeSpace) {
      return
    }

    const vdEditor = this.productDriver.getVdEditor()
    const space = vdEditor.spaceHighlightModule.findSpace({
      spaceId: activeSpace,
    })

    if (!space) {
      return
    }

    const boundingRect = space.getBoundingRect()
    const { height: sceneHeight } = vdEditor.getSceneDimensions()
    const scenePadding = 140
    const diff = vdEditor.getCanvasToSceneDiff()

    const left = boundingRect.left - diff.width / 2
    const top = boundingRect.top - diff.height / 2

    if (top + boundingRect.height >= sceneHeight - scenePadding) {
      return {
        position: "top",
        left: left + boundingRect.width / 2,
        top: top,
      }
    }

    return {
      position: "bottom",
      left: left + boundingRect.width / 2,
      top: top + boundingRect.height,
    }
  }
}
