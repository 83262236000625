import { VirtualDielineMigration } from "./virtual-dieline-migration.interface"
import { DesignVersion } from "../../../../../types/design.version"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import { PackhelpMaskObject } from "../../object-extensions/packhelp-objects"

export default class V301 implements VirtualDielineMigration {
  readonly designVersion = DesignVersion.v301

  public async migrate(vdEditor: VirtualDielineEditor): Promise<void> {
    for (const object of vdEditor.assetsModule.getEditableObjects()) {
      if (object.maskConfig?.type !== "covering") {
        continue
      }

      const mask = vdEditor.getCanvasObjectById(
        object.maskConfig.maskObjectId
      ) as PackhelpMaskObject

      if (!mask) {
        object.maskConfig = undefined
      }
    }
  }
}
