import React from "react"
import { observer } from "mobx-react-lite"
import { ImageToolbarController } from "../../../../../../controllers/toolbars/image-toolbar.controller"
import Colour from "../../../../../../models/colour"
import { ToolbarSection } from "../../Toolbar/ToolbarSection"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import { ToolbarCheckbox } from "../../../../molecules/ToolbarCheckbox/ToolbarCheckbox"
import { I18N } from "../../../../../i18n"
import { useTranslate } from "../../../../../hooks/useTranslate"

const i18n = I18N.toolbar.image

export interface ImageSectionProps {
  controller: ImageToolbarController
}

export const ImageSection = observer(function ({
  controller,
}: ImageSectionProps) {
  const t = useTranslate()

  const {
    isToolbarAvailable,
    isColorPickerAvailable,
    selectedColor,
    isUnitScalingAvailable,
    isUniScalingLocked,
    cmykColorList,
  } = controller

  if (!isToolbarAvailable) {
    return null
  }

  const onColorChange = (color: Colour) => {
    controller.setColor(color)
  }

  const onUniScalingToggle = () => {
    controller.toggleUniScaling()
  }

  return (
    <>
      {isColorPickerAvailable && (
        <ToolbarSection>
          <ColorConfigurator
            onColorSelect={onColorChange}
            selectedColor={selectedColor}
            analyticsSection="design-toolbar"
            analyticsName="image-color"
            colorsList={cmykColorList}
          />
        </ToolbarSection>
      )}

      {isUnitScalingAvailable && (
        <ToolbarSection>
          <ToolbarCheckbox
            onChange={onUniScalingToggle}
            label={t(i18n.keepProportions)}
            checked={isUniScalingLocked}
            analyticsName="keep-proportions"
            analyticsId={isUniScalingLocked ? "enable" : "disable"}
          />
        </ToolbarSection>
      )}
    </>
  )
})
