import React, { useEffect, useRef, useState } from "react"
import cxBinder from "classnames/bind"
import Card, { CardType, HeightType } from "../../../atoms/Card/Card"
import { bodyModalLock } from "./utils/modal.helpers"
import { useMatchQuery } from "../../../common/hooks"
import { useOutsideClick } from "../../../common/hooks/use-outside-click"
import { ModalProps } from "./utils/modal.types"
import styles from "./Modal.module.scss"

const cx = cxBinder.bind(styles)

export enum ModalType {
  Default = "",
  SideModal = "wrapper--side-modal",
  PlainModal = "wrapper--plain-modal"
}

export enum ModalTopSpacingType {
  Default = "",
  Medium = "wrapper--top-spacing-md",
  Large = "wrapper--top-spacing-lg",
  ExtraLarge = "wrapper--top-spacing-x-lg"
}

const cardDefaultProps = {
  type: CardType.Round,
  withShadow: true
}

export const Modal = ({
  align,
  cardProps = {},
  children,
  onCloseAction,
  open,
  e2eTarget = "modal",
  e2eTargetName,
  topSpacing = ModalTopSpacingType.Default,
  type = ModalType.Default,
  ...other
}: Partial<ModalProps>) => {
  const [modalHeight, setModalHeight] = useState<number | undefined>(undefined)

  const isMobile = useMatchQuery()

  const modalRef = useRef(null)

  useOutsideClick(modalRef, onCloseAction || (() => {}), open)

  useEffect(() => {
    bodyModalLock(!open)

    return () => bodyModalLock(true)
  }, [open])

  useEffect(() => {
    const getOffset = (): number => {
      switch (topSpacing) {
        case ModalTopSpacingType.Medium:
          return 24
        case ModalTopSpacingType.Large:
          return 48
        case ModalTopSpacingType.ExtraLarge:
          return 64
        default:
          return 0
      }
    }

    if (isMobile && open) {
      setModalHeight(window.innerHeight - getOffset())
    }

    return () => setModalHeight(undefined)
  }, [isMobile, open])

  if (isMobile && !cardProps.heightType) {
    cardProps.heightType = HeightType.High
  }

  return open ? (
    // @ts-ignore
    <div
      className={cx("wrapper", {
        [type]: type,
        [topSpacing]: topSpacing
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      <div
        className={cx("inner", {
          "inner--align-left": align === "left",
          "inner--align-right": align === "right",
          "inner--content-left": align === "content-left",
          "inner--content-right": align === "content-right"
        })}
        style={{ height: modalHeight }}
      >
        <Card
          {...(isMobile && { heightType: HeightType.High })}
          {...cardDefaultProps}
          {...cardProps}
        >
          <div className={styles["modal-content"]} ref={modalRef}>
            {children}
          </div>
        </Card>
      </div>
    </div>
  ) : null
}
