import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
} from "../../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { cloneGroupObject } from "../helpers"
import { EditableGroupController } from "../editable-group.controller"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { DuplicationOptions } from "../../canvas-object-controller/types"
import { MaskDuplicationController } from "../../mask-controller/mask-duplication.controller"

export class DuplicationController {
  constructor(
    private readonly group: PackhelpEditableGroup,
    private readonly vdEditor: VirtualDielineEditor
  ) {}

  public async duplicate(
    options: DuplicationOptions = {
      withRender: true,
    }
  ): Promise<PackhelpEditableGroup> {
    const clonedObjects = await this.cloneObjects()

    const groupController = new EditableGroupController(this.vdEditor)

    const clonedGroup = await groupController.init(
      options.newSpaceId || this.group.originSpaceArea,
      clonedObjects
    )

    const newPosition = fabric.util.rotatePoint(
      new fabric.Point(clonedGroup.left!, clonedGroup.top!),
      this.group.getCenterPoint(),
      fabric.util.degreesToRadians(this.group.angle!)
    )

    clonedGroup.set({
      left: newPosition.x,
      top: newPosition.y,
      angle: this.group.angle!,
    })

    const maskDuplicationController = new MaskDuplicationController(
      this.group,
      this.vdEditor
    )
    await maskDuplicationController.duplicateForIfNeeded(clonedGroup, options)

    if (options.withRender) {
      this.vdEditor.addOnCanvas(clonedGroup, true)
    }

    return clonedGroup
  }

  private async cloneObjects(): Promise<PackhelpEditableObject[]> {
    const clonedObjects: PackhelpEditableObject[] = []
    const groupAngle = this.group.angle!

    for (const object of this.group.getObjects()) {
      if (!object.assetType) {
        continue
      }

      const clonedObject = await cloneGroupObject(object)

      const newPosition = fabric.util.rotatePoint(
        new fabric.Point(clonedObject.left!, clonedObject.top!),
        new fabric.Point(this.group.left!, this.group.top!),
        fabric.util.degreesToRadians(-groupAngle)
      )

      clonedObject.set({
        left: newPosition.x,
        top: newPosition.y,
        angle: clonedObject.angle! - groupAngle,
        clipPath: undefined,
      })

      clonedObjects.push(clonedObject)
    }

    return clonedObjects
  }
}
