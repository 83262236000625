import Basil from "shared-libs/src/js/libs/storage/basil.js"
import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import {
  getServerInstance,
  getServerInstanceName,
  serverInstanceTypes,
} from "../../../configs/region-config/interfaces/js/env-config"

export const X_DOMAIN_API_TOKEN_COOKIE_NAME = "z-x-subdomain-api-token"
export const X_DOMAIN_LOGIN_STATUS_COOKIE_NAME = "z-x-subdomain-is-logged-in"
export const LOGIN_COOKIE_NAME = "is-logged-in"
export const REGION_COOKIE_NAME = "z-region-cookie"
export const API_TOKEN_COOKIE_NAME = "api-token"
export const BNPL_COOKIE_NAME = "z-bnpl-cookie"

export const cookieKeyWithServerInstance = (key: string) => {
  const name = getServerInstanceName()
  return `${name}-${key}`
}

export const loginCookieKeyBasedOnServerInstance = () => {
  const serverInstance = getServerInstance()
  const cookie = cookieKeyWithServerInstance(LOGIN_COOKIE_NAME)

  switch (serverInstance) {
    case serverInstanceTypes.DEVELOPMENT:
    case serverInstanceTypes.STAGING:
    case serverInstanceTypes.LOCAL:
      return cookie
    default:
      // default for production to keep existing users happy
      return X_DOMAIN_LOGIN_STATUS_COOKIE_NAME
  }
}

export const apiTokenCookieKeyBasedOnServerInstance = () => {
  const serverInstance = getServerInstance()
  const cookie = cookieKeyWithServerInstance(API_TOKEN_COOKIE_NAME)

  switch (serverInstance) {
    case serverInstanceTypes.DEVELOPMENT:
    case serverInstanceTypes.STAGING:
    case serverInstanceTypes.LOCAL:
      return cookie
    default:
      // default for production to keep existing users happy
      return X_DOMAIN_API_TOKEN_COOKIE_NAME
  }
}

export const userGlobalLoginStateCookie = {
  cookieKey: loginCookieKeyBasedOnServerInstance(),
  setLoggedIn: () => {
    Basil.cookie.set(loginCookieKeyBasedOnServerInstance(), true, {
      expireDays: 31,
      domain: RegionConfig.getDomainHostnameWithDot(),
    })
  },
  setLoggedOut: () => {
    Basil.cookie.remove(loginCookieKeyBasedOnServerInstance())
  },
  isLoggedIn: () => !!Basil.cookie.get(loginCookieKeyBasedOnServerInstance()),
}
