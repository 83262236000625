import React from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import styles from "./EditZoneRulers.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Dimensions } from "../../atoms/Dimensions/Dimensions"

const cx = cxBinder.bind(styles)

export const EditZoneRulers = observer(function () {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil } = containerSet
  const { productDriver } = designAndProductDriver
  const { appConfig } = envUtil
  const {
    activeContext,
    activeSpace,
    productRenderPilot,
    isSpaceZoomActive,
    isRendererLoading,
  } = productDriver.state

  if (!activeContext || !activeSpace || isRendererLoading) {
    return null
  }

  const dielineNavigator = productDriver.getDielineNavigator()

  if (dielineNavigator.isSpaceLoading) {
    return null
  }

  const editZoneConfig = productDriver.getDielineNavigator().editZoneConfig

  if (editZoneConfig === null) {
    return null
  }

  const { size, label } = editZoneConfig

  const rulerWidth = size.width
  const rulerHeight = size.height
  const MANUALLY_ADJUSTED_RULER_OFFSET = 35

  const { rulers: rulersConfig, controlsTheme } =
    productRenderPilot.uiConfig.editZone

  const rulersClassName = cx({
    ruler: true,
    /**
     * Edit Zone Rulers are located outside Edit Zone,
     * so if zoom is active we need to show dashed lines.
     */
    dashed: isSpaceZoomActive || rulersConfig.lines === "dashed",
    /**
     * Edit Zone Rulers are located outside Edit Zone.
     *
     * If zoom is active (no product background),
     * rulers are placed on a light background (page background, which is light gray),
     * so they need to be dark.
     */
    light: !isSpaceZoomActive && controlsTheme === "light",
  })

  return (
    <div className={styles.wrapper}>
      <div
        className={rulersClassName}
        style={{
          width: rulerWidth,
          top: `calc(50% - ${
            rulerHeight / 2 + MANUALLY_ADJUSTED_RULER_OFFSET
          }px)`,
          left: `calc(50% - ${rulerWidth / 2}px)`,
        }}
      >
        {rulersConfig.dimensions && (
          <span>
            <Dimensions
              dimensionsCm={[label.width]}
              displayUnit={appConfig.locale.displayUnit}
            />
          </span>
        )}
      </div>

      <div
        className={rulersClassName}
        style={{
          width: rulerHeight,
          top: `calc(50% - ${rulerHeight / 2}px)`,
          left: `calc(50% - ${
            rulerWidth / 2 + MANUALLY_ADJUSTED_RULER_OFFSET
          }px)`,
          transform: `translateY(${rulerHeight}px) rotate(-90deg)`,
          transformOrigin: "left top",
        }}
      >
        {rulersConfig.dimensions && (
          <span>
            <Dimensions
              dimensionsCm={[label.height]}
              displayUnit={appConfig.locale.displayUnit}
            />
          </span>
        )}
      </div>
    </div>
  )
})
