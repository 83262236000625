import { action, makeObservable, observable, toJS } from "mobx"
import _cloneDeep from "lodash/cloneDeep"
import { Debug } from "../services/logger"
import {
  RenderEngine,
  RenderEngineExportOptions,
} from "../render-engine/render-engine"
import { RenderEngineExport, RendererMountPoints } from "../render-engine/types"
import {
  EditContext,
  ModelContext,
  SpaceId,
  ViewMode,
  ViewType,
} from "../libs/products-render-config/types"
import ActiveObjectDriver from "./active-object.driver"
import { AssetsDriver } from "./assets.driver"
import ConverterDriver from "./converter.driver"
import BackgroundsDriver from "./backgrounds.driver"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { ProductDesignStore } from "../stores/product-design.store"
import { ProductDesignModificationListener } from "../services/product-design-modification.listener"
import VirtualDielineEditor from "../render-engine/modules/vd-editor/virtual-dieline-editor"
import { ProductRenderPilotFactory } from "../libs/products-render-config/product-render-pilot-factory"
import { ProductStore } from "../stores/product.store"
import { DielineNavigator } from "../render-engine/modules/vd-editor/modules/dieline-navigator/dieline-navigator"
import EventEmitter from "eventemitter3"
import { RenderEngineBuilder } from "../render-engine/services/render-engine.builder"
import { DielineStore } from "../stores/dieline.store"
import {
  HighlightDefinition,
  HighlightMode,
} from "../render-engine/modules/vd-editor/modules/space-highlight-module"
import { isInteractiveCanvas } from "../types/asset.types"
import { VariantCustomization } from "@ph/product-api"
import { CanvasEvent } from "../events/partials/domain.events"
import { IEvent } from "fabric/fabric-impl"

const debug = Debug("ph:editor:stores:product-design")

type ProductDriverState = {
  renderEngine: RenderEngine | undefined
  isRendererLoading: boolean
  isVirtualDielineLoading: boolean
  isProductLoading: boolean
  isProductChanging: boolean
  isLoading3DModel: boolean
  isDesignPreviewMode: boolean
  modelContext: ModelContext
  previousViewType: ViewType
  activeViewType: ViewType
  activeContext: EditContext
  activeSpace: SpaceId | null
  productRenderPilot: ProductRenderPilot
  vdMountPoints: RendererMountPoints["vdMountPoints"]
  rendererMountPoint: HTMLDivElement | undefined
  isSpaceZoomActive: boolean
  dielineZoom: number
  isEditModeEventListenersAttached: boolean
  isSelectingAssetPlacement: boolean
  highlightedSpaceId: SpaceId | null
  isDragModeActive: boolean
}

type RenderEngineSnapshotOptions = { force?: boolean; withPreview?: boolean }

enum CURSORS {
  INITIAL = "initial",
  POINTER = "pointer",
  GRAB = "grab",
}

export class ProductDriver {
  public readonly minDielineZoom = 1
  public readonly maxDielineZoom = 4

  @observable public activeObjectDriver: ActiveObjectDriver
  @observable public backgroundsDriver: BackgroundsDriver
  public state: ProductDriverState
  public readonly assetsDriver: AssetsDriver
  public readonly productStore: ProductStore
  public readonly eventEmitter: EventEmitter

  private converterDriver: ConverterDriver | undefined
  private renderExportSnapshot: RenderEngineExport | null = null
  private tempResizeRenderExportSnapshot: RenderEngineExport | null = null
  private readonly productDesignTouchListener: ProductDesignModificationListener
  private readonly renderEngineBuilder: RenderEngineBuilder
  private readonly productDesignStore: ProductDesignStore
  private readonly dielineStore: DielineStore
  private readonly ee: AllEditorEventsEmitter

  constructor(
    services: {
      renderEngineBuilder: RenderEngineBuilder
      productRenderPilot: ProductRenderPilot
      eventEmitter: EventEmitter
      ee: AllEditorEventsEmitter
    },
    stores: {
      productStore: ProductStore
      productDesignStore: ProductDesignStore
      dielineStore: DielineStore
    }
  ) {
    this.renderEngineBuilder = services.renderEngineBuilder
    this.eventEmitter = services.eventEmitter
    this.ee = services.ee
    this.productStore = stores.productStore
    this.productDesignStore = stores.productDesignStore
    this.dielineStore = stores.dielineStore

    const zoomConfig = services.productRenderPilot.uiConfig.editZone.zoom
    const isSpaceZoomActive = zoomConfig.available && zoomConfig.activeByDefault
    const defaultView = services.productRenderPilot.getDefaultView()

    this.state = observable<ProductDriverState>({
      renderEngine: undefined,
      isRendererLoading: true,
      isProductLoading: false,
      isProductChanging: false,
      isVirtualDielineLoading: true,
      isDesignPreviewMode: this.productDesignStore.isDesignReadOnly,
      modelContext: ModelContext.CLOSED,
      previousViewType: defaultView.viewType,
      activeViewType: defaultView.viewType,
      activeContext: defaultView.editContext,
      activeSpace:
        defaultView.viewType === ViewType.SPACE ? defaultView.spaceId : null,
      isLoading3DModel: false,
      productRenderPilot: services.productRenderPilot,
      vdMountPoints: {},
      rendererMountPoint: undefined,
      isSpaceZoomActive: isSpaceZoomActive,
      dielineZoom: 1,
      isEditModeEventListenersAttached: false,
      isSelectingAssetPlacement: false,
      highlightedSpaceId: null,
      isDragModeActive: false,
    })

    this.activeObjectDriver = new ActiveObjectDriver(this, this.ee)
    this.backgroundsDriver = new BackgroundsDriver(this)
    this.assetsDriver = new AssetsDriver(this)
    this.productDesignTouchListener = new ProductDesignModificationListener(
      this.eventEmitter,
      this.ee
    )

    this.ee.on(eventTree.pd.skuChangeStarted, this.onChangeProductSkuStarted)
    this.ee.on(eventTree.pd.skuChangeEnded, this.onChangeProductSkuEnded)
    this.ee.on(
      eventTree.graphics.maskAttached,
      this.onVirtualDielineObjectAdded
    )
    this.ee.on(
      eventTree.keyboard.spaceDown,
      this.toggleDragMode.bind(this, true)
    )
    this.ee.on(
      eventTree.keyboard.spaceUp,
      this.toggleDragMode.bind(this, false)
    )

    makeObservable(this)

    globalThis.productDriver = this
  }

  public async changeSku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    this.ee.emit(eventTree.pd.skuChangeStarted)

    const product = await this.productStore.setProductBySku(sku, customization)
    const productRenderPilot = await ProductRenderPilotFactory(
      product,
      ProductDesignStore.designFormatVersion,
      this.state.productRenderPilot.getEditorMode()
    )
    await this.dielineStore.loadDielineUrls(productRenderPilot)

    this.ee.emit(
      eventTree.pd.skuChangeEnded,
      toJS(this.productStore.productSku),
      productRenderPilot
    )
  }

  @action
  public async setIsSpaceZoomActive(isActive: boolean): Promise<void> {
    const zoomConfig = this.state.productRenderPilot.uiConfig.editZone.zoom

    if (this.state.isSpaceZoomActive === isActive || !zoomConfig.available) {
      return
    }

    this.setRendererLoading(true)
    this.state.isSpaceZoomActive = isActive
    await this.converterDriver?.refresh(this.state.productRenderPilot)
    this.setRendererLoading(false)
  }

  public zoomDieline(value: number): void {
    const { activeViewType, dielineZoom } = this.state

    if (activeViewType !== "dieline") {
      return
    }

    const vdEditor = this.getVdEditor()
    this.setDielineZoom(dielineZoom + value)
    this.state.renderEngine?.clearActiveObject()
    this.clearHighlightSelection()
    vdEditor.zoomDieline(this.state.dielineZoom)
  }

  public rotateView(): void {
    const vdEditor = this.getVdEditor()
    this.clearHighlightSelection()

    vdEditor.rotateView()
  }

  public async toggleDragMode(
    isActive = !this.state.isDragModeActive
  ): Promise<void> {
    const { activeViewType, isDragModeActive, isDesignPreviewMode } = this.state

    if (
      isDesignPreviewMode ||
      activeViewType !== "dieline" ||
      isDragModeActive === isActive
    ) {
      return
    }

    const vdEditor = this.getVdEditor()
    this.setIsDragModeActive(isActive)
    this.clearHighlightSelection()

    if (this.state.isDragModeActive) {
      await vdEditor.enterDragMode()
    } else {
      await vdEditor.escapeDragMode()
    }
  }

  @action
  private setDielineZoom(zoom: number): void {
    this.state.dielineZoom = Math.min(
      Math.max(zoom, this.minDielineZoom),
      this.maxDielineZoom
    )
  }

  @action
  private setIsDragModeActive(isActive: boolean): void {
    this.state.isDragModeActive = isActive
  }

  @action
  public async onDeregisterMountPoints(): Promise<void> {
    const { renderEngine } = this.state

    if (!renderEngine) {
      return
    }

    this.setVirtualDielineLoading(true)
    this.setRendererLoading(true)

    renderEngine.clearActiveObject()
    await this.cacheDesignData()
    renderEngine.unMountRenderEngine()
    this.setRenderEngine(undefined)
    this.ee.emit(eventTree.productDriver.renderEngineDeregistered)
  }

  @action
  public async renderEngineDomMounter({
    rendererMountPoint,
    vdMountPoints,
  }: RendererMountPoints): Promise<void> {
    this.state.rendererMountPoint = rendererMountPoint
    this.state.vdMountPoints = vdMountPoints

    await this.initRenderer({
      rendererMountPoint,
      vdMountPoints,
    })
  }

  @action
  public initDby(): void {
    this.setRendererLoading(false)
    this.setVirtualDielineLoading(false)

    this.ee.emit(eventTree.productDriver.dbyEngineInitiated)
  }

  @action
  public async initRenderer({
    rendererMountPoint,
    vdMountPoints,
  }: RendererMountPoints): Promise<void> {
    this.setVirtualDielineLoading(true)
    this.setRendererLoading(true)

    const { productRenderPilot, modelContext } = this.state

    const renderEngine = await this.renderEngineBuilder.init(
      productRenderPilot,
      {
        virtualDielines: vdMountPoints,
        threeDimensionalRenderer: productRenderPilot.isViewModeAvailable(
          ViewMode.THREE_DIMENSIONAL
        )
          ? rendererMountPoint
          : undefined,
      },
      modelContext
    )

    this.setRenderEngine(renderEngine)

    if (this.tempResizeRenderExportSnapshot) {
      const initRenderExport = _cloneDeep(this.tempResizeRenderExportSnapshot)
      this.setRenderExportSnapshot(initRenderExport)
      await renderEngine.loadDesign(initRenderExport)
      this.tempResizeRenderExportSnapshot = null

      // ! We must set design as touched to be able to save changes we've made before resizing the window
      this.productDesignStore.setDesignTouched(true)
    } else if (this.productDesignStore.isDesignCreated) {
      const productDesignData = this.productDesignStore.getEditorDesignData()
      debug(`loading product desing data to renderer`, productDesignData)
      // cloneDeep fix reference fabric filter error.
      // Bootstrap after screen resizing - for further reference and debug
      const initRenderExport = _cloneDeep(productDesignData)
      this.setRenderExportSnapshot(initRenderExport)
      await renderEngine.loadDesign(initRenderExport)
    }

    if (!this.state.isDesignPreviewMode) {
      await renderEngine.prepareEditing()
    }

    this.setActiveObjectDriver(this)
    this.setConverterDriver(this)
    this.assetsDriver.init()
    this.backgroundsDriver.init()

    await this.forceView(
      this.state.activeViewType,
      this.state.activeContext,
      this.state.activeSpace
    )

    if (this.state.isDesignPreviewMode) {
      for (const vdEditor of renderEngine.getVirtualDielineEditors()) {
        await vdEditor.setPreviewMode()
      }
    }

    this.ee.emit(eventTree.productDriver.renderEngineInitiated)
    this.setRendererLoading(false)
    this.setVirtualDielineLoading(false)
  }

  public async switchToFullProductView(): Promise<void> {
    const { activeViewType, activeContext, productRenderPilot, dielineZoom } =
      this.state

    const vdEditor = this.getVdEditor()
    vdEditor.resetRotation()
    this.zoomDieline(-dielineZoom)

    if (activeViewType !== "space") {
      this.clearHighlightSelection()

      return
    }

    const availableViewTypes = productRenderPilot.getAvailableViewTypes()

    if (availableViewTypes.includes(ViewType.MODEL)) {
      return this.forceView(ViewType.MODEL, activeContext, null)
    }

    if (availableViewTypes.includes(ViewType.DIELINE)) {
      return this.forceView(ViewType.DIELINE, activeContext, null)
    }
  }

  public setDesignTouched(touched: boolean): void {
    this.productDesignStore.setDesignTouched(touched)
  }

  public clearHighlight(): void {
    const vdEditor = this.getVdEditor()
    vdEditor.spaceHighlightModule.clearHighlight()
  }

  public clearHighlightSelection(): void {
    const { activeViewType, activeSpace, isRendererLoading } = this.state

    if (isRendererLoading) {
      return
    }

    const vdEditor = this.getVdEditor()
    vdEditor.spaceHighlightModule.clearHighlightSelection()

    if (activeViewType === "dieline" && activeSpace) {
      this.setEditSpace(null)
    }
  }

  @action
  private setRenderEngine(renderEngine): void {
    this.state.renderEngine = renderEngine
  }

  @action
  private setProductRenderPilot(productRenderPilot: ProductRenderPilot): void {
    this.state.productRenderPilot = productRenderPilot

    const availableEditContexts = productRenderPilot.getAvailableEditContexts()

    if (!availableEditContexts.includes(this.state.activeContext)) {
      this.state.activeContext = productRenderPilot.getDefaultEditContext()
    }
  }

  @action
  public setIsProductChanging(isChanging: boolean): void {
    this.state.isProductChanging = isChanging
  }

  @action
  private setRendererLoading(isLoading: boolean): void {
    this.state.isRendererLoading = isLoading
  }

  @action
  private setVirtualDielineLoading(isLoading: boolean): void {
    this.state.isVirtualDielineLoading = isLoading
  }

  @action
  private setActiveObjectDriver(context): void {
    if (!this.activeObjectDriver) {
      this.activeObjectDriver = new ActiveObjectDriver(context, this.ee)
    }
  }

  @action
  private setConverterDriver(context): void {
    this.converterDriver = new ConverterDriver(context, this.ee)
  }

  @action
  public async changeModelContext(modelContext: ModelContext): Promise<void> {
    if (
      this.state.isLoading3DModel ||
      this.state.modelContext === modelContext
    ) {
      return
    }

    this.setModelContext(modelContext)

    const { renderEngine } = this.state

    this.setLoading3DModel(!renderEngine?.isModelFoldingSupported())

    await renderEngine?.changeModelContext(modelContext)

    this.setLoading3DModel(false)
  }

  public async reloadModel(): Promise<void> {
    this.setLoading3DModel(true)

    const { renderEngine } = this.state
    await renderEngine?.reloadModel(this.state.modelContext)

    this.setLoading3DModel(false)
  }

  @action
  private setLoading3DModel(isLoading): void {
    this.state.isLoading3DModel = isLoading
  }

  @action
  private setModelContext(context: ModelContext): void {
    this.state.modelContext = context
  }

  @action
  public async setView(
    viewType: ViewType,
    editContext: EditContext,
    spaceId: SpaceId | null
  ): Promise<void> {
    const {
      isVirtualDielineLoading,
      isRendererLoading,
      activeSpace,
      activeContext,
      activeViewType,
    } = this.state

    if (isVirtualDielineLoading || isRendererLoading) {
      return
    }

    if (
      viewType === activeViewType &&
      editContext === activeContext &&
      spaceId === activeSpace
    ) {
      return
    }

    await this.forceView(viewType, editContext, spaceId)

    this.ee.emit(eventTree.productDriver.viewChanged, {
      space: spaceId,
      context: editContext,
      viewType: viewType,
      previousSpace: activeSpace,
    })
  }

  public async refreshView(): Promise<void> {
    const { activeViewType, activeContext, activeSpace } = this.state

    return this.forceView(activeViewType, activeContext, activeSpace)
  }

  @action
  public setIsSelectingAssetPlacement(isSelecting: boolean): void {
    this.state.isSelectingAssetPlacement = isSelecting

    this.refreshHighlightMode()
  }

  private async forceView(
    viewType: ViewType,
    editContext: EditContext,
    spaceId: SpaceId | null
  ): Promise<void> {
    await this.clearView()
    this.setActiveViewType(viewType)
    this.refreshHighlightMode()

    if (viewType === ViewType.MODEL) {
      return this.showModel(editContext)
    }

    if (viewType === ViewType.DIELINE) {
      return this.showDieline(editContext)
    }

    if (viewType === ViewType.SPACE && spaceId) {
      return this.showSpace(editContext, spaceId)
    }
  }

  private async clearView(): Promise<void> {
    const { activeViewType } = this.state
    const vdEditor = this.getVdEditor()

    await this.toggleDragMode(false)
    await vdEditor.setCanvasElementSize(vdEditor.getCanvasDimensions())
    await vdEditor.escapeEditMode()
    await vdEditor.resetDielinePosition()
    await this.setHighlightedSpaceId(null)

    if (activeViewType === ViewType.MODEL) {
      return this.clearModelEventListeners()
    }

    if (activeViewType === ViewType.DIELINE) {
      this.clearDielineEventListeners()

      return vdEditor.hideDieline()
    }
  }

  @action
  public setEditContext(editContext: EditContext): void {
    const { activeContext, productRenderPilot } = this.state

    if (activeContext === editContext) {
      return
    }

    if (!productRenderPilot.isPrintAvailableFor(editContext)) {
      return
    }

    this.state.activeContext = editContext
  }

  private async showDieline(editContext: EditContext): Promise<void> {
    const vdEditor = this.getVdEditor(editContext)

    this.setVirtualDielineLoading(true)

    const { width, height } = vdEditor.getCanvasDimensions()
    await vdEditor.setCanvasElementSize({
      width: width * 2,
      height: height * 2,
    })
    this.setDielineZoom(1)
    this.setEditContext(editContext)
    this.setEditSpace(null)

    if (!this.state.isDesignPreviewMode) {
      this.productDesignTouchListener.attachEditModeEventListeners()
      await vdEditor.enterEditMode()
    }

    await vdEditor.showDieline()
    this.attachDielineEventListeners()

    this.setVirtualDielineLoading(false)
  }

  private async showModel(editContext: EditContext): Promise<void> {
    const renderEngine = this.getRenderEngine()
    const { modelContext, productRenderPilot } = this.state

    const renderer = renderEngine.getThreeDimRenderer()

    if (!renderer) {
      return
    }

    this.setVirtualDielineLoading(true)

    renderEngine.set2dInterfaceObjectsVisibility(false)
    renderEngine.rerender()

    const newModelContext =
      productRenderPilot.getModelContextByEditContext(editContext)

    if (newModelContext === modelContext) {
      renderer.touchTextures()
    } else {
      await this.changeModelContext(newModelContext)
    }

    this.setEditContext(editContext)
    this.setEditSpace(null)

    this.attachModelEventListeners()
    this.productDesignTouchListener.clearEditModeEventListeners()
    this.setVirtualDielineLoading(false)
  }

  public async generateDielinePreview(
    editContext: EditContext
  ): Promise<string | undefined> {
    const renderEngine = this.getRenderEngine()

    return renderEngine.generateDielinePreview(editContext)
  }

  private getRenderEngine(): RenderEngine {
    const { renderEngine } = this.state

    if (!renderEngine) {
      throw new Error("Render engine is not ready")
    }

    return renderEngine
  }

  public getDielineNavigator(): DielineNavigator {
    return this.getVdEditor().dielineNavigator
  }

  private async showSpace(
    editContext: EditContext,
    spaceId: SpaceId
  ): Promise<void> {
    return new Promise(async (resolve) => {
      const renderEngine = this.getRenderEngine()
      renderEngine.offRendererTextures()
      renderEngine.onNextRendererTick(async () => {
        this.setVirtualDielineLoading(true)

        this.setEditContext(editContext)
        this.setEditSpace(spaceId)

        const vdEditor = this.getVdEditor(editContext)

        if (!this.state.isDesignPreviewMode) {
          this.productDesignTouchListener.attachEditModeEventListeners()
          await vdEditor.enterEditMode()
        }

        await vdEditor.showSpace(spaceId)

        this.setVirtualDielineLoading(false)

        this.ee.emit(eventTree.productDriver.changedDielinePosition, {
          activeContext: this.state.activeContext,
          activeSpace: this.state.activeSpace,
        })

        resolve()
      })
    })
  }

  @action
  public setActiveViewType(viewType: ViewType): void {
    const { activeViewType } = this.state

    if (activeViewType !== "space") {
      this.state.previousViewType = activeViewType
    }

    this.state.activeViewType = viewType
  }

  @action
  private setEditSpace(spaceId: SpaceId | null): void {
    this.state.activeSpace = spaceId
  }

  private onTemplateSelected = (): void => {
    const renderEngine = this.getRenderEngine()

    if (renderEngine.has3D) {
      renderEngine.set2dInterfaceObjectsVisibility(false)
      renderEngine.rerender()
    }
  }

  private attachDielineEventListeners(): void {
    if (this.state.isDesignPreviewMode) {
      return
    }

    const vdEditor = this.getVdEditor()

    vdEditor.fabricCanvas.on(CanvasEvent.mouseMove, this.onDielineMouseMove)
    vdEditor.fabricCanvas.on(CanvasEvent.mouseDown, this.onDielineMouseClick)
    vdEditor.fabricCanvas.on(CanvasEvent.mouseWheel, this.onDielineScroll)
    this.eventEmitter.on("vdEditorResized", this.onDielineResized)
  }

  private clearDielineEventListeners(): void {
    const vdEditor = this.getVdEditor()

    vdEditor.fabricCanvas.off(CanvasEvent.mouseMove, this.onDielineMouseMove)
    vdEditor.fabricCanvas.off(CanvasEvent.mouseDown, this.onDielineMouseClick)
    vdEditor.fabricCanvas.off(CanvasEvent.mouseWheel, this.onDielineScroll)
    this.eventEmitter.off("vdEditorResized", this.onDielineResized)
  }

  private attachModelEventListeners(): void {
    this.eventEmitter.on("onThreeDimMouseMove", this.onModelMouseMove)
    this.eventEmitter.on("onThreeDimMouseClick", this.onModelMouseClick)
    this.eventEmitter.on("onThreeDimTouchEnd", this.onModelTouchEnd)
    this.eventEmitter.on(
      "onVirtualDielineObjectAdded",
      this.onVirtualDielineObjectAdded
    )

    this.ee.on(eventTree.templates.selected, this.onTemplateSelected)
    this.ee.on(eventTree.templates.designerModeLoaded, this.onTemplateSelected)
  }

  private clearModelEventListeners(): void {
    this.eventEmitter.off("onThreeDimMouseMove", this.onModelMouseMove)
    this.eventEmitter.off("onThreeDimMouseClick", this.onModelMouseClick)
    this.eventEmitter.off("onThreeDimTouchEnd", this.onModelTouchEnd)
    this.eventEmitter.off(
      "onVirtualDielineObjectAdded",
      this.onVirtualDielineObjectAdded
    )

    this.ee.off(eventTree.templates.selected, this.onTemplateSelected)
  }

  public async generateRenderEngineSnapshot(
    options: RenderEngineSnapshotOptions = {
      force: false,
      withPreview: false,
    }
  ): Promise<{
    isVersionGenerated: boolean
    exportedData: RenderEngineExport
  }> {
    if (
      this.renderExportSnapshot &&
      !options.force &&
      !this.productDesignStore.state.isDesignTouched
    ) {
      return {
        isVersionGenerated: false,
        exportedData: this.renderExportSnapshot,
      }
    }

    const exportedData = await this.getRenderEngineDataForExport(options)
    this.setRenderExportSnapshot(exportedData)
    this.setDesignTouched(false)

    return {
      isVersionGenerated: true,
      exportedData,
    }
  }

  private async cacheDesignData(): Promise<void> {
    this.tempResizeRenderExportSnapshot = await this.getRenderEngine().export()
  }

  private async getRenderEngineDataForExport(
    options: RenderEngineExportOptions
  ): Promise<RenderEngineExport> {
    /*
     * https://packhelp.slack.com/archives/G014LL8KU0J/p1642672784009100
     *
     * If "tempResizeDataExport" is not empty it means that the window is being resized.
     * During this operation, the canvas and VD Editors are being removed
     * (and reinitialized after resizing is finished).
     *
     * In that case "renderEngine.fullExport()" returns empty data (because VD Editors are not reinitialized yet),
     * so we need to return "tempResizeDataExport" (a snapshot made just before the resize event).
     */
    if (!!this.tempResizeRenderExportSnapshot) {
      return this.tempResizeRenderExportSnapshot
    }

    return await this.getRenderEngine().export(options)
  }

  private onChangeProductSkuStarted = async (): Promise<void> => {
    this.setRendererLoading(true)
  }

  private onChangeProductSkuEnded = async (
    sku: string,
    productRenderPilot: ProductRenderPilot
  ): Promise<void> => {
    const previousRenderPilot = this.state.productRenderPilot
    this.setProductRenderPilot(productRenderPilot)

    if (
      productRenderPilot.hasObjModel() &&
      !productRenderPilot.hasObjModel(this.state.modelContext)
    ) {
      this.setModelContext(productRenderPilot.getDefaultModelContext())
    }

    await this.converterDriver?.refresh(previousRenderPilot)
    this.assetsDriver.init()

    this.setRendererLoading(false)
  }

  private onVirtualDielineObjectAdded = (): void => {
    this.state.renderEngine?.touchModelTextures()
  }

  private setRenderExportSnapshot(exportedData: RenderEngineExport): void {
    this.renderExportSnapshot = exportedData
  }

  private refreshHighlightMode(): void {
    const { renderEngine, activeViewType } = this.state

    const mode = this.getHighlightMode()

    for (const vdEditor of this.getVdEditors()) {
      vdEditor.spaceHighlightModule.setMode(mode)
    }

    if (activeViewType === "model") {
      renderEngine?.touchModelTextures()
    }
  }

  private getHighlightMode(): HighlightMode {
    const { isSelectingAssetPlacement, activeViewType } = this.state

    if (activeViewType === "dieline") {
      return "dieline"
    }

    if (isSelectingAssetPlacement) {
      return "model-asset-placement"
    }

    return "model"
  }

  private onDielineMouseMove = (e: IEvent) => {
    const point = e.absolutePointer
    const vdEditor = this.getVdEditor()
    const { spaceHighlightModule, assetsModule } = vdEditor

    if (!point || this.state.isDragModeActive || assetsModule.isAssetHovered) {
      return this.clearHighlight()
    }

    spaceHighlightModule.highlightSpace({
      x: point.x,
      y: point.y,
    })
  }

  private onDielineMouseClick = (e: IEvent) => {
    const point = e.absolutePointer
    const { isDragModeActive, isDesignPreviewMode } = this.state

    const vdEditor = this.getVdEditor()
    const { spaceHighlightModule } = vdEditor

    if (
      !point ||
      this.activeObjectDriver.activeObject ||
      isDragModeActive ||
      isDesignPreviewMode
    ) {
      return this.clearHighlightSelection()
    }

    const space = spaceHighlightModule.selectSpace(point)

    this.setEditSpace(space?.originSpaceArea || null)
  }

  private onDielineResized = (): void => {
    this.clearHighlightSelection()
  }

  private onDielineScroll = (): void => {
    if (this.state.dielineZoom > 1) {
      this.clearHighlightSelection()
    }
  }

  private onModelMouseMove = (definition: HighlightDefinition): void => {
    const { renderEngine } = this.state

    if (this.state.isDesignPreviewMode || !renderEngine) {
      return
    }

    const shouldUpdateModelTextures = this.getVdEditors()
      .map((vdEditor) => {
        if (vdEditor.spaceHighlightModule.isSpaceHighlighted) {
          vdEditor.spaceHighlightModule.clearHighlight()
          return true
        }
        return false
      })
      .find((sideHighlightUpdated) => sideHighlightUpdated === true)

    if (shouldUpdateModelTextures) {
      renderEngine.touchModelTextures()
    }

    const mountPoint = this.state.rendererMountPoint

    if (typeof mountPoint === "undefined") {
      return
    }

    const vdEditor = this.getVdEditors().find(
      (vdEditor) => vdEditor.editContext === definition.editContext
    )

    if (!vdEditor) {
      mountPoint.style.cursor = CURSORS.GRAB
      this.setHighlightedSpaceId(null)
      return
    }

    const highlight = vdEditor.spaceHighlightModule.highlightSpace(definition)
    this.setHighlightedSpaceId(highlight?.originSpaceArea || null)

    if (!highlight) {
      mountPoint.style.cursor = CURSORS.GRAB
      return
    }

    mountPoint.style.cursor = CURSORS.POINTER

    renderEngine.onNextRendererTick(() => {
      renderEngine.touchModelTextures()
    })
  }

  private onModelMouseClick = (definition: HighlightDefinition): void => {
    if (this.state.isDesignPreviewMode) {
      return
    }

    const { editContext } = definition

    if (!editContext || !Object.values(EditContext).includes(editContext)) {
      return
    }

    const vdEditor = this.getVdEditors().find(
      (vdEditor) => vdEditor.editContext === definition.editContext
    )

    if (!vdEditor) {
      return
    }

    const space = vdEditor.spaceHighlightModule.findSpace(definition)

    if (!space) {
      return
    }

    const spaceId = space.originSpaceArea as SpaceId

    if (
      !vdEditor.productRenderPilot.isSpaceEditable(
        vdEditor.editContext,
        spaceId
      )
    ) {
      return
    }

    this.setHighlightedSpaceId(null)

    if (this.state.isSelectingAssetPlacement) {
      this.setIsSelectingAssetPlacement(false)
      this.ee.emit(eventTree.productDriver.spaceSelectedForAsset, {
        spaceId,
        editContext,
      })
    } else {
      this.setView(ViewType.SPACE, editContext, spaceId)
      this.ee.emit(eventTree.productDriver.spaceSelectedForEdit, {
        spaceId,
        editContext,
      })
    }
  }

  private onModelTouchEnd = (definition: HighlightDefinition): void => {
    this.onModelMouseClick(definition)
  }

  public deselectObjectsOnCanvas(): void {
    if (!this.state.renderEngine) {
      return
    }

    const canvas = this.state.renderEngine.getVirtualDielineEditor(
      this.state.activeContext
    ).fabricCanvas

    if (!isInteractiveCanvas(canvas)) {
      return
    }

    canvas.discardActiveObject().renderAll()
  }

  public getVdEditors(): VirtualDielineEditor[] {
    return this.getRenderEngine().getVirtualDielineEditors()
  }

  public getVdEditor(
    editContext: EditContext = this.state.activeContext
  ): VirtualDielineEditor {
    return this.getRenderEngine().getVirtualDielineEditor(editContext)
  }

  @action
  private setHighlightedSpaceId(spaceId: SpaceId | null) {
    this.state.highlightedSpaceId = spaceId
  }
}

export default ProductDriver
