import fabric from "../../../../../libs/vendors/Fabric"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  AvailableRotations,
  TranslationCalculator,
} from "./calculators/translation.calculator"
import {
  PackhelpImage,
  PackhelpObject,
  VirtualDielineSpace,
} from "../../object-extensions/packhelp-objects"
import {
  CartesianCoords,
  Size,
  TempLayers,
} from "../../types/render-engine.types"
import {
  EditContext,
  SpaceId,
} from "../../../../../libs/products-render-config/types"
import { TempPatternController } from "../backgrounds-module/pattern/temp-pattern.controller"
import { TempBackgroundController } from "./controllers/temp-background.controller"
import { BackgroundsModule } from "../backgrounds.module"
import AssetsModule from "../assets.module"
import { ProductRenderPilot } from "../../../../../libs/products-render-config/product-render-pilot"
import { action, makeObservable, observable } from "mobx"
import { TempBackgroundImageController } from "../backgrounds-module/background-image/temp-background-image.controller"
import { SpaceClippingController } from "../backgrounds-module/space-clipping.controller"
import {
  isAssetGroup,
  isBackgroundTexture,
  isSafeZone,
} from "../../../../../types/asset.types"
import { RotationHelper } from "../assets-module/helpers/rotation.helper"
import { ClippingHelper } from "../assets-module/helpers/clipping.helper"
import { SceneDecorationController } from "../backgrounds-module/scene-decoration/scene-decoration.controller"
import { VirtualDielineSpaceNotFoundError } from "../../errors"
import { CANVAS_DIM } from "../../../../types"

export interface EditZoneConfig {
  size: {
    width: number
    height: number
  }
  label: { width: number; height: number }
}

export class DielineNavigator {
  @observable public isSpaceLoading = false
  @observable public editZoneConfig: EditZoneConfig | null = null

  private readonly vdEditor: VirtualDielineEditor
  private currentRotation: AvailableRotations = 0
  private activeSpaceId: SpaceId | null = null
  private readonly translationCalculator: TranslationCalculator
  private readonly tempPatternController: TempPatternController
  private readonly tempBackgroundController: TempBackgroundController
  private readonly tempBackgroundImageController: TempBackgroundImageController
  private readonly sceneDecorationController: SceneDecorationController
  private productRenderPilot: ProductRenderPilot
  private readonly editContext: EditContext
  private isSpaceZoomActive: boolean
  private dielineZoom = 1

  constructor(
    vdEditor: VirtualDielineEditor,
    backgroundsModule: BackgroundsModule,
    assetsModule: AssetsModule,
    productRenderPilot: ProductRenderPilot,
    editContext: EditContext
  ) {
    makeObservable(this)
    this.vdEditor = vdEditor
    this.editContext = editContext
    const { fabricCanvas } = vdEditor

    this.translationCalculator = new TranslationCalculator(
      vdEditor.getCanvasDimensions().width
    )
    this.tempPatternController = new TempPatternController(vdEditor)
    this.tempBackgroundController = new TempBackgroundController(fabricCanvas)
    this.tempBackgroundImageController = new TempBackgroundImageController(
      vdEditor
    )
    this.sceneDecorationController = new SceneDecorationController(vdEditor)

    //TODO: use vdEditor.productRenderPilot instead
    this.productRenderPilot = productRenderPilot

    const zoomConfig = productRenderPilot.uiConfig.editZone.zoom
    this.isSpaceZoomActive = zoomConfig.available && zoomConfig.activeByDefault

    this.refreshEditZoneConfig(this.activeSpaceId)
  }

  public setIsSpaceZoomActive(isActive: boolean) {
    this.isSpaceZoomActive = isActive
  }

  public setDielineZoom(zoom: number): void {
    this.dielineZoom = Math.max(1, zoom)
  }

  public get isDielineZoomActive(): boolean {
    return this.dielineZoom > 1
  }

  public setProductRenderPilot(productRenderPilot: ProductRenderPilot) {
    this.productRenderPilot = productRenderPilot
  }

  public setCurrentRotation(rotation: AvailableRotations): void {
    this.currentRotation = rotation
  }

  public getCurrentRotation(): number {
    return this.currentRotation
  }

  public async resetPanning(): Promise<void> {
    this.tempBackgroundController.removeFromCanvas()
    this.tempBackgroundImageController.removeTempBackgroundImage()
    await this.tempPatternController.removeTempPattern()
    this.resetPanningToDefault()
    this.resetRotation()
    this.setActiveSpace(null)
    this.refreshEditZoneConfig(null)

    this.vdEditor.fabricCanvas.clipPath = undefined
  }

  public getActiveSpaceId(): SpaceId | null {
    return this.activeSpaceId
  }

  public rotateView(rotation: AvailableRotations): void {
    if (rotation === this.currentRotation) {
      return
    }

    this.resetRotation()

    if (rotation) {
      this.currentRotation = rotation
      this.rotateObjects(this.currentRotation)
    }

    this.zoomToSpace()
  }

  @action
  public async panToSpace(spaceId: SpaceId | null): Promise<void> {
    if (!spaceId) {
      return
    }

    this.setIsSpaceLoading(true)

    this.setActiveSpace(spaceId)
    this.rotateView(this.getSpaceRotation(spaceId))
    this.zoomToSpace()
    await this.sceneDecorationController.decorate(this.isSpaceZoomActive)
    await this.applyTempBackground(spaceId)
    await this.applyTempBackgroundImage(spaceId)

    if (
      this.isGlobalPatternApplied() &&
      !this.tempPatternController.getTempPattern()
    ) {
      await this.applyTempPattern(spaceId)
    }

    await this.applyCanvasSpaceClipPath(spaceId)

    this.setIsSpaceLoading(false)
  }

  public panToDieline(): void {
    this.rotateView(
      this.productRenderPilot.getDielineRotation(this.editContext)
    )
    this.zoomToDieline()
  }

  public onResize(): void {
    this.zoomToSpace()
    this.zoomToDieline()
  }

  public moveDieline(x: number, y: number): void {
    const tempDieline = this.getTempDieline()

    if (!tempDieline) {
      return
    }

    this.vdEditor.fabricCanvas.relativePan({ x, y })
  }

  public zoomToDieline(): void {
    const tempDieline = this.getTempDieline()

    if (!tempDieline) {
      return
    }

    const sceneDimensions = this.vdEditor.getSceneDimensions()
    const size = {
      width: CANVAS_DIM,
      height: tempDieline.getScaledHeight(),
    }

    const padding = 100

    const zoom =
      Math.min(
        (sceneDimensions.width - 2 * padding) / size.width,
        (sceneDimensions.height - 2 * padding) / size.height,
        1
      ) * this.dielineZoom

    if (this.dielineZoom > 1) {
      this.vdEditor.fabricCanvas.zoomToPoint(
        { x: sceneDimensions.width / 2, y: sceneDimensions.height / 2 },
        zoom
      )
    } else {
      this.vdEditor.fabricCanvas.setZoom(zoom)

      this.vdEditor.fabricCanvas.absolutePan({
        x: (sceneDimensions.width - size.width * zoom) / -2,
        y: (sceneDimensions.height - size.height * zoom) / -2,
      })
    }
  }

  public getViewportCenter(): fabric.Point {
    const sceneDimensions = this.vdEditor.getSceneDimensions()
    const { viewportTransform } = this.vdEditor.fabricCanvas

    return fabric.util.transformPoint(
      new fabric.Point(sceneDimensions.width / 2, sceneDimensions.height / 2),
      fabric.util.invertTransform(viewportTransform!)
    )
  }

  public async refreshTempBackgroundOnActiveSpace() {
    if (!this.activeSpaceId) {
      return
    }

    await this.tempBackgroundController.refreshBackground(
      this.getVirtualDielineSpace(this.activeSpaceId)
    )
  }

  public async refreshTempBackground(spaceId) {
    await this.tempBackgroundController.refreshBackground(
      this.getVirtualDielineSpace(spaceId)
    )
  }

  public async refreshTempBackgroundImageOnActiveSpace(): Promise<void> {
    if (!this.activeSpaceId) {
      return
    }

    await this.refreshTempBackgroundImage(this.activeSpaceId)
  }

  public async refreshTempBackgroundImage(spaceId: SpaceId): Promise<void> {
    await this.tempBackgroundImageController.refreshTempBackgroundImage(
      await this.cloneSpace(spaceId)
    )
  }

  public async refreshTempPatternOnActiveSpace(): Promise<void> {
    if (!this.activeSpaceId) {
      return
    }

    await this.refreshTempPattern(this.activeSpaceId)
  }

  public async refreshTempPattern(spaceId: SpaceId): Promise<void> {
    await this.tempPatternController.refreshTempPattern(
      await this.cloneSpace(spaceId),
      this.getSpaceZoomRatio(spaceId)
    )
  }

  public async refreshSceneDecoration() {
    await this.sceneDecorationController.refresh()
  }

  public getActiveVirtualDielineSpace(): VirtualDielineSpace | undefined {
    if (!this.activeSpaceId) {
      return
    }

    return this.getVirtualDielineSpace(this.activeSpaceId)
  }

  public getVirtualDielineSpace(spaceId): VirtualDielineSpace {
    const space = this.vdEditor.virtualDieline
      .getObjects()
      .find((space) => space.id === spaceId)

    if (!space) {
      throw new VirtualDielineSpaceNotFoundError(
        `Can't find space with provided id: ${spaceId}`
      )
    }

    return space as VirtualDielineSpace
  }

  public getSpaceRotation(spaceId: SpaceId): number {
    return this.getVirtualDielineSpace(spaceId).rotation || 0
  }

  public getSpaceBoundingRectWithRotation(spaceId: SpaceId) {
    const space = this.getVirtualDielineSpace(spaceId)

    return this.translationCalculator.calculateSpaceBoundingRect(
      space,
      space.rotation
    )
  }

  public getSpaceBoundingRect(spaceId: SpaceId) {
    const space = this.getVirtualDielineSpace(spaceId)

    return this.translationCalculator.calculateSpaceBoundingRect(space, 0)
  }

  public getAssetPositionInSpace(
    spaceId: SpaceId,
    objectSize: Size
  ): CartesianCoords {
    const space = this.getVirtualDielineSpace(spaceId)

    return this.translationCalculator.calculateAssetCenterPositionInSpace(
      space,
      this.currentRotation,
      objectSize
    )
  }

  @action
  private refreshEditZoneConfig(spaceId: SpaceId | null) {
    this.editZoneConfig = spaceId ? this.prepareEditZoneConfig(spaceId) : null
  }

  private async applyCanvasSpaceClipPath(spaceId: SpaceId) {
    const clipPath = await this.cloneSpaceToClipPath(spaceId, true, false)

    ClippingHelper.resizeClipPathBy(
      clipPath,
      this.productRenderPilot.uiConfig.editZone.shadow?.blur || 1
    )

    this.vdEditor.fabricCanvas.clipPath = clipPath
  }

  private getPaddingScale(spaceId: SpaceId): {
    scaleX: number
    scaleY: number
    paddingZoneRatio: number
  } {
    const paddingZoneSizeMm =
      this.productRenderPilot.uiConfig.editZone.paddingZoneSizeMm || 0

    if (!paddingZoneSizeMm) {
      return {
        scaleX: 1,
        scaleY: 1,
        paddingZoneRatio: 0,
      }
    }

    const artworkSpaceSize = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      spaceId
    )

    const scaleX =
      (artworkSpaceSize.widthCm * 10 - 2 * paddingZoneSizeMm) /
      (artworkSpaceSize.widthCm * 10)
    const scaleY =
      (artworkSpaceSize.heightCm * 10 - 2 * paddingZoneSizeMm) /
      (artworkSpaceSize.heightCm * 10)

    const paddingZoneRatio = paddingZoneSizeMm / (artworkSpaceSize.widthCm * 10)

    return { scaleX, scaleY, paddingZoneRatio }
  }

  public getPaddingZoneSize(spaceId) {
    const currentSideObjectCalc = this.getSpaceBoundingRect(spaceId)

    const paddingZoneSizeRatio = this.getPaddingScale(spaceId).paddingZoneRatio

    const paddingZoneSize =
      paddingZoneSizeRatio *
      Math.max(currentSideObjectCalc.width, currentSideObjectCalc.height)

    return paddingZoneSize
  }

  public async cloneSpaceToClipPath(
    spaceId: SpaceId,
    withRotation = true,
    withPadding = true
  ): Promise<PackhelpObject> {
    const currentSideObject = this.getVirtualDielineSpace(spaceId)

    return new Promise((resolve) => {
      currentSideObject.clone(
        (clonedSideObject) => {
          const currentSideObjectCalc = this.getSpaceBoundingRect(spaceId)

          let paddingZoneSize = 0
          let scaleX = 1
          let scaleY = 1

          if (
            withPadding &&
            this.productRenderPilot.isSpaceEditable(this.editContext, spaceId)
          ) {
            paddingZoneSize = this.getPaddingZoneSize(spaceId)
            const paddingScale = this.getPaddingScale(spaceId)
            scaleX = paddingScale.scaleX
            scaleY = paddingScale.scaleY
          }

          clonedSideObject.set({
            fill: "red",
            evented: false,
            strokeWidth: 0,
            opacity: 1,
            selectable: false,
            visible: true,
            left: currentSideObjectCalc.left + paddingZoneSize,
            top: currentSideObjectCalc.top + paddingZoneSize,
            width: currentSideObjectCalc.width,
            height: currentSideObjectCalc.height,
            scaleX: scaleX,
            scaleY: scaleY,
          })

          if (withRotation) {
            this.rotateObject(clonedSideObject, this.currentRotation)
          }

          return resolve(clonedSideObject)
        },
        ["id", "rotation"]
      )
    })
  }

  public resetRotation(): void {
    if (this.currentRotation === AvailableRotations.none) {
      return
    }

    this.rotateObjects(this.currentRotation * -1)
    this.currentRotation = AvailableRotations.none
  }

  private resetPanningToDefault() {
    this.vdEditor.fabricCanvas.setZoom(1)
    this.vdEditor.fabricCanvas.absolutePan(new fabric.Point(0, 0))
  }

  private setActiveSpace(spaceId: SpaceId | null): void {
    const { fabricCanvas } = this.vdEditor

    this.activeSpaceId = spaceId
    fabricCanvas.activeSpaceId = spaceId
  }

  public getSpaceZoomRatio(spaceId: SpaceId): number {
    return this.translationCalculator.calculateSpaceToDielineZoomRatio(
      this.getVirtualDielineSpace(spaceId),
      this.getCurrentRotation(),
      this.vdEditor.getSceneDimensions(),
      this.isSpaceZoomActive
    )
  }

  private zoomToSpace(): void {
    if (!this.activeSpaceId) {
      return
    }

    this.refreshEditZoneConfig(this.activeSpaceId)
    this.resetPanningToDefault()

    const { fabricCanvas } = this.vdEditor
    const sceneDimensions = this.vdEditor.getSceneDimensions()
    const space = this.getVirtualDielineSpace(this.activeSpaceId)
    const spaceCenter = this.translationCalculator.calculateSpaceCenterOnScene(
      space,
      this.getCurrentRotation(),
      sceneDimensions
    )

    fabricCanvas.absolutePan(spaceCenter)
    fabricCanvas.zoomToPoint(
      new fabric.Point(sceneDimensions.centerX, sceneDimensions.centerY),
      this.getSpaceZoomRatio(this.activeSpaceId)
    )
  }

  public async applyTempBackground(spaceId: SpaceId): Promise<void> {
    const space = this.getVirtualDielineSpace(spaceId)
    if (typeof space.fill !== "string") {
      throw new Error("SpaceId is not compatible")
    }
    this.tempBackgroundController.removeFromCanvas()
    const tempBackground = await this.tempBackgroundController.createBackground(
      space,
      await this.cloneSpace(spaceId),
      {
        shadow: this.productRenderPilot.uiConfig.editZone.shadow,
        texture: this.vdEditor.texture,
      }
    )
    this.vdEditor.addOnCanvas(tempBackground, false)
    this.rotateObject(tempBackground, this.currentRotation)
    this.refreshEditZoneConfig(this.activeSpaceId)
  }

  public removeTempBackground() {
    this.tempBackgroundController.removeFromCanvas()
  }

  public getTempBackground() {
    return this.tempBackgroundController.getBackground()
  }

  public getTempDieline(): PackhelpImage | undefined {
    return this.vdEditor.getCanvasObjectById<PackhelpImage>(
      TempLayers.TEMP_DIELINE
    )
  }

  public async applyTempBackgroundImage(spaceId: SpaceId): Promise<void> {
    const globalBackgroundImage =
      this.vdEditor.backgroundsModule.getGlobalBackgroundImage()
    const isPrintActive = this.productRenderPilot.isPrintActiveFor(
      this.editContext
    )

    const hasPadding =
      !!this.productRenderPilot.uiConfig.editZone.paddingZoneSizeMm

    if (!globalBackgroundImage || !isPrintActive) {
      this.tempBackgroundImageController.removeTempBackgroundImage()

      return
    }

    await this.tempBackgroundImageController.applyTempBackgroundImage(
      await this.cloneSpace(spaceId),
      globalBackgroundImage
    )

    const spaceClippingController = new SpaceClippingController(
      this,
      globalBackgroundImage
    )
    const isVisible =
      !spaceClippingController.isClippingActive(spaceId) || hasPadding
    this.setTempBackgroundImageVisibility(isVisible)
  }

  public setTempBackgroundImageVisibility(isVisible: boolean) {
    this.tempBackgroundImageController.setTempBackgroundImageVisibility(
      isVisible
    )
  }

  public getTempBackgroundImage(): PackhelpObject | undefined {
    return this.tempBackgroundImageController.getTempBackgroundImage()
  }

  public async applyTempPattern(spaceId: SpaceId): Promise<void> {
    const tempPattern = await this.tempPatternController.applyTempPattern(
      await this.cloneSpace(spaceId),
      this.getSpaceZoomRatio(spaceId)
    )

    this.rotateObject(tempPattern, this.currentRotation)
  }

  public toggleVisibleTempPattern(spaceId) {
    this.tempPatternController.toggleVisibleTempPattern(spaceId)
  }

  public getTempPattern() {
    return this.tempPatternController.getTempPattern()
  }

  private isGlobalPatternApplied(): boolean {
    return !!this.vdEditor.backgroundsModule.getGlobalPattern()
  }

  private prepareEditZoneConfig(spaceId: SpaceId): EditZoneConfig | null {
    const editZoneBackground = this.getTempBackground()

    if (!editZoneBackground) {
      return null
    }

    const spaceZoom = this.getSpaceZoomRatio(spaceId)
    const spaceDimensions = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      spaceId
    )

    const size = {
      width: editZoneBackground.getScaledWidth() * spaceZoom,
      height: editZoneBackground.getScaledHeight() * spaceZoom,
    }

    const smallerDimension = Math.min(
      spaceDimensions.widthCm,
      spaceDimensions.heightCm
    )
    const largerDimension = Math.max(
      spaceDimensions.widthCm,
      spaceDimensions.heightCm
    )

    const label =
      size.width >= size.height
        ? { width: largerDimension, height: smallerDimension }
        : {
            width: smallerDimension,
            height: largerDimension,
          }

    if (RotationHelper.isVertical(this.getCurrentRotation())) {
      return {
        size: {
          width: size.height,
          height: size.width,
        },
        label: {
          width: label.height,
          height: label.width,
        },
      }
    }

    return {
      size,
      label,
    }
  }

  private async cloneSpace(spaceId): Promise<PackhelpObject> {
    const currentSpace = this.getVirtualDielineSpace(spaceId)
    return new Promise((resolve) => {
      currentSpace.clone(
        (clonedSpace) => {
          const currentSpaceCoords = this.getSpaceBoundingRect(spaceId)
          clonedSpace.set({
            evented: false,
            strokeWidth: 0,
            opacity: 0,
            selectable: false,
            visible: true,
            left: currentSpaceCoords.left,
            top: currentSpaceCoords.top,
            width: currentSpaceCoords.width,
            height: currentSpaceCoords.height,
            id: `temp_background_${spaceId}`,
            originSpaceArea: spaceId,
          })
          resolve(clonedSpace)
        },
        ["id", "rotation", "originSpaceArea"]
      )
    })
  }

  private rotateObjects(rotation: number): void {
    const canvasClipping = this.vdEditor.fabricCanvas.clipPath

    if (canvasClipping) {
      this.rotateObject(canvasClipping, rotation)
    }

    this.vdEditor.fabricCanvas.getObjects().forEach((obj) => {
      // Do not translate these types of objects
      if (isBackgroundTexture(obj)) {
        return
      }

      this.rotateObject(obj, rotation)

      // Do not translate clip paths of these types of objects
      if (isSafeZone(obj)) {
        return
      }

      if (isAssetGroup(obj)) {
        for (const groupObject of obj.getObjects()) {
          if (groupObject.clipPath) {
            this.translateClipPath(groupObject.clipPath, rotation)
          }
        }
      } else if (obj.clipPath) {
        this.translateClipPath(obj.clipPath, rotation)
      }
    })
  }

  private rotateObject(obj: fabric.Object, rotation: number): void {
    RotationHelper.rotateObjectAroundCanvasCenter(obj, rotation)
    obj.setCoords()
  }

  private translateClipPath(clipPath: fabric.Object, rotation: number): void {
    RotationHelper.rotateClipPath(clipPath, rotation)
  }

  public getSpaceBoundaries(spaceId: SpaceId) {
    const space = this.getVirtualDielineSpace(spaceId)

    return this.translationCalculator.calculateSpaceBoundingRect(
      space,
      space.rotation
    )
  }

  @action
  private setIsSpaceLoading(isSpaceLoading: boolean) {
    this.isSpaceLoading = isSpaceLoading
  }
}
