import { computed, makeObservable } from "mobx"
import { I18N } from "../../ui/i18n"
import { AssetsStore } from "../../stores/assets.store"
import { ImageAssetItemStore } from "../../stores/image-asset-item.store"
import { ImagesController } from "../assets/images.controller"
import { ElementsTabElement, ElementsTabSection } from "../../types/ui.types"
import removeIconUrl from "../../ui/assets/_icons/trash.svg"
import logoIconUrl from "../../ui/assets/_icons/heart.svg"
import { BackgroundImageUiController } from "../background-image-ui.controller"

const i18nUploader = I18N.component.assetsUploader
const i18nAssets = I18N.editorTools.assets

export class UploadTabController {
  private readonly imagesController: ImagesController
  private readonly backgroundImageUiController: BackgroundImageUiController
  private readonly assetStore: AssetsStore

  constructor(
    services: {
      imagesController: ImagesController
      backgroundImageUiController: BackgroundImageUiController
    },
    stores: {
      assetStore: AssetsStore
    }
  ) {
    this.imagesController = services.imagesController
    this.backgroundImageUiController = services.backgroundImageUiController
    this.assetStore = stores.assetStore

    makeObservable(this)
  }

  public uploadFiles(files: File[]): void {
    files.forEach((file) => this.assetStore.addAssetFromFile(file))
  }

  @computed
  public get failedImages(): ImageAssetItemStore[] {
    return this.assetStore.failedAssets
  }

  @computed
  public get uploadedImagesSection(): ElementsTabSection<ImageAssetItemStore> {
    return {
      id: "user-images",
      titleIntl: i18nAssets.uploadedImages,
      elements: this.assetStore.successUserAssets.map((element) => ({
        id: element.id,
        title: element.name,
        previewUrl: element.previewUri,
        asset: element,
        type: "user-image",
        badge: this.buildImageBadge(element),
        loading: element.isUploading,
        actions: this.buildImageActions(element),
      })),
      onElementClick: (asset: ImageAssetItemStore) => {
        this.imagesController.addImage(asset.getImageAsset())
      },
    }
  }

  private buildImageBadge(
    image: ImageAssetItemStore
  ): ElementsTabElement<ImageAssetItemStore>["badge"] {
    if (this.assetStore.logo === image) {
      return { labelIntl: i18nUploader.logo }
    }
  }

  private buildImageActions(
    image: ImageAssetItemStore
  ): ElementsTabElement<ImageAssetItemStore>["actions"] {
    const actions = [
      {
        type: "remove",
        titleIntl: i18nUploader.remove,
        iconUrl: removeIconUrl,
        onClick: async () => {
          await this.backgroundImageUiController.removeBackgroundImagesByImageAssetStore(
            image
          )

          this.assetStore.removeAsset(image)
        },
      },
    ]

    if (this.assetStore.logo !== image) {
      actions.unshift({
        type: "set-as-logo",
        titleIntl: i18nUploader.setAsLogo,
        iconUrl: logoIconUrl,
        onClick: async () => {
          await this.assetStore.setAssetAsLogo(image)
        },
      })
    }

    return actions
  }
}
