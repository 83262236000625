import React from "react"
import { ListingItem } from "shared-libs/src/js/libs/services/listing-services/utils/listing-services.types"
import { Tile } from "../../../../atoms/Tile/Tile"
import { Dropdown } from "../../../../atoms/Dropdown/Dropdown"
import { DropdownLink } from "../../../../atoms/Dropdown/DropdownLink"
import { prepareImgixUrl } from "shared-libs/src/js/libs/helpers/prepare-imgix-url"
import { I18N } from "../../../../../i18n"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import styles from "./ListingTile.module.scss"
import Badge, { BadgeColorTypes } from "dsl/src/atoms/Badge/Badge"

const i18n = I18N.editorTools.productSwitcher

export interface ListingTileProps {
  item: ListingItem
  onClick: (item: ListingItem) => void
  buildProductPageUrl: (path: string) => string
  loading?: boolean
  active?: boolean
}

export function ListingTile({
  item,
  onClick,
  buildProductPageUrl,
  loading = false,
  active = false,
}: ListingTileProps) {
  const t = useTranslate()

  const thumbnail = item.thumbnail
  const imageUrl = thumbnail?.optimized?.cdnUrl || thumbnail?.url
  const editorType = item.attributes.product.editor_type
  const isDbyOnly = editorType === "dby"

  return (
    <Tile
      key={item.id}
      label={item.content.title}
      onClick={() => onClick(item)}
      contextMenu={
        item.url ? (
          <Dropdown>
            <DropdownLink
              label={t(i18n.viewProductPage)}
              href={buildProductPageUrl(item.url)}
              analyticsSection="left-tab-product"
              analyticsType="context-menu"
              analyticsName={item.name}
              analyticsId="product-page"
            />
          </Dropdown>
        ) : undefined
      }
      active={active}
      analyticsSection="left-tab-product"
      analyticsType="thumbnail"
      analyticsName="product"
      analyticsId={item.name}
      badge={
        isDbyOnly ? (
          <Badge badgeColor={BadgeColorTypes.Black} noMargin>
            {t(I18N.generic.dielineOnly)}
          </Badge>
        ) : undefined
      }
    >
      {loading && (
        <div className={styles.loader}>
          <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
        </div>
      )}

      {imageUrl && (
        <figure>
          <img
            src={prepareImgixUrl(imageUrl, {
              auto: "format",
              w: 376,
              q: 75,
            })}
          />
        </figure>
      )}
    </Tile>
  )
}
