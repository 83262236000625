import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
  PackhelpEditableShape,
  PackhelpEditableText,
  VirtualDielineSpace,
} from "../../../../object-extensions/packhelp-objects"
import {
  isActiveSelection,
  isAssetGroup,
  isAssetShape,
  isAssetText,
} from "../../../../../../../types/asset.types"
import { EditContext } from "../../../../../../../libs/products-render-config/types"
import { ProductRenderPilot } from "../../../../../../../libs/products-render-config/product-render-pilot"
import { FontSizeCalculator } from "./font-size.calculator"
import { ObjectDpiCalculator } from "../../../../../../../libs/calculators/object-dpi.calculator"

export class ScaleLimitCalculator {
  private readonly fontSizeCalculator

  public constructor(
    private readonly object: PackhelpEditableObject,
    private readonly productRenderPilot: ProductRenderPilot,
    private readonly editContext: EditContext,
    private readonly vdSpace: VirtualDielineSpace
  ) {
    this.fontSizeCalculator = new FontSizeCalculator(
      productRenderPilot,
      editContext,
      vdSpace
    )
  }

  public calcMin(): number {
    return this.calcMinScaleLimit(this.object)
  }

  private calcMinScaleLimit(object: PackhelpEditableObject) {
    if (isAssetText(object)) {
      return this.calcMinScaleLimitForText(object)
    }

    if (isAssetShape(object)) {
      return this.calcMinScaleLimitForShape(object)
    }

    if (isAssetGroup(object) || isActiveSelection(object)) {
      return this.calcMinScaleLimitForGroup(object)
    }

    return 0
  }

  private calcMinScaleLimitForGroup(group: PackhelpEditableGroup): number {
    let limit = Number.NEGATIVE_INFINITY

    for (const groupObject of group.getObjects()) {
      limit = Math.max(this.calcMinScaleLimit(groupObject), limit)
    }

    return limit
  }

  private calcMinScaleLimitForText(object: PackhelpEditableText): number {
    const objectScale = object.scaleX!
    const groupScale = object.group ? object.group.scaleX! : 1
    const fontSize = object.fontSize * objectScale * groupScale

    return (
      (this.fontSizeCalculator.calcEditorMin() / fontSize) * this.object.scaleX!
    )
  }

  private calcMinScaleLimitForShape(object: PackhelpEditableShape): number {
    const minHeightMm = object.assetObjectMeta.minHeightMm

    if (!minHeightMm) {
      return 0
    }

    const artworkSize = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      this.vdSpace.id
    )

    const objectOriginalHeightPx = object.height! + object.strokeWidth!
    const vdSize = ObjectDpiCalculator.getVdSize(this.vdSpace)

    const vdRatio = vdSize.widthVdpx / vdSize.heightVdpx
    const artworkRatio = artworkSize.widthPx / artworkSize.heightPx
    const vdRatioToArtworkRatio = vdRatio / artworkRatio
    const artworkHeightMm = artworkSize.heightCm * 10

    const minHeightToArtworkHeightRatio = minHeightMm / artworkHeightMm
    const minHeightPx = minHeightToArtworkHeightRatio * vdSize.heightVdpx
    const minScale =
      (minHeightPx / objectOriginalHeightPx) * vdRatioToArtworkRatio

    if (object.group) {
      const height = object.getScaledHeight()
      const minHeight = objectOriginalHeightPx * minScale

      return minHeight / height
    }

    return minScale
  }
}
