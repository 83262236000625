import React from "react"
import styles from "../Select.module.scss"
import { ItemProps } from "../types"

const Item = (props: ItemProps) => {
  const { children, isSelected, ...other } = props
  return (
    // @ts-ignore
    <li className={styles.item} {...other}>
      {children}
    </li>
  )
}

export { Item, Item as default }
