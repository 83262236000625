import fabric from "../../../../../../libs/vendors/Fabric"
import {
  PackhelpGroup,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { Size } from "../../../types/render-engine.types"
import { AvailableRotations } from "../../dieline-navigator/calculators/translation.calculator"
import { CANVAS_DIM } from "../../../../../types"
import { isGroup } from "../../../../../../types/asset.types"

export class RotationHelper {
  public static alignObjectRotation(object: PackhelpObject, sensitivity = 5) {
    const angle = object.angle!

    for (const snapAngle of [0, 45, 90, 135, 180, 225, 270, 315, 360]) {
      if (angle > snapAngle - sensitivity && angle < snapAngle + sensitivity) {
        object.rotate(snapAngle)

        break
      }
    }
  }

  public static rotateObject(
    object: PackhelpObject,
    centerPoint: fabric.Point,
    angle: number
  ) {
    const newPosition = this.rotatePoint(
      new fabric.Point(object.left!, object.top!),
      centerPoint,
      angle
    )

    object.set({
      left: newPosition.x,
      top: newPosition.y,
      angle: object.angle! + angle,
    })
  }

  public static rotateObjectAroundCanvasCenter(
    object: fabric.Object,
    angle: number
  ) {
    const newPosition = this.rotatePointAroundCanvasCenter(
      new fabric.Point(object.left!, object.top!),
      angle
    )

    object.set({
      left: newPosition.x,
      top: newPosition.y,
      angle: object.angle! + angle,
    })
  }

  public static rotatePoint(
    point: fabric.Point,
    centerPoint: fabric.Point,
    angle: number
  ): fabric.Point {
    return fabric.util.rotatePoint(
      point,
      centerPoint,
      fabric.util.degreesToRadians(angle)
    )
  }

  public static rotatePointAroundCanvasCenter(
    point: fabric.Point,
    angle: number
  ): fabric.Point {
    return fabric.util.rotatePoint(
      point,
      new fabric.Point(CANVAS_DIM / 2, CANVAS_DIM / 2),
      fabric.util.degreesToRadians(angle)
    )
  }

  public static calculateCenterPoint(
    topLeftPoint: fabric.Point,
    objectSize: Size,
    spaceRotation: AvailableRotations
  ): fabric.Point {
    if (spaceRotation === AvailableRotations.upsideDown) {
      return new fabric.Point(
        topLeftPoint.x - objectSize.width / 2,
        topLeftPoint.y - objectSize.height / 2
      )
    }

    if (spaceRotation === AvailableRotations.verticalLeft) {
      return new fabric.Point(
        topLeftPoint.x + objectSize.height / 2,
        topLeftPoint.y - objectSize.width / 2
      )
    }

    if (spaceRotation === AvailableRotations.verticalRight) {
      return new fabric.Point(
        topLeftPoint.x - objectSize.height / 2,
        topLeftPoint.y + objectSize.width / 2
      )
    }

    return new fabric.Point(
      topLeftPoint.x + objectSize.width / 2,
      topLeftPoint.y + objectSize.height / 2
    )
  }

  public static getPositionMultiplierForSpaceRotation(
    rotation: AvailableRotations
  ): { left: number; top: number; rotation: number } {
    if (this.isUpsideDown(rotation)) {
      return {
        top: -1,
        left: -1,
        rotation: -1,
      }
    }

    if (rotation === AvailableRotations.verticalRight) {
      return {
        top: -1,
        left: 1,
        rotation: 1,
      }
    }

    if (rotation === AvailableRotations.verticalLeft) {
      return {
        top: 1,
        left: -1,
        rotation: 1,
      }
    }

    return {
      top: 1,
      left: 1,
      rotation: 1,
    }
  }

  public static isVertical(rotation: AvailableRotations): boolean {
    return (
      rotation === AvailableRotations.verticalLeft ||
      rotation === AvailableRotations.verticalRight
    )
  }

  public static isUpsideDown(rotation: AvailableRotations): boolean {
    return Math.abs(rotation) === AvailableRotations.upsideDown
  }

  public static rotateClipPath(
    clipPath: fabric.Object,
    rotation: number
  ): void {
    if (isGroup(clipPath)) {
      return this.rotateClipPathGroup(clipPath, rotation)
    }

    return this.rotateObjectAroundCanvasCenter(clipPath, rotation)
  }

  public static rotateClipPathGroup(
    group: PackhelpGroup,
    rotation: number
  ): void {
    for (const groupObject of group.getObjects()) {
      const positionMultiplier =
        RotationHelper.getPositionMultiplierForSpaceRotation(rotation)

      let left = groupObject.left!
      let top = groupObject.top!

      if (
        [
          AvailableRotations.verticalLeft,
          AvailableRotations.verticalRight,
        ].includes(rotation)
      ) {
        left = groupObject.top!
        top = groupObject.left!
      }

      groupObject.set({
        left: positionMultiplier.left * left,
        top: positionMultiplier.top * top,
        angle: groupObject.angle! + positionMultiplier.rotation * rotation,
      })

      if (groupObject.clipPath) {
        this.rotateObjectAroundCanvasCenter(groupObject.clipPath, rotation)
      }
    }
  }
}
