import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Tooltip.module.scss"
import {
  BaseTooltip,
  TooltipDirections,
  ArrowPlacements
} from "../../atoms/BaseTooltip/BaseTooltip"
import { TooltipPortal } from "./TooltipPortal"
import { useTooltipParameters } from "./utils"
const cx = cxBinder.bind(styles)

export enum TriggerTypes {
  click = "click",
  mouseover = "mouseover"
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type CustomDiv = Omit<React.HTMLProps<HTMLDivElement>, "value">
export interface TooltipProps extends CustomDiv {
  direction: TooltipDirections
  /** Tooltip content */
  value: React.ReactNode
  arrowPlacement: ArrowPlacements
  /** Trigger type: either "mouseover" or "click"; defaults to "mouseover" */
  trigger?: TriggerTypes
  zIndex?: number
  withCursorDefault?: boolean
  children?: React.ReactNode
}

export type CoordinatesType = {
  left: number
  top: number
}

const Tooltip = (props: TooltipProps) => {
  const {
    children,
    direction,
    arrowPlacement,
    value: tooltipValue,
    trigger = TriggerTypes.mouseover,
    zIndex,
    withCursorDefault = false,
    ...other
  } = props

  const { open, coordinates, handleOpen, handleClose, handleOutsideClick } =
    useTooltipParameters(direction, arrowPlacement)
  /**
   * we don't know what component will be passed so we can't pass it as a prop
   * to the child explicitly an alternative to the current solution is to wrap
   * the child in an additional tag that will have the mouse event handlers,
   * but that in turn would create a div soup
   */
  const childrenWithMouseSupport = React.Children.map(children, (child) => {
    const supportedEvents =
      trigger === TriggerTypes.mouseover
        ? {
            onMouseOver: handleOpen,
            onMouseLeave: handleClose
          }
        : {
            onClick: !open ? handleOpen : handleClose
          }
    // @ts-ignore
    return React.cloneElement(React.Children.only(child), supportedEvents)
  })

  return (
    // @ts-ignore
    <div
      className={cx("wrapper", {
        "wrapper--cursor-default": withCursorDefault
      })}
      {...other}
    >
      {childrenWithMouseSupport}
      {open && (
        <TooltipPortal coordinates={coordinates} zIndex={props.zIndex}>
          <BaseTooltip
            wrapped
            open={open}
            direction={direction}
            arrowPlacement={arrowPlacement}
            handleClickOutside={
              trigger === TriggerTypes.click ? handleOutsideClick : undefined
            }
          >
            {tooltipValue}
          </BaseTooltip>
        </TooltipPortal>
      )}
    </div>
  )
}

export { TooltipInfoIcon } from "./TooltipInfoIcon"
export { Tooltip, Tooltip as default }
