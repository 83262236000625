import React, { useRef } from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { TileLink } from "../../../atoms/TileLink/TileLink"
import { Skeleton } from "../../../atoms/Skeleton/Skeleton"
import { Step } from "../../../atoms/Step/Step"
import {
  Dropzone,
  DropzoneProps,
  DropzoneRef,
} from "../../../atoms/Dropzone/Dropzone"
import { UploadError } from "../../../molecules/UploadError/UploadError"
import { ContextNav } from "../../../atoms/ContextNav/ContextNav"
import { ContextNavItem } from "../../../atoms/ContextNav/ContentNavItem"
import { StepList } from "../../../atoms/Step/StepList"
import checkIconUrl from "../../../../assets/_icons/check.svg"

const i18nDby = I18N.component.dbyUploader
const i18nUploader = I18N.component.assetsUploader

export const TabDby = observer(function () {
  const t = useTranslate()
  const dropzoneRef = useRef<DropzoneRef>()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { dbyTabController, editorModeController } = containerSet.ui
  const {
    isLoadingDielineUrls,
    downloadItems,
    isUploading,
    uploadError,
    previewUrl,
    filename,
  } = dbyTabController
  const { isDesignReadOnly } = editorModeController
  const isContextNavVisible = previewUrl && !isDesignReadOnly

  const onDrop = (files: File[]) => {
    dbyTabController.uploadFile(files[0])
  }

  const onRemoveClick = () => {
    dbyTabController.removeFile()
  }

  const getState = (): DropzoneProps["state"] => {
    if (isUploading && !uploadError) {
      return "uploading"
    }

    return "initial"
  }

  return (
    <Tab title={t(I18N.tabs.dby)} e2eTargetName="dby">
      <StepList>
        {!previewUrl && (
          <>
            <Step number={1} title={t(i18nDby.step1.title)}>
              {isLoadingDielineUrls ? (
                <Skeleton height={48} />
              ) : (
                <>
                  {downloadItems.map((item) => {
                    return (
                      <TileLink
                        key={item.titleIntl}
                        label={t(item.titleIntl)}
                        iconUrl={item.iconUrl}
                        href={item.url}
                        analyticsSection="left-tab-add-design"
                        analyticsType="button"
                        analyticsName="download-file"
                        analyticsId={item.type}
                      />
                    )
                  })}
                </>
              )}
            </Step>

            <Step number={2} title={t(i18nDby.step2.title)}></Step>
          </>
        )}

        <Step
          number={previewUrl ? undefined : 3}
          title={previewUrl ? t(i18nDby.uploaded) : t(i18nDby.step3.title)}
          iconUrl={previewUrl ? checkIconUrl : undefined}
        >
          {!previewUrl && (
            <Dropzone
              ref={dropzoneRef}
              onDrop={onDrop}
              state={getState()}
              label={t(i18nUploader.uploadDieline)}
              analytics-section="left-tab-add-design"
              analytics-name="upload-dby"
            ></Dropzone>
          )}

          {uploadError && <UploadError error={uploadError} />}

          {isContextNavVisible && (
            <ContextNav>
              {filename}
              <ContextNavItem
                label={t(i18nUploader.removeFile)}
                onClick={onRemoveClick}
                analyticsSection="left-tab-add-design"
                analyticsType="button"
                analyticsName="remove-file"
                e2eTarget="button"
                e2eTargetName="remove-file"
              />
            </ContextNav>
          )}
        </Step>
      </StepList>
    </Tab>
  )
})
