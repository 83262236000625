import React from "react"
import { CustomPicker } from "react-color"
import tinycolor from "tinycolor2"
import {
  Saturation,
  Hue,
  EditableInput
} from "react-color/lib/components/common"
import color from "react-color/lib/helpers/color"
import { Typography, TType } from "../../atoms/Typography/Typography"
import styles from "./PickerColorComponent.module.scss"
import { RgbaObject } from "./PickerColorTypes"

const Pointer = () => {
  return <span className={styles["z-hue-pointer"]} />
}

type CustomRgbaHexType = {
  HEX: string
  R: number
  B: number
  G: number
}

interface ColorPickerComponentProps {
  hex: string
  hsl: string
  onChange: (object: string) => void
  rgb: RgbaObject
}

const ColorPickerComponent = (props: ColorPickerComponentProps) => {
  const handleInputChange = (data: CustomRgbaHexType) => {
    if (data["HEX"]) {
      const hexString = data["HEX"]
      color.isValidHex(hexString) && props.onChange(hexString)
    } else if (data["R"] || data["G"] || data["B"]) {
      const rgba = {
        r: data["R"] || props.rgb.r,
        g: data["G"] || props.rgb.g,
        b: data["B"] || props.rgb.b,
        a: props.rgb.a || 1
      }

      const hexConverted = tinycolor(rgba).toHexString()
      props.onChange(hexConverted)
    }
  }

  return (
    <div className={styles["z-color-picker"]}>
      <div className={styles["z-color-picker--hue-and-saturation"]}>
        <div className={styles["z-color-picker--hueContainer"]}>
          <Hue
            direction="vertical"
            pointer={Pointer}
            width="10"
            style={{
              background: "black"
            }}
            {...props}
            className={styles["z-color-picker--hue"]}
          />
        </div>

        <div className={styles["z-color-picker--saturationContainer"]}>
          <Saturation {...props} />
        </div>
      </div>
      <div className={styles["z-color-picker--colors_inputs"]}>
        <div className={styles["z-color-picker--colors_inputs--hex"]}>
          <Typography type={TType.Body15_350} htmlElement="div">
            <EditableInput
              label="HEX"
              value={props.hex}
              onChange={handleInputChange}
              className={styles["input"]}
            />
          </Typography>
        </div>
        <div className={styles["z-color-picker--colors_inputs--rgb"]}>
          <Typography type={TType.Body15_350} htmlElement="div">
            <EditableInput
              label="R"
              value={props.rgb.r}
              onChange={handleInputChange}
              className={styles["input"]}
            />
          </Typography>
          <Typography type={TType.Body15_350} htmlElement="div">
            <EditableInput
              label="G"
              value={props.rgb.g}
              onChange={handleInputChange}
              className={styles["input"]}
            />
          </Typography>
          <Typography type={TType.Body15_350} htmlElement="div">
            <EditableInput
              label="B"
              value={props.rgb.b}
              onChange={handleInputChange}
              className={styles["input"]}
            />
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default CustomPicker(ColorPickerComponent)
