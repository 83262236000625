import { computed } from "mobx"
import { PackhelpEditableSvgImage } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { isAssetSvgImage } from "../../types/asset.types"
import { isSvgImageObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"
import { SvgImageObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/svg-image-object.controller"
import { ImageToolbarController } from "./image-toolbar.controller"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { colorsListDemo } from "../../libs/colors/hex-colors.list"

export class SvgImageToolbarController extends ImageToolbarController {
  public get cmykColorList(): ColorListArray[] {
    return colorsListDemo
  }

  @computed
  protected get activeObject(): PackhelpEditableSvgImage | null {
    const { activeObject } = this.productDriver.activeObjectDriver

    if (activeObject && isAssetSvgImage(activeObject)) {
      return activeObject
    }

    return null
  }

  @computed
  protected get activeObjectController(): SvgImageObjectController | null {
    const { activeObjectController } = this.productDriver.activeObjectDriver

    if (
      activeObjectController &&
      isSvgImageObjectController(activeObjectController)
    ) {
      return activeObjectController
    }

    return null
  }
}
