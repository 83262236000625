import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  PackhelpEditableGroup,
  PackhelpEditableImage,
  PackhelpEditableObject,
} from "../../../object-extensions/packhelp-objects"
import { ImageCreator } from "../canvas-object-creator/image.creator"
import { ImageAsset } from "../../../../../../models/image-asset"
import { calcObjectAbsoluteValues } from "../editable-group-controller/helpers"
import { isAssetImage } from "../../../../../../types/asset.types"

/**
 * We changed the way we deal with SVG images in the Editor.
 * Instead of rasterizing and resizing them to 2048x2048px
 * (which resulted in large size of design exports, cause every rasterized image was exported as a base64 string),
 * we started to treat them like AssetObject with ImageAssetMeta.
 *
 * We need to have a possibility to automatically transform old, rasterized SVG images to proper SVG objects.
 */
export class RasterizedSvgImageToSvgObjectTransformer {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async transform(
    image: PackhelpEditableImage
  ): Promise<PackhelpEditableObject> {
    const { assetImageMeta } = image
    const { isSvg } = assetImageMeta.original.metaInfo

    if (!isSvg) {
      return image
    }

    const imageCreator = new ImageCreator(this.vdEditor, image.originSpaceArea)
    const imageAsset = new ImageAsset(assetImageMeta)

    const object = await imageCreator.create(imageAsset)
    const imageAbsoluteValues = calcObjectAbsoluteValues(image)

    object.scaleX = image.getScaledWidth() / object.width!
    object.scaleY = image.getScaledHeight() / object.height!
    object.top = imageAbsoluteValues.top
    object.left = imageAbsoluteValues.left
    object.angle = imageAbsoluteValues.angle
    object.groupId = image.groupId

    return object
  }

  public async transformGroup(
    group: PackhelpEditableGroup
  ): Promise<PackhelpEditableGroup> {
    for (const groupObject of group.getObjects()) {
      if (isAssetImage(groupObject)) {
        const newGroupObject = await this.transform(groupObject)
        group.remove(groupObject)
        group.addWithUpdate(newGroupObject)
      }
    }

    return group
  }
}
