import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TileButton } from "../../../../atoms/TileButton/TileButton"
import { I18N } from "../../../../../i18n"
import disabledIconUrl from "../../../../../assets/_icons/disabled.svg"
import { TabSection } from "../../Tab/TabSection"
import {
  Pattern,
  PatternCategory,
  PatternColor,
  PatternColourKeys,
} from "../../../../../../models/pattern"
import { PatternColorPalette } from "../../../../molecules/PatternColorPalette/PatternColorPalette"
import { PatternCategorySection } from "./PatternCategorySection"
import { SkeletonTileList } from "../../../../atoms/Skeleton/SkeletonTileList"

const i18n = I18N.editorTools.patterns

export const PatternsSubTab = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.patterns,
    c.ecommerce,
    c.ui,
    c.designAndProductDriver,
  ])

  if (!containerSet) {
    return null
  }

  const {
    patterns: patternsContainer,
    ecommerce,
    ui,
    designAndProductDriver,
  } = containerSet

  if (!patternsContainer.available || !ecommerce.available) {
    return null
  }

  const { patternsStore, patternsController } = patternsContainer
  const { taxStore } = ecommerce
  const { templatesPatternsTabController } = ui
  const { productRenderPilot } = designAndProductDriver.productDriver.state
  const { isLoading, activePattern, categorizedPatterns, arePatternsTouched } =
    patternsStore
  const { isSelectingDisabled } = patternsController
  const { expandedPatternCategory } = templatesPatternsTabController

  const colorConfigs =
    activePattern?.getEditableColorConfigs(
      productRenderPilot.getColorPreset()
    ) || {}

  const onRemovePatternClick = () => {
    patternsController.removePattern()
  }

  const onPatternClick = (pattern: Pattern) => {
    patternsController.applyPattern(pattern)
  }

  const onExpandClick = (category: PatternCategory) => {
    templatesPatternsTabController.setExpandedPatternCategory(category)
  }

  const onColorChange = (key: PatternColourKeys, colorConfig: PatternColor) => {
    patternsStore.changeColor(key, colorConfig)
  }

  const onColorReset = () => {
    patternsStore.setOriginalPatterns()
  }

  return (
    <>
      {Object.keys(colorConfigs).length > 0 && (
        <TabSection e2eTargetName="pattern-colors">
          <PatternColorPalette
            colorConfigs={colorConfigs}
            onChange={onColorChange}
            onReset={arePatternsTouched ? onColorReset : undefined}
          />
        </TabSection>
      )}

      {!expandedPatternCategory && (
        <TabSection>
          <TileButton
            iconUrl={disabledIconUrl}
            label={t(i18n.noPattern)}
            onClick={onRemovePatternClick}
            active={!activePattern}
            disabled={isSelectingDisabled || !activePattern}
            type="light"
            analyticsSection="left-tab-templates"
            analyticsType="button"
            analyticsName="no-pattern"
            e2eTarget="button"
            e2eTargetName="no-pattern"
          />
        </TabSection>
      )}

      {isLoading ? (
        <SkeletonTileList repeats={12} cols={2} />
      ) : (
        categorizedPatterns.map((category) => {
          if (
            expandedPatternCategory &&
            expandedPatternCategory.name !== category.name
          ) {
            return null
          }

          return (
            <PatternCategorySection
              key={category.name}
              category={category}
              activePattern={activePattern}
              onExpandClick={onExpandClick}
              onPatternClick={onPatternClick}
              getPrice={(pattern) => taxStore.getPrice(pattern).displayPrice}
              disabled={isSelectingDisabled}
              expanded={expandedPatternCategory?.name === category.name}
            />
          )
        })
      )}
    </>
  )
})
