export enum DesignVersion {
  /**
   * Old pre versioned designs 2.2.0
   */
  "oldest" = 220,

  /**
   * 2.2.0
   * Old pre versioned designs
   */
  "v220" = 220,

  /**
   * 2.2.1
   * - We turn objects 180 on bottom of box designs
   */
  "v221" = 221,

  /**
   * 2.2.2
   * - We changed space mask format to be compatible with asset mask
   */
  "v222" = 222,

  /**
   * 2.2.3
   * - We fixed a typo in "maskParrentId" param
   * - We added custom shapes and colours for masks
   */
  "v223" = 223,

  /**
   * 2.2.4
   * - We changed pattern / background image clip paths from relative to absolute positioned
   */
  "v224" = 224,

  /**
   * 2.2.5
   * - Mask config is now exported with mask parent
   * - Editable object clipPath has been changed to group (includes space clipping and assets clipping objects)
   */
  "v225" = 225,

  /**
   * 2.2.6
   * Due to a bug with missing maskConfig for LogoPlaceholder, we need to bump version and run previous migration again
   * More: https://packhelp.slack.com/archives/C01MJGYB9GU/p1655310227254579
   */
  "v226" = 226,

  /**
   * 3.0.0
   *
   * Fabric v5
   */
  "v300" = 300,

  /**
   * 3.0.1
   * Clears incorrect mask export
   */
  "v301" = 301,

  /**
   * Latest default
   */
  "latest" = 301,
}
