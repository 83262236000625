import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { AlignmentHelper } from "../helpers/alignment.helper"
import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { isAssetGroup } from "../../../../../../types/asset.types"
import { SpaceClippingHelper } from "../helpers/space-clipping.helper"
import { ClipPathModes } from "../../../services/clip-path.generator"
import { DielineNavigator } from "../../dieline-navigator/dieline-navigator"

export abstract class Creator {
  constructor(protected readonly vdEditor: VirtualDielineEditor) {}

  protected alignObject(object: PackhelpEditableObject): void {
    AlignmentHelper.alignCenter(this.dielineNavigator, object)
  }

  protected async setObjectSpaceClipping(
    object: PackhelpEditableObject,
    spaceId: SpaceId
  ): Promise<void> {
    if (isAssetGroup(object)) {
      return
    }

    await SpaceClippingHelper.setSpaceClipping(
      this.vdEditor,
      spaceId,
      object,
      ClipPathModes.FillMode
    )
  }

  protected rotateObject(
    object: PackhelpEditableObject,
    spaceId: SpaceId
  ): void {
    const currentRotation = this.dielineNavigator.getCurrentRotation()
    const spaceRotation = this.dielineNavigator.getSpaceRotation(spaceId)

    if (currentRotation) {
      return
    }

    object.rotate(-spaceRotation)
  }

  private get dielineNavigator(): DielineNavigator {
    return this.vdEditor.dielineNavigator
  }
}
