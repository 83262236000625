import React from "react"
import { IntlProvider } from "react-intl"

import { useIntlImperative } from "./i18n/use-imperative-intl"
import * as locales from "./others/async-18n-injection"
import { getLanguageTag } from "./others/i18n"

import type { Locale } from "../../configs/region-config/interfaces/js/region-config"

export { getLanguageTag }

export enum Lang {
  csCZ = "cs-CZ",
  deDE = "de-DE",
  enUS = "en-US",
  esES = "es-ES",
  frFR = "fr-FR",
  itIT = "it-IT",
  plPL = "pl-PL",
  ptPT = "pt-PT",
  svSE = "sv-SE",
  roRO = "ro-RO",
  nlNL = "nl-NL",
}

export const langToLocale = new Map<Locale, Lang>([
  ["cz", Lang.csCZ],
  ["cs", Lang.csCZ],
  ["de", Lang.deDE],
  ["en", Lang.enUS],
  ["es", Lang.esES],
  ["fr", Lang.frFR],
  ["it", Lang.itIT],
  ["pl", Lang.plPL],
  ["pt", Lang.ptPT],
  ["sv", Lang.svSE],
  ["ro", Lang.roRO],
  ["nl", Lang.nlNL],
])

export const languageMap = {
  [Lang.csCZ]: locales.loadCzLocale,
  [Lang.deDE]: locales.loadDeLocale,
  [Lang.enUS]: locales.loadEnLocale,
  [Lang.esES]: locales.loadEsLocale,
  [Lang.frFR]: locales.loadFrLocale,
  [Lang.itIT]: locales.loadItLocale,
  [Lang.plPL]: locales.loadPlLocale,
  [Lang.svSE]: locales.loadSvLocale,
  [Lang.ptPT]: locales.loadPtLocale,
  [Lang.roRO]: locales.loadRoLocale,
  [Lang.nlNL]: locales.loadNlLocale,
}

/**
 * We probably need this component less and less.
 * Used for now but staged for removal. useTranslateLuke
 */
export const AsyncIntContainer = (props: {
  children: React.ReactElement
  key?: string
  languageTag?: Lang
}) => {
  const intl = useIntlImperative(props.languageTag)
  if (intl == null) return null

  return (
    <IntlProvider messages={intl.messages} locale={intl.locale}>
      {props.children}
    </IntlProvider>
  )
}
