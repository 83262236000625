import { computed, observable, makeObservable, action } from "mobx"
import { ShapesStore } from "../../stores/shapes.store"
import ProductDriver from "../../drivers/product.driver"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import {
  PackhelpEditableObject,
  PackhelpGroupType,
} from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { Shape } from "../../models/shape"
import Colour from "../../models/colour"
import { isInteractiveCanvas } from "../../types/asset.types"
import { CanvasObjectControllable } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"
import {
  colorsListDemo,
  colorsListDemoWithBlankColor,
} from "../../libs/colors/hex-colors.list"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { isMultiColor } from "../../libs/products-render-config/helpers"

export class MaskToolbarController {
  @observable public isMaskApplied = false
  @observable public selectedShape?: Shape
  @observable public selectedColor?: Colour

  private readonly productDriver: ProductDriver
  private readonly ee: AllEditorEventsEmitter
  private readonly shapesStore: ShapesStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    stores: {
      shapesStore: ShapesStore
    }
  ) {
    this.productDriver = services.productDriver
    this.ee = services.ee
    this.shapesStore = stores.shapesStore

    this.selectedShape = this.shapesStore.maskShapes[0]

    makeObservable(this)

    this.ee.on(eventTree.activeObject.selected, this.refreshState.bind(this))
  }

  @computed
  public get isToolbarAvailable(): boolean {
    const { productRenderPilot } = this.productDriver.state

    if (
      !productRenderPilot.uiConfig.features.mask ||
      this.activeObject?.maskController?.getConfig().isEditingDisabled ||
      this.activeObject?.type === PackhelpGroupType.activeSelection ||
      this.activeObjectController?.hasGroup()
    ) {
      return false
    }

    return this.isObjectMaskAvailable || this.isClippingMaskAvailable
  }

  @computed
  public get isColorPickerAvailable(): boolean {
    return this.isMaskApplied && this.isObjectMaskAvailable
  }

  @computed
  public get isShapePickerAvailable(): boolean {
    return this.isMaskApplied
  }

  @computed
  public get availableColors(): ColorListArray[] {
    if (this.isClippingMaskAvailable) {
      return colorsListDemoWithBlankColor
    }

    return colorsListDemo
  }

  @computed
  public get availableShapes(): Shape[] {
    return this.shapesStore.maskShapes
  }

  public async toggleMask(): Promise<void> {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    const vdEditor = this.getVirtualDielinEditor()

    if (!isInteractiveCanvas(vdEditor.fabricCanvas)) {
      return
    }

    const hasMask = this.activeObjectController.hasMask()

    if (hasMask) {
      vdEditor.fabricCanvas.setActiveObject(this.activeObject)
      this.activeObject.maskController?.dispose()
    } else {
      await vdEditor.assetsModule.createMaskAndAttachItToObject(
        this.activeObject
      )
    }

    this.ee.emit(
      eventTree.productDriver.maskToggled,
      this.activeObject,
      !hasMask
    )

    this.refreshState()
  }

  public async changeMaskShape(shape: Shape): Promise<void> {
    if (!this.activeObject) {
      return
    }

    const maskController = this.activeObject.maskController

    if (!maskController) {
      return
    }

    this.shapesStore.setIsLoadingSingleShape(true)

    maskController.selectMaskParent()
    await maskController.changeShape(shape)

    this.ee.emit(
      eventTree.productDriver.maskModified,
      this.activeObject,
      maskController.getShape(),
      maskController.getColor()
    )

    this.refreshState()

    this.shapesStore.setIsLoadingSingleShape(false)
  }

  public async changeMaskColor(color: Colour): Promise<void> {
    if (!this.activeObject) {
      return
    }

    const maskController = this.activeObject.maskController

    if (!maskController) {
      return
    }

    maskController.selectMaskParent()
    await maskController.changeColor(color)

    this.ee.emit(
      eventTree.productDriver.maskModified,
      this.activeObject,
      maskController.getShape(),
      maskController.getColor()
    )

    this.refreshState()
  }

  @action
  private refreshState(): void {
    const maskController = this.activeObject?.maskController

    this.isMaskApplied = !!maskController

    if (maskController) {
      this.selectedShape = maskController.getShape()
      this.selectedColor = maskController.getColor()
    }
  }

  @computed
  private get isObjectMaskAvailable(): boolean {
    const { productRenderPilot } = this.productDriver.state

    return isMultiColor(productRenderPilot.getColorMode())
  }

  @computed
  private get isClippingMaskAvailable(): boolean {
    const { activeContext } = this.productDriver.state

    return this.productDriver.backgroundsDriver.isClippingMaskAvailable(
      activeContext
    )
  }

  private getVirtualDielinEditor(): VirtualDielineEditor {
    const { renderEngine, activeContext } = this.productDriver.state

    return renderEngine!.getVirtualDielineEditor(activeContext)
  }

  @computed
  private get activeObject(): PackhelpEditableObject | null {
    return this.productDriver.activeObjectDriver.activeObject
  }

  @computed
  private get activeObjectController(): CanvasObjectControllable | null {
    return this.productDriver.activeObjectDriver.activeObjectController
  }
}
