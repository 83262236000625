import VirtualDielineEditor from "./modules/vd-editor/virtual-dieline-editor"
import { ThreeDimRenderer } from "./modules/3d-renderer/three-dim-renderer"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { EditContext, ModelContext } from "../libs/products-render-config/types"
import { VirtualDielinesData } from "../types/asset.types"
import { ThumbnailGenerator } from "./thumbnail.generator"
import {
  RenderEngineExport,
  ThumbnailGeneratorConfig,
  VirtualDielineDesign,
} from "./types"
import { CanvasPreviewPreparator } from "./modules/vd-editor/services/canvas-preview.preparator"
import { ModelConfigProvider } from "./modules/3d-renderer/model-config.provider"

export type RenderEngineExportOptions = { withPreview?: boolean }

export class RenderEngine {
  private tickActionInNextFrameId: number | undefined
  public readonly has3D: boolean

  constructor(
    private readonly vdEditors: VirtualDielineEditor[],
    private readonly modelConfigProvider: ModelConfigProvider,
    private productRenderPilot: ProductRenderPilot,
    private readonly threeDimRenderer?: ThreeDimRenderer
  ) {
    this.has3D = !!threeDimRenderer
  }

  public isModelFoldingSupported(): boolean {
    if (!this.threeDimRenderer) {
      return false
    }

    return this.threeDimRenderer.isFoldingSupported()
  }

  public async reloadModel(modelContext: ModelContext): Promise<void> {
    if (!this.threeDimRenderer) {
      return
    }

    const modelConfig = await this.modelConfigProvider.call(
      this.productRenderPilot,
      modelContext
    )

    await this.threeDimRenderer.init(modelConfig)
  }

  public async changeModelContext(modelContext: ModelContext): Promise<void> {
    if (!this.threeDimRenderer) {
      return
    }

    const modelConfig = await this.modelConfigProvider.call(
      this.productRenderPilot,
      modelContext
    )

    await this.threeDimRenderer.changeContext(modelConfig)
  }

  public async reloadModelTextures(modelContext: ModelContext): Promise<void> {
    if (!this.threeDimRenderer) {
      return
    }

    const modelConfig = await this.modelConfigProvider.call(
      this.productRenderPilot,
      modelContext
    )

    this.threeDimRenderer.setModelTextures(modelConfig.textureDefinitions)
  }

  public touchModelTextures(): void {
    this.threeDimRenderer?.touchTextures()
  }

  public async generateDielinePreview(
    editContext: EditContext
  ): Promise<string | undefined> {
    try {
      const vdEditor = this.getVirtualDielineEditor(editContext)
      const preparator = new CanvasPreviewPreparator(vdEditor, {
        enableRetinaScaling: false,
      })
      const canvasPreview = await preparator.getCanvasToPreview({
        backgroundTextureEnabled: false,
        editZoneBackgroundTextureEnabled: false,
      })

      return canvasPreview.toDataURL()
    } catch (e: any) {
      window.Sentry?.captureException(
        new Error(`Could not generate dieline preview: ${e.message}`)
      )
    }
  }

  public updateProductRenderPilot(newPilot: ProductRenderPilot) {
    this.productRenderPilot = newPilot
  }

  public loadDesign(virtualDielineDesign: VirtualDielineDesign) {
    return Promise.all(
      this.getVirtualDielineEditors().map(async (virtualDieline) => {
        await virtualDieline.import(
          virtualDielineDesign.virtualDielines[virtualDieline.editContext]!,
          virtualDielineDesign.designDataFormatVersion
        )
      })
    )
  }

  public prepareEditing() {
    return Promise.all(
      this.getVirtualDielineEditors().map((vdEditor) =>
        vdEditor.prepareEditing()
      )
    )
  }

  public clearActiveObject() {
    for (const virtualDielineEditor of this.getVirtualDielineEditors()) {
      virtualDielineEditor.assetsModule.clearActiveObject()
    }
  }

  public getThreeDimRenderer(): ThreeDimRenderer | undefined {
    return this.threeDimRenderer
  }

  public set2dInterfaceObjectsVisibility(visible: boolean) {
    this.getVirtualDielineEditors().forEach((vdEditor) => {
      vdEditor.assetsModule.set2dInterfaceObjectsVisibility(visible)
    })
  }

  public getVirtualDielineEditor(
    editContext: EditContext
  ): VirtualDielineEditor {
    const vdEditor = this.vdEditors.find(
      (vdEditor) => vdEditor.editContext === editContext
    )

    if (!vdEditor) {
      throw new Error("VirtualDieline is not ready")
    }

    return vdEditor
  }

  public onNextRendererTick = (action: Function) => {
    if (this.tickActionInNextFrameId)
      cancelAnimationFrame(this.tickActionInNextFrameId)
    this.tickActionInNextFrameId = requestAnimationFrame(() => action())
  }

  public getVirtualDielineEditors(): VirtualDielineEditor[] {
    return this.vdEditors
  }

  public offRendererTextures(): void {
    this.getVirtualDielineEditors().forEach((vdEditor) => {
      vdEditor.spaceHighlightModule.clearHighlight()
    })

    if (this.threeDimRenderer) {
      this.threeDimRenderer.stopAnimate()
    }
  }

  public unMountRenderEngine(): void {
    this.unMountThreeDimRenderer()
    this.unMountVirtualDielines()
  }

  public async export(
    options: RenderEngineExportOptions = { withPreview: false }
  ): Promise<RenderEngineExport> {
    const thumbnail = await this.createThumbnail()
    const virtualDielines = await this.exportVirtualDielines()

    if (options.withPreview) {
      for (const [editContext, virtualDielineData] of Object.entries(
        virtualDielines
      )) {
        const isActive = this.productRenderPilot.isPrintActiveFor(
          editContext as EditContext
        )

        if (isActive) {
          virtualDielineData.preview = await this.generateDielinePreview(
            editContext as EditContext
          )
        }
      }
    }

    return {
      thumbnail,
      virtualDielines,
    }
  }

  public async exportVirtualDielines(): Promise<VirtualDielinesData> {
    const virtualDielinesData = {}

    await Promise.all(
      this.getVirtualDielineEditors().map(async (virtualDielineEditor) => {
        virtualDielinesData[virtualDielineEditor.getEditContext()] =
          await virtualDielineEditor.export()
      })
    )

    return virtualDielinesData
  }

  public rerender(): void {
    for (const vdEditor of this.getVirtualDielineEditors()) {
      vdEditor.fabricCanvas.renderAll()
    }

    this.touchModelTextures()
  }

  public async createThumbnail(
    config?: ThumbnailGeneratorConfig
  ): Promise<string> {
    try {
      const thumbGen = new ThumbnailGenerator(
        this.vdEditors,
        this.modelConfigProvider,
        this.productRenderPilot,
        config
      )

      return await thumbGen.createBase64Thumbnail(this.has3D)
    } catch (e: any) {
      console.log(e)
      window.Sentry?.captureException(
        new Error(`Could not generate thumbnail: ${e.message}`)
      )

      return ""
    }
  }

  private unMountVirtualDielines(): void {
    this.getVirtualDielineEditors().map((vdEditor) => {
      vdEditor.dispose()
    })
  }

  private unMountThreeDimRenderer(): void {
    this.threeDimRenderer?.dispose()
  }
}
