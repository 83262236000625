import THREE from "../../../../libs/vendors/THREE"

import { isGroup, isMesh, SvgModelConfig } from "../types"
import { rotateAboutPoint } from "../helpers/rotate-about-point"
import { ModelContext } from "../../../products-render-config/types"
import { RotationPointCalculator } from "../calculators/rotation-point.calculator"

export class FoldingManager {
  private steps: number = 0
  private percentage: number = 0
  private angles: Record<string, number> = {}

  constructor(
    private readonly model: THREE.Group,
    private readonly config: SvgModelConfig["folding"]
  ) {}

  public setSteps(steps: number): void {
    this.steps = steps
  }

  public getPercentage(): number {
    return this.percentage
  }

  public setPercentage(percentage: number): void {
    this.percentage = percentage

    this.fold(this.model)
  }

  public getTargetPercentage(modelContext: ModelContext): number {
    return this.config.target[modelContext] || 100
  }

  private fold(object: THREE.Object3D): void {
    object.children.forEach((child) => {
      if (isGroup(child)) {
        return this.fold(child)
      }

      const { folding } = child.userData

      if (!folding) {
        return this.fold(child)
      }

      if (!isMesh(child)) {
        return this.fold(child)
      }

      const foldingStep = this.percentage / (100 / this.steps)
      let currentPercentage = foldingStep % 1

      if (folding.step <= foldingStep) {
        currentPercentage = 1
      }

      if (folding.step > Math.floor(foldingStep) + 1) {
        currentPercentage = 0
      }

      if (this.percentage === 100) {
        currentPercentage = 1
      }

      const currentAngle = folding.angle * currentPercentage
      const currentStepAngle = this.getAngle(child.name) - currentAngle
      this.setAngle(child.name, currentAngle)

      child.visible = this.isValidToShow(child, currentAngle)

      rotateAboutPoint(
        child,
        new RotationPointCalculator(child, folding).call(),
        folding.axis,
        (-currentStepAngle * Math.PI) / 180
      )

      this.fold(child)
    })
  }

  private isValidToShow(object: THREE.Mesh, currentAngle: number): boolean {
    const { folding } = object.userData

    if (!folding || !folding.hidden) {
      return true
    }

    return currentAngle !== folding.angle
  }

  private setAngle(name: string, angle: number): void {
    this.angles[name] = angle
  }

  private getAngle(name: string): number {
    return this.angles[name] || 0
  }
}
