import React from "react"
import { PickerToggle } from "../../../atoms/PickerToggle/PickerToggle"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { Colour } from "../../../../../models/colour"
import { PantoneColorPreset } from "../../../../../libs/products-render-config/types"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"
import styles from "./ColorConfiguratorInline.module.scss"
import { PantoneConfigurator } from "../../../molecules/PantoneConfigurator/PantoneConfigurator"
import { HexConfigurator } from "../../../molecules/HexConfigurator/HexConfigurator"
import { AnalyticsSection } from "../../../types"
import { PrintingInformation } from "../../PrintingInformation/PrintingInformation"
import { isMonoPantone } from "../../../../../libs/products-render-config/helpers"

interface ColorConfiguratorProps {
  title?: string
  selectedColor?: Colour
  colorsList?: ColorListArray[]
  pantoneColorPreset?: PantoneColorPreset
  onColorSelect: (color: Colour) => void
  analyticsSection?: AnalyticsSection
}

export function ColorConfiguratorInline({
  selectedColor,
  colorsList,
  title,
  onColorSelect,
  pantoneColorPreset,
  analyticsSection,
}: ColorConfiguratorProps) {
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver } = designAndProductDriverContainer
  const { productRenderPilot } = productDriver.state

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <PickerToggle
          backgroundColor={selectedColor}
          e2eTargetName="current-color"
        />
      </header>

      {isMonoPantone(productRenderPilot.getColorMode()) ? (
        <PantoneConfigurator
          selectedColor={selectedColor}
          onColorSelect={onColorSelect}
          pantoneColorPreset={
            pantoneColorPreset || productRenderPilot.getPantoneColorPreset()
          }
          analyticsSection={analyticsSection}
        />
      ) : (
        <HexConfigurator
          selectedColor={selectedColor}
          onColorSelect={onColorSelect}
          colorsList={colorsList}
          analyticsSection={analyticsSection}
        />
      )}

      <PrintingInformation />
    </div>
  )
}
