import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import _PANTONE_COLORS_MAP from "dsl/src/organisms/PickerPantone/PantoneColorsMap"
import _uniqBy from "lodash/uniqBy"
import { PantoneColorPreset } from "../products-render-config/types"
import Colour from "../../models/colour"
import {
  PANTONE_COLORS_DB_METALLIC,
  PANTONE_COLORS_DB_TISSUE_BACKGROUND,
  PANTONE_COLORS_REPLICATOR_PATTERN,
} from "./pantone-custom-palettes"
import _uniq from "lodash/uniq"
import _compact from "lodash/compact"

/** standard supported colors, i.e. regular colors without fancy Pantone like gold, silver, neon etc. */
export const PANTONE_COLORS_DB_STANDARD = _uniqBy(
  _PANTONE_COLORS_MAP,
  (color) => color.pantoneName
)

/** all Pantone colors ever, standard set + metallic */
export const PANTONE_COLORS_DB_ALL = _uniq([
  ...PANTONE_COLORS_DB_STANDARD,
  ...PANTONE_COLORS_DB_METALLIC,
  ...PANTONE_COLORS_DB_TISSUE_BACKGROUND,
  ...PANTONE_COLORS_REPLICATOR_PATTERN,
])

export const PANTONE_COLORS_DB_WHITE = PANTONE_COLORS_DB_STANDARD.find(
  (value) => value.pantoneName === "White"
)

export const PANTONE_COLORS_DB_BLACK = PANTONE_COLORS_DB_STANDARD.find(
  (value) => value.pantoneName === "Neutral Black C"
)

/** list of Pantone colors that are able to cover black foil */
export const PANTONE_COLORS_DB_INK_COVERING_BLACK_FOIL = _compact([
  PANTONE_COLORS_DB_WHITE,
  ...PANTONE_COLORS_DB_METALLIC,
])

/** list of Pantone colors that are able to cover grey foil */
export const PANTONE_COLORS_DB_INK_COVERING_GREY_FOIL = _compact([
  PANTONE_COLORS_DB_WHITE,
  PANTONE_COLORS_DB_BLACK,
])

/** tissue paper available colors */
export const PANTONE_COLORS_DB_TISSUE_BACKGROUND_ALL = _compact([
  PANTONE_COLORS_DB_WHITE,
  ...PANTONE_COLORS_DB_TISSUE_BACKGROUND,
])

export class PantoneColorsDatabase {
  public getByPreset(preset: PantoneColorPreset): PantonePickerColorObject[] {
    switch (preset) {
      case PantoneColorPreset.ALL:
        return PANTONE_COLORS_DB_ALL
      case PantoneColorPreset.STANDARD:
        return PANTONE_COLORS_DB_STANDARD
      case PantoneColorPreset.METALLIC:
        return PANTONE_COLORS_DB_METALLIC
      case PantoneColorPreset.COVERING_BLACK_FOIL:
        return PANTONE_COLORS_DB_INK_COVERING_BLACK_FOIL
      case PantoneColorPreset.COVERING_GREY_FOIL:
        return PANTONE_COLORS_DB_INK_COVERING_GREY_FOIL
      case PantoneColorPreset.TISSUE_BACKGROUND:
        return PANTONE_COLORS_DB_TISSUE_BACKGROUND_ALL
      case PantoneColorPreset.PATTERN_REPLICATOR_PALETTE:
        return PANTONE_COLORS_REPLICATOR_PATTERN
    }
  }

  private getSearchList = (preset?: PantoneColorPreset) =>
    preset !== undefined ? this.getByPreset(preset) : PANTONE_COLORS_DB_ALL

  public getByPantoneName = (
    name: string,
    searchInPreset?: PantoneColorPreset
  ): PantonePickerColorObject | undefined =>
    this.getSearchList(searchInPreset).find(
      (pantoneColor) => pantoneColor.pantoneName === name
    )

  public getByHex = (
    hex: string,
    searchInPreset?: PantoneColorPreset
  ): PantonePickerColorObject | undefined =>
    this.getSearchList(searchInPreset).find(
      (pantoneColor) => pantoneColor.color === hex
    )

  public getByColour = (
    colour: Colour,
    searchInPreset?: PantoneColorPreset
  ): PantonePickerColorObject | undefined => {
    let pantone: PantonePickerColorObject | undefined = undefined
    const name = colour.getPantoneName()
    if (name) pantone = this.getByPantoneName(name, searchInPreset)
    return pantone || this.getByHex(colour.getHex(), searchInPreset)
  }
}
