import React from "react"
import { observer } from "mobx-react-lite"
import { ColorConfiguratorInline } from "../../../configurators/ColorConfigurator/ColorConfiguratorInline"
import { I18N } from "../../../../../i18n"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { Colour } from "../../../../../../models/colour"
import { TabSection } from "../../Tab/TabSection"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"

export const BackgroundColorSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }
  const { ui } = containerSet
  const { backgroundTabController } = ui
  const { selectedBackgroundColor, pantoneColorPreset, hexColorsList } =
    backgroundTabController

  const onColorSelect = (color?: Colour) => {
    backgroundTabController.setBackgroundColor(color)
  }

  return (
    <TabSection>
      <ColorConfiguratorInline
        title={t(I18N.component.colorPicker.background)}
        onColorSelect={onColorSelect}
        selectedColor={selectedBackgroundColor}
        colorsList={hexColorsList}
        pantoneColorPreset={pantoneColorPreset}
        analyticsSection="left-tab-background"
      />
    </TabSection>
  )
})
