import THREE from "../../../../libs/vendors/THREE"
import { ModelStrategy } from "./model.strategy"
import {
  EditContext,
  TextureDefinition,
} from "../../../../libs/products-render-config/types"
import ObjLoader from "../../../../libs/services/loaders/obj.loader"
import threeLoadTexturesConfig from "../services/three-load-textures"
import {
  isMesh,
  ModelSettings,
  ObjModelConfig,
  ObjModelTexture,
  RendererConfig,
} from "../types"
import { CameraPositionCalculator } from "../calculators/camera-position.calculator"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { isMono } from "../../../../libs/products-render-config/helpers"

enum ObjMeshType {
  SHADOW_PLANE = "Shadow_Plane",
  CAN_CAP = "_cap",
}

export class ObjModelStrategy extends ModelStrategy {
  private model?: THREE.Group
  private settings: ModelSettings = {}
  private readonly textures: {
    canvas: Record<string, THREE.CanvasTexture>
    model: ObjModelTexture[]
  } = {
    canvas: {},
    model: [],
  }

  constructor(
    canvases: Record<string, HTMLCanvasElement>,
    mode: RendererConfig["mode"]
  ) {
    super(canvases, mode)

    for (const [editContext, canvas] of Object.entries(this.vdCanvases)) {
      this.textures.canvas[editContext] = new THREE.CanvasTexture(canvas)
    }
  }

  public getModel(): THREE.Group | undefined {
    return this.model
  }

  public async init(config: ObjModelConfig): Promise<THREE.Group> {
    const [model, textures] = await Promise.all([
      ObjLoader.load(config.objFilePath),
      threeLoadTexturesConfig(config.textureDefinitions),
    ])

    this.model = model
    this.settings = config.settings
    this.textures.model = textures

    this.prepareModel(model, textures)
    this.refreshTextures()

    return this.model
  }

  public isFoldingSupported(): boolean {
    return false
  }

  public getFoldingPercentage(): number {
    return 100
  }

  public setFoldingPercentage(): void {}

  public getTargetFoldingPercentage(): number {
    return 100
  }

  public getFoldingStep(): number {
    return 0
  }

  public async changeContext(config: ObjModelConfig): Promise<THREE.Group> {
    return this.init(config)
  }

  public fitModelToScreen(orbitControls: OrbitControls): void {
    if (!this.model) {
      return
    }

    this.model.traverse((child) => {
      if (!isMesh(child) || child.name !== EditContext.OUTSIDE) {
        return
      }

      new CameraPositionCalculator(
        this.model!,
        child,
        this.settings.cameraSettings?.[this.mode]
      ).setCameraPosition(orbitControls)

      orbitControls.update()
    })
  }

  public dispose(): void {
    if (!this.model) {
      return
    }

    this.model = undefined
  }

  public async setModelTextures(
    textureDefinitions: TextureDefinition[]
  ): Promise<void> {
    this.textures.model = await threeLoadTexturesConfig(textureDefinitions)

    this.refreshTextures()
  }

  public touchTextures(): void {
    for (const texture of Object.values(this.textures.canvas)) {
      texture.needsUpdate = true
    }
  }

  public isFogRequired(): boolean {
    return false
  }

  private refreshTextures(): void {
    if (!this.model) {
      return
    }

    this.model.traverse((child) => {
      if (this.textures.canvas[child.name]) {
        return this.applyBlendAndShader(child.name as EditContext)
      }

      if (!isMesh(child)) {
        return
      }

      if (Object.values(EditContext).includes(child.name as EditContext)) {
        const textureConfig = this.textures.model.find(
          (texture) => texture.type === child.name
        )

        if (textureConfig) {
          const material = child.material as THREE.MeshPhongMaterial
          material.map = textureConfig.texture
        }
      }
    })
  }

  private applyBlendAndShader(editContext: EditContext): void {
    if (!this.model) {
      return
    }

    this.model.traverse((child) => {
      if (!isMesh(child)) {
        return
      }

      const childMaterial = child.material as THREE.MeshPhongMaterial

      if (
        child.name === ObjMeshType.SHADOW_PLANE &&
        this.mode === "thumbnail"
      ) {
        childMaterial.visible = false

        return
      }

      const baseTextureConfig = this.textures.model.find(
        (texture) => texture.type === child.name
      )
      const canvasTexture = this.textures.canvas[editContext]
      const textureConfig = this.textures.model.find(
        (texture) => texture.type === child.name
      )

      if (editContext !== child.name || !canvasTexture || !textureConfig) {
        return
      }

      const envMapConfig = this.textures.model.find(
        (texture) => texture.type === "env_map"
      )

      child.userData = {
        editContext,
      }

      canvasTexture.magFilter = THREE.LinearFilter
      canvasTexture.anisotropy = this.settings.anisotropy || 1

      let materialConfig = {
        transparent: true,
        map: canvasTexture,
      }

      if (envMapConfig && child.name === EditContext.OUTSIDE) {
        materialConfig = Object.assign(materialConfig, {
          envMap: envMapConfig.texture,
          combine: THREE.MultiplyOperation,
          // TODO: Why mailer boxes use negative value of reflectivity?
          reflectivity: childMaterial.userData.isUserDefinedMaterial
            ? childMaterial.reflectivity
            : -0.1,
        })
      }

      const blendOpacity = this.settings.blendOpacity || 1
      const colorMode = this.settings.colorMode
      const material = new THREE.MeshPhongMaterial(materialConfig)

      material.onBeforeCompile = function (shader) {
        shader.uniforms.base = {
          value: baseTextureConfig?.texture,
        }

        shader.uniforms.blend = {
          value: canvasTexture,
        }

        shader.uniforms.blendOpacity = {
          value: blendOpacity,
        }

        shader.uniforms.isMonochrome = {
          value: colorMode && isMono(colorMode),
        }

        shader.vertexShader = `
            varying vec2 vUv;
            
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `

        shader.fragmentShader = `
            precision mediump float;
    
            varying vec2 vUv;
            uniform sampler2D blend;
            uniform sampler2D base;
            uniform float blendOpacity;
            uniform bool isMonochrome;
    
            void main() {
              vec4 Ca = texture2D(blend, vUv);
              vec4 Cb = texture2D(base, vUv);
              vec3 c;
              
              if (isMonochrome) {
                c = Ca.rgb * Ca.a + Cb.rgb * Cb.a * (1.0 - Ca.a);
              } else {
                c = Ca.rgb * Ca.a * Cb.rgb * blendOpacity + Cb.rgb * Cb.a * (1.0 - Ca.a * blendOpacity);
              }
              
              gl_FragColor = vec4(c, 1.0);
            }
          `
      }

      childMaterial.dispose()

      child.material = material
    })
  }

  private prepareModel(model: THREE.Group, textures: ObjModelTexture[]): void {
    model.traverse((child) => {
      if (!isMesh(child)) {
        return
      }

      const childMaterial = (child.material as THREE.MeshPhongMaterial).clone() //TODO: optimize
      childMaterial.transparent = true

      const textureConfig = textures.find(
        (texture) => texture.type === child.name
      )
      const envMapConfig = textures.find(
        (texture) => texture.type === "env_map"
      )

      // TODO: previosuly (before paper cans) env map was applied only to 'outside' in applyBlendAndShader.
      // Cans have non-editable meshes that require env maps - caps.
      // If all meshes (in boxes) had their own materials with reflectivity 0, there would be no need for this hack.
      if (envMapConfig?.texture && child.name?.includes(ObjMeshType.CAN_CAP)) {
        childMaterial.envMap = envMapConfig.texture
      }

      if (textureConfig) {
        childMaterial.map = textureConfig.texture
      }

      child.material = childMaterial
    })

    model.position.y = 0
    model.position.z = 0
    model.position.x = 0
    model.matrixAutoUpdate = false
  }
}
