import React, { useRef, useEffect } from "react"
import { CanvasNotificationPopover } from "../../CanvasNotificationPopover/CanvasNotificationPopover"
import { EditZoneRulers } from "../../EditZoneRulers/EditZoneRulers"
import { EditContext } from "../../../../../libs/products-render-config/types"
import { observer } from "mobx-react-lite"
import styles from "./TwoDimensionalScene.module.scss"
import { TwoDimensionalSceneMountpoint } from "./partials/TwoDimensionalSceneMountpoint"
import ProductDriver from "../../../../../drivers/product.driver"
import { CanvasObjectContextMenu } from "../../CanvasObjectContextMenu/CanvasObjectContextMenu"
import cxBinder from "classnames/bind"
import { CanvasSpaceContextMenu } from "../../CanvasSpaceContextMenu/CanvasSpaceContextMenu"

const cx = cxBinder.bind(styles)

interface TwoDimensionalSceneProps {
  productDriver: ProductDriver
  onSceneMount: (mountPoints) => void
}

const editContexts = Object.values(EditContext)

export const TwoDimensionalScene = observer(function ({
  productDriver,
  onSceneMount,
}: TwoDimensionalSceneProps) {
  const { activeContext, activeViewType } = productDriver.state

  const createRefs = () => {
    const refs = {}

    editContexts.map((context) => {
      refs[context] = undefined
    })

    return refs
  }

  const itemsRef = useRef(createRefs())

  useEffect(() => {
    onSceneMount(itemsRef.current)
  }, [activeContext])

  return (
    <div className={cx("wrapper", activeViewType)}>
      {editContexts.map((context) => {
        return (
          <TwoDimensionalSceneMountpoint
            key={context}
            editContext={context}
            viewType={activeViewType}
            getRef={(el) => (itemsRef.current[context] = el)}
            active={activeContext === context}
          />
        )
      })}

      <div className={styles.ui}>
        <EditZoneRulers />
        <CanvasNotificationPopover />
        <CanvasObjectContextMenu />
        <CanvasSpaceContextMenu />
      </div>
    </div>
  )
})
