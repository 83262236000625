import { BaseController } from "./base.controller"
import { PackhelpEditableObject } from "../../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { SpaceClippingHelper } from "../../helpers/space-clipping.helper"
import { ClipPathModes } from "../../../../services/clip-path.generator"
import { CanvasEvent } from "../../../../../../../events/partials/domain.events"

export class SpaceClippingController extends BaseController {
  constructor(
    protected readonly canvasObject: PackhelpEditableObject,
    protected readonly vdEditor: VirtualDielineEditor
  ) {
    super(canvasObject)
  }

  public async toggle(): Promise<void> {
    this.canvasObject.isSpaceClippingDisabled =
      !this.canvasObject.isSpaceClippingDisabled

    if (this.canvasObject.isSpaceClippingDisabled) {
      await SpaceClippingHelper.removeSpaceClipping(this.canvasObject)
    } else {
      await SpaceClippingHelper.setSpaceClipping(
        this.vdEditor,
        this.canvasObject.originSpaceArea,
        this.canvasObject,
        ClipPathModes.FillMode
      )
    }

    this.canvasObject.maskController?.reinit()
    this.canvas.fire(CanvasEvent.objectModified, {
      target: this.canvasObject,
    })

    this.canvas.renderAll()
  }
}
