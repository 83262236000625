import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Notification } from "../../atoms/Notification/Notification"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import styles from "./PrintingInformation.module.scss"

const i18n = I18N.component.printingInformation

export const PrintingInformation = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { lightColorsLimitation, colorsOnKraft } =
    containerSet.designAndProductDriver.productDriver.state.productRenderPilot
      .uiConfig.disclaimers

  if (!lightColorsLimitation && !colorsOnKraft) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <Notification type="warning">
        {t(i18n.title)}:
        <ul>
          {lightColorsLimitation && (
            <li>{t(i18n.disclaimers.lightColorsLimitation)}</li>
          )}

          {colorsOnKraft && <li>{t(i18n.disclaimers.colorsOnKraft)}</li>}
        </ul>
      </Notification>
    </div>
  )
})
