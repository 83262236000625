import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Checkbox.module.scss"
import { CheckIcon } from "../../assets/_svgr/icons/Check"

const cx = cxBinder.bind(styles)

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type InputWitoutSize = Omit<React.HTMLProps<HTMLInputElement>, "size">

export interface CheckboxProps extends InputWitoutSize {
  disabled?: boolean
  error?: boolean
  checked: boolean
  small?: boolean
  right?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  e2eTarget?: string
  e2eTargetName?: string
}

const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    disabled,
    error,
    onChange,
    small,
    right,
    e2eTarget = "checkbox",
    e2eTargetName,
    ...other
  } = props

  return (
    <div
      className={cx("wrapper", {
        "wrapper--small": small,
        "wrapper--left": !right,
        "wrapper--right": right
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {/* @ts-ignore */}
      <input
        type="checkbox"
        className={styles.input}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        e2e-target="checkbox-input"
        {...other}
      />
      <div
        className={cx("mark", {
          checked,
          disabled,
          error
        })}
      >
        <CheckIcon className={styles.tick} />
      </div>
    </div>
  )
}

export { Checkbox, Checkbox as default }
