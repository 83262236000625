import { computed, makeObservable, observable, action } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import Colour from "../../models/colour"
import { PackhelpObject } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { CanvasObjectControllable } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import {
  colorsListDemo,
  colorsListDemoWithBlankColor,
} from "../../libs/colors/hex-colors.list"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { isMultiColor } from "../../libs/products-render-config/helpers"

export abstract class ImageToolbarController {
  @observable public selectedColor?: Colour
  @observable public isUniScalingLocked = false

  protected readonly productDriver: ProductDriver

  constructor(services: {
    productDriver: ProductDriver
    ee: AllEditorEventsEmitter
  }) {
    this.productDriver = services.productDriver

    makeObservable(this)

    services.ee.on(
      eventTree.activeObject.selected,
      this.refreshState.bind(this)
    )
  }

  protected abstract get activeObject(): PackhelpObject | null
  protected abstract get activeObjectController(): CanvasObjectControllable | null

  @computed
  public get isToolbarAvailable(): boolean {
    return !!this.activeObject
  }

  @computed
  public get cmykColorList(): ColorListArray[] {
    if (
      isMultiColor(this.productDriver.state.productRenderPilot.getColorMode())
    ) {
      return colorsListDemoWithBlankColor
    }

    return colorsListDemo
  }

  @computed
  public get isColorPickerAvailable(): boolean {
    return !!this.activeObjectController?.isColorModificationAvailable()
  }

  public setColor(color: Colour): void {
    this.activeObjectController?.setStyles({ fill: color })
    this.refreshState()
  }

  public toggleUniScaling(): void {
    this.productDriver.activeObjectDriver.toggleUniScaling()
    this.refreshState()
  }

  @computed
  public get isUnitScalingAvailable(): boolean {
    return !this.activeObject?.assetObjectMeta?.keepRatio
  }

  @action
  protected refreshState(): void {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.selectedColor = this.activeObjectController.getFill()
    this.isUniScalingLocked = !!this.activeObject.lockUniScaling
  }
}
