import fabric from "editor/src/libs/vendors/Fabric"
import { Colour } from "../../../../models/colour"
import { PackhelpImage } from "../object-extensions/packhelp-objects"
import { IBaseFilter } from "fabric/fabric-impl"
import { MonochromeColor } from "../../../../libs/products-render-config/types"
import _sortBy from "lodash/sortBy"

fabric.textureSize = 5000

interface BlendFilterConfig {
  mode: string
  alpha: number
}

class FiltersModule {
  public static setBlendImageFilter(
    image: PackhelpImage,
    blendImage: fabric.Object,
    { mode = "multiply", alpha = 0.5 }: Partial<BlendFilterConfig> = {}
  ): PackhelpImage {
    return this.addFilter(
      image,
      this.buildFilter("BlendImage", {
        image: blendImage,
        mode,
        alpha,
      })
    )
  }

  public static setBlendColorFilter(
    image: PackhelpImage,
    blendColor: Colour,
    { mode = "multiply", alpha = 1 }: Partial<BlendFilterConfig> = {}
  ): PackhelpImage {
    const filter = image.filters.find(
      (filter) => filter.mode === mode && filter.color
    )
    const options = {
      color: blendColor.getHex(),
      alpha,
    }

    if (filter) {
      return this.updateFilter(image, filter, options)
    }

    return this.addFilter(
      image,
      this.buildFilter("BlendColor", {
        ...options,
        mode,
      })
    )
  }

  public static setTintFilter(
    image: PackhelpImage,
    color: Colour
  ): PackhelpImage {
    const filter = image.filters.find((filter) => filter.mode === "tint")
    const options = {
      color: color.getHex(),
      alpha: 1,
    }

    if (filter) {
      return this.updateFilter(image, filter, options)
    }

    return this.addFilter(
      image,
      this.buildFilter("BlendColor", {
        ...options,
        mode: "tint",
      })
    )
  }

  public static removeTintFilter(image: PackhelpImage): PackhelpImage {
    return this.removeFilter(image, "BlendColor", "tint")
  }

  public static setRemoveColorFilter(
    image: PackhelpImage,
    distance: number,
    color: Colour
  ): PackhelpImage {
    return this.addFilter(
      image,
      this.buildFilter("RemoveColor", {
        distance: distance,
        color: color.getHex(),
      })
    )
  }

  public static setInvertFilter(image: PackhelpImage): PackhelpImage {
    return this.addFilter(image, this.buildFilter("Invert", {}))
  }

  public static changeAssetThreshold(
    image: PackhelpImage,
    threshold: number
  ): PackhelpImage {
    const filter = image.filters.find((filter) => filter.type === "RemoveColor")
    const options = { distance: threshold }

    if (filter) {
      return this.updateFilter(image, filter, options)
    }

    return this.setRemoveColorFilter(
      image,
      threshold,
      new Colour(MonochromeColor.BLACK)
    )
  }

  public static toggleMonochrome(image: PackhelpImage): PackhelpImage {
    if (!image.isImageInverted()) {
      return this.addFilter(image, this.buildFilter("Invert", {}))
    }

    return this.removeFilter(image, "Invert")
  }

  private static addFilter(
    image: PackhelpImage,
    filter: IBaseFilter
  ): PackhelpImage {
    image.filters.push(filter)

    image.filters = _sortBy(image.filters, ({ type }) =>
      ["Invert", "RemoveColor", "BlendColor", "BlendImage"].indexOf(type)
    )

    image.applyFilters()

    return image
  }

  private static updateFilter(
    image: PackhelpImage,
    filter: IBaseFilter,
    options: Record<string, any>
  ): PackhelpImage {
    filter.setOptions(options)
    image.applyFilters()

    return image
  }

  private static removeFilter(
    image: PackhelpImage,
    type: string,
    mode?: string
  ): PackhelpImage {
    image.filters.splice(
      image.filters.findIndex(
        (filter) => filter.type === type && (!mode || filter.mode === mode)
      ),
      1
    )

    image.applyFilters()

    return image
  }

  private static buildFilter(filterName, params): IBaseFilter {
    return new fabric.Image.filters[filterName](params)
  }
}

export default FiltersModule
