import React, { useState } from "react"
import Affix from "./Affix"
import cxBinder from "classnames/bind"
import styles from "./TextField.module.scss"
import { scrollToElement } from "./helpers"
import { useMatchQuery } from "../../common/hooks"
import {
  createOnKeyDownCallback,
  createOnKeyUpCallback
} from "../BaseInput/BaseInput"

const cx = cxBinder.bind(styles)

export interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
  autofocus?: boolean
  disabled?: boolean
  error?: boolean
  medium?: boolean
  min?: number | string
  name?: string
  suffix?: React.Component | string | React.ReactNode
  step?: number | string
  type?: string
  value?: string
  prefix?: string
  onAffixClick?: () => any
  e2eTarget?: string
  e2eTargetName?: string
  preventAffixStyling?: boolean
  onCustomBlur?: (event: React.FocusEvent<HTMLInputElement>) => any
  withPulsatingGlow?: boolean
  placeholder?: string
}

export const inputRef = React.createRef<HTMLInputElement>()

const TextField = React.forwardRef(
  (props: TextFieldProps, ref?: React.Ref<HTMLInputElement>): any => {
    const {
      disabled,
      prefix,
      suffix,
      medium,
      onAffixClick,
      onFocus,
      onBlur,
      onCustomBlur,
      error,
      e2eTarget = "input",
      e2eTargetName,
      preventAffixStyling,
      withPulsatingGlow = false,
      placeholder = "",
      autofocus,
      ...other
    } = props

    const [focused, setFocus] = useState<boolean>(Boolean(autofocus))
    const [isTyping, setIsTyping] = useState<boolean>(false)
    const isMobile = useMatchQuery()

    const customFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true)

      isMobile && scrollToElement(event.target)

      onFocus && onFocus(event)
    }

    const customBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(false)
      onBlur && onBlur(event)
      onCustomBlur && onCustomBlur(event)
    }

    const affix = suffix || prefix

    const shouldDisplayPulsatingGlow =
      withPulsatingGlow && !error && !disabled && !focused

    return (
      <div
        className={cx("textfield", {
          error,
          disabled,
          focused,
          "textfield--medium": medium,
          "textfield--is-typing": isTyping,
          "textfield--with-pulsating-glow": shouldDisplayPulsatingGlow
        })}
      >
        {/* @ts-ignore */}
        <input
          ref={ref || inputRef}
          className={cx("input", { error, medium, handicapped: true })}
          onFocus={customFocus}
          onBlur={customBlur}
          disabled={disabled}
          onKeyDown={createOnKeyDownCallback(setIsTyping)}
          onKeyUp={createOnKeyUpCallback(setIsTyping)}
          e2e-target={e2eTarget}
          e2e-target-name={e2eTargetName}
          placeholder={placeholder}
          autoFocus={autofocus}
          {...other}
        />
        {affix && (
          <Affix
            suffix={!!suffix}
            prefix={!!prefix}
            preventStyling={preventAffixStyling}
            onClick={onAffixClick}
          >
            {affix}
          </Affix>
        )}
        <span className={cx("input-mock")} />
      </div>
    )
  }
)

export { TextField, TextField as default }
