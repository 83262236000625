import React from "react"
import { observer } from "mobx-react-lite"
import plusIconUrl from "../../../assets/_icons/plus-bg.svg"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import styles from "./AddLogoButton.module.scss"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"

const i18n = I18N.editorTools.logoPlaceholder

export const AddLogoButton = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.logo])

  if (!containerSet) {
    return null
  }

  const { logo } = containerSet

  const { logoUiController } = logo
  const { isAddLogoButtonVisible } = logoUiController

  if (!isAddLogoButtonVisible) {
    return null
  }

  const onClick = () => {
    logoUiController.showLogoTab()
  }

  return (
    <button
      className={styles.wrapper}
      onClick={onClick}
      analytics-section="main-canvas-area"
      analytics-type="button"
      analytics-name="add-logo"
      e2e-target="button"
      e2e-target-name="add-logo"
    >
      <div className={styles.icon}>
        <SvgIcon url={plusIconUrl} size="xl" />
      </div>
      <div className={styles.caption}>{t(i18n.addLogoAndEdit)}</div>
    </button>
  )
})
