import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { MaskPosition } from "../types"
import { CANVAS_DIM } from "../../../../../../types"

export class GlobalLayerClippingMaskCalculator {
  public calcPosition(mask: PackhelpObject): MaskPosition {
    return {
      left: mask.left! - CANVAS_DIM / 2,
      top: mask.top! - CANVAS_DIM / 2,
    }
  }
}
