import React from "react"
import { observer } from "mobx-react-lite"
import boxUrl from "../../../assets/_images/box.png"
import dielineUrl from "../../../assets/_images/dieline.svg"
import styles from "./BackToPreviousViewButton.module.scss"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import arrowIconUrl from "../../../assets/_icons/type-arrow-left.svg"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"
import { ViewType } from "../../../../libs/products-render-config/types"

const i18n = I18N.component.cameraControlsToolbar

const analyticsNames = {
  [ViewType.MODEL]: "back-to-3d",
  [ViewType.DIELINE]: "back-to-2d",
  [ViewType.SPACE]: "back-to-2d",
}

const imageUrls = {
  [ViewType.MODEL]: boxUrl,
  [ViewType.DIELINE]: dielineUrl,
  [ViewType.SPACE]: dielineUrl,
}

export const BackToPreviousViewButton = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { productDriver } = containerSet.designAndProductDriver
  const { previousViewType, activeViewType, activeContext } =
    productDriver.state

  if (previousViewType === "space" || activeViewType !== "space") {
    return null
  }

  const onClick = () => {
    productDriver.setView(previousViewType, activeContext, null)
  }

  return (
    <div className={styles.wrapper}>
      <img src={imageUrls[previousViewType]} alt="" />
      <div className={styles.button}>
        <Button
          onClick={onClick}
          size={ButtonSizes.small}
          icon={<SvgIcon url={arrowIconUrl} size="xs" />}
          isIconOnLeft={true}
          fluid
          analyticsSection="main-canvas-area"
          analyticsType="button"
          analyticsName={analyticsNames[previousViewType]}
          e2eTarget="button"
          e2eTargetName="back-to-previous-view"
        >
          {t(i18n.backTo[previousViewType])}
        </Button>
      </div>
    </div>
  )
})
