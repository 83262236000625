import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { LayerController } from "./object-controllers/layer.controller"
import {
  PackhelpCanvas,
  PackhelpEditableGroup,
  PackhelpEditableObject,
} from "../../../object-extensions/packhelp-objects"
import { DuplicationController } from "./object-controllers/duplication.controller"
import { getGroup } from "../editable-group-controller/helpers"
import { AlignmentController } from "./object-controllers/alignment.controller"
import { CanvasObjectControllable } from "./canvas-object-controllable.interface"
import { RemovalController } from "./object-controllers/removal.controller"
import {
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../../events/partials/domain.events"
import { Colour } from "../../../../../../models/colour"
import { AssetColorController } from "../asset-color.controller"
import { MovementController } from "./object-controllers/movement.controller"
import { DuplicationOptions, MovementDirection } from "./types"
import { SnappingController } from "./object-controllers/snapping.controller"
import { ControlsHelper } from "../helpers/controls.helper"
import { SpaceClippingController } from "./object-controllers/space-clipping.controller"
import { isAssetGroup } from "../../../../../../types/asset.types"
import { isMonochrome } from "../../../../../../libs/products-render-config/helpers"

export interface FabricObjectStyles {
  lockUniScaling?: boolean
}

export interface ObjectStyles extends FabricObjectStyles {
  fill?: Colour
}

export class ObjectController implements CanvasObjectControllable {
  protected readonly layerController: LayerController
  protected readonly duplicationController: DuplicationController
  protected readonly alignmentController: AlignmentController
  protected readonly removalController: RemovalController
  protected readonly assetColorController: AssetColorController
  protected readonly movementController: MovementController
  protected readonly spaceClippingController: SpaceClippingController

  constructor(
    protected readonly canvasObject: PackhelpEditableObject,
    protected readonly vdEditor: VirtualDielineEditor
  ) {
    this.layerController = new LayerController(this.canvasObject)
    this.duplicationController = new DuplicationController(
      this.canvasObject,
      this.vdEditor
    )
    this.alignmentController = new AlignmentController(
      this.canvasObject,
      this.vdEditor,
      new SnappingController(this.canvasObject)
    )
    this.removalController = new RemovalController(this.canvasObject)
    this.assetColorController = new AssetColorController(this.vdEditor)
    this.movementController = new MovementController(this.canvasObject)
    this.spaceClippingController = new SpaceClippingController(
      this.canvasObject,
      this.vdEditor
    )
  }

  public async toggleSpaceClipping(): Promise<void> {
    if (!this.isSpaceClippingTogglingAvailable()) {
      return
    }

    await this.spaceClippingController.toggle()
  }

  public moveLayerUp() {
    if (!this.isMovingLayerUpDownAvailable()) {
      return
    }

    this.layerController.moveLayerUp()
  }

  public moveLayerDown() {
    if (!this.isMovingLayerUpDownAvailable()) {
      return
    }

    this.layerController.moveLayerDown()
  }

  public move(direction: MovementDirection, step = 1) {
    this.movementController.move(direction, step)
  }

  public async duplicate(
    options: DuplicationOptions = {
      withRender: true,
    }
  ): Promise<PackhelpEditableObject> {
    if (!this.isDuplicatingAvailable()) {
      throw new Error("Object can't be duplicated")
    }

    return this.duplicationController.duplicate(options)
  }

  public alignHorizontal() {
    this.alignmentController.alignHorizontal()
  }

  public alignVertical() {
    this.alignmentController.alignVertical()
  }

  public remove() {
    this.removalController.remove()
  }

  public getObject(): PackhelpEditableObject {
    return this.canvasObject
  }

  public getGroup(): PackhelpEditableGroup | undefined {
    return getGroup(this.canvasObject)
  }

  public hasGroup(): boolean {
    return !!this.canvasObject.groupId
  }

  public hasMask(): boolean {
    return !!this.canvasObject.maskController
  }

  public setStyles(styles: ObjectStyles) {
    if (styles.fill) {
      this.setFill(styles.fill)
      delete styles.fill
    }

    this.canvasObject.set(styles as FabricObjectStyles)
    ControlsHelper.setControls(this.canvasObject)
    this.canvasObject.fire(CanvasObjectEvent.changed)
    this.canvas.fire(CanvasEvent.objectRestyled, {
      target: this.canvasObject,
    })

    this.rerender()
  }

  public getFill(): Colour {
    return new Colour(
      this.canvasObject.fill as string,
      this.canvasObject.fillPantoneName
    )
  }

  public shouldKeepRatio(): boolean {
    return !!this.canvasObject.keepRatio
  }

  public isColorModificationAvailable(): boolean {
    const mode = this.vdEditor.productRenderPilot.getColorMode()

    if (isMonochrome(mode)) {
      return false
    }

    return true
  }

  public isMovingLayerUpDownAvailable(): boolean {
    return true
  }

  public isDuplicatingAvailable(): boolean {
    return true
  }

  public isGroupingAvailable(): boolean {
    return false
  }

  public isUngroupingAvailable(): boolean {
    return isAssetGroup(this.canvasObject) || this.hasGroup()
  }

  public isGroupable(): boolean {
    return true
  }

  public isSpaceClippingTogglingAvailable(): boolean {
    return this.vdEditor.isSpaceClippingTogglingAvailable()
  }

  protected setFill(color: Colour) {
    this.assetColorController.recolorObject(color, this.canvasObject)

    this.canvas.fire(CanvasEvent.objectRecolored, {
      target: this.canvasObject,
    })
  }

  protected get canvas(): PackhelpCanvas {
    return this.vdEditor.fabricCanvas
  }

  protected rerender() {
    this.canvas.renderAll()
  }
}
