import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Button.module.scss"
import { ButtonInner } from "./ButtonInner"
import {
  ButtonBaseProps,
  ButtonLinkProps,
  ButtonModes,
  ButtonPlainProps,
  ButtonSizes
} from "./types"

const cx = cxBinder.bind(styles)

const className = (modifiers: Partial<ButtonBaseProps>) => {
  const {
    size = ButtonSizes.medium,
    mode,
    icon,
    fluid,
    fluidOnMobile,
    mobileSize,
    isLoading
  } = modifiers
  return cx("base", {
    large: size === ButtonSizes.large,
    medium: size === ButtonSizes.medium,
    small: size === ButtonSizes.small,
    secondary: mode === ButtonModes.secondary,
    destructive: mode === ButtonModes.destructive,
    "secondary-white": mode === ButtonModes.secondaryWhite,
    "secondary-mariner": mode === ButtonModes.secondaryMariner,
    "secondary-white-three": mode === ButtonModes.secondaryWhiteThree,
    "supporting-transparent": mode === ButtonModes.supportingTransparent,
    "semi-transparent": mode === ButtonModes.semiTransparent,
    "mobile-large": mobileSize === ButtonSizes.large,
    "mobile-medium": mobileSize === ButtonSizes.medium,
    "mobile-small": mobileSize === ButtonSizes.small,
    "with-icon": icon,
    fluid,
    "fluid-mobile": fluidOnMobile,
    loading: isLoading
  })
}

const Button = (props: ButtonPlainProps) => {
  const {
    analyticsId,
    analyticsName,
    analyticsSection,
    analyticsType,
    borderRadius,
    children,
    color,
    e2eTarget = "button",
    e2eTargetName,
    fluid,
    fluidOnMobile,
    icon,
    isIconOnLeft,
    isLoading,
    mode,
    size,
    ...other
  } = props

  return (
    // @ts-ignore
    <button
      className={className({
        size,
        mode,
        icon,
        fluid,
        fluidOnMobile,
        isLoading
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      style={{ borderRadius }}
      {...other}
    >
      <ButtonInner
        icon={icon}
        isLoading={isLoading}
        isIconOnLeft={isIconOnLeft}
      >
        {children}
      </ButtonInner>
    </button>
  )
}

const ButtonAsLink = (props: ButtonLinkProps) => {
  const {
    borderRadius,
    children,
    e2eTarget = "button",
    e2eTargetName,
    fluid,
    fluidOnMobile,
    icon,
    isIconOnLeft,
    isLoading,
    mobileSize,
    mode,
    size,
    ...other
  } = props

  return (
    // @ts-ignore
    <a
      className={className({
        size,
        mode,
        icon,
        fluid,
        fluidOnMobile,
        mobileSize,
        isLoading
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      style={{ borderRadius }}
      {...other}
    >
      <ButtonInner
        icon={icon}
        isLoading={isLoading}
        isIconOnLeft={isIconOnLeft}
      >
        {children}
      </ButtonInner>
    </a>
  )
}

export { Button, ButtonAsLink, Button as default }
