import { getRoutingConfiguration } from "../services/_routing"
import type { CartStore } from "../stores/cart.store"
import type { PlantingTreesStore } from "../stores/planting-trees.store"
import type { CurrencyStore } from "../stores/currency.store"
import type { ProductPricingStore } from "../stores/product-pricing.store"
import type { TaxStore } from "../stores/tax.store"
import type { ApiSessionContainer } from "./api-session.container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { UtilEnvContainer } from "./util.container"
import { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap.container"
import { AuthMaybeContainer } from "./auth-maybe.container"
import { CartDto } from "../libs/api/ecommerce-api/types"
import { EcommerceStore } from "../stores/ecommerce.store"
import { EcommerceController } from "../controllers/ecommerce.controller"
import { provideShippingDestinationStore } from "shared-libs/src/js/shared-views/forms/shipping-destination-form/store/shipping-destination.container"
import { ShippingDestinationStore } from "shared-libs/src/js/shared-views/forms/shipping-destination-form/store/shipping-destination.store"
import type { DbyModeContainer } from "./dby-mode.container"
import { QuantityPickerUiController } from "../controllers/quantity-picker-ui.controller"
import { DynamicVariantController } from "../controllers/dynamic-variant.controller"
import { EcommerceApiEnvMode } from "../app-config/types"

async function importDeps() {
  const { CurrencyStore } = await import("../stores/currency.store")
  const { TaxStore } = await import("../stores/tax.store")
  const { PricingManager } = await import(
    "../services/managers/pricing.manager"
  )
  const { ProductPricingStore } = await import(
    "../stores/product-pricing.store"
  )
  const { PlantingTreesStore } = await import("../stores/planting-trees.store")
  const { CartStore } = await import("../stores/cart.store")

  return {
    CurrencyStore,
    TaxStore,
    PricingManager,
    ProductPricingStore,
    PlantingTreesStore,
    CartStore,
  }
}

// WL doesnt support shipping destination. In future we can implement this service
const getShippingDestinationApiService = (mode: EcommerceApiEnvMode) => {
  if (["injected", "api-customers"].includes(mode)) {
    return {
      user: {
        getUserMe: async () => {
          return { shipping_destination: undefined }
        },
        saveShippingDestination: () => Promise.resolve(null),
      },
    }
  }

  return undefined
}

export async function provideEcommerceMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  authContainer: AuthMaybeContainer,
  dbyModeContainer: DbyModeContainer
): Promise<EcommerceMaybeContainer> {
  const { uri, appConfig, ee } = utilEnvContainer
  const { apiService, designApi, ecommerceApi } = apiSessionContainer
  const { productDesignStore, productDriver, changeProductController } =
    designAndProduct
  const { productStore } = rootBootstrapper
  const { authController } = authContainer
  const { dbyModeStore } = dbyModeContainer

  if (
    !appConfig.api.ecommerce.features.ecommerce.basic ||
    uri.isDesignerMode() ||
    uri.isDtpPreviewMode()
  ) {
    return {
      available: false,
      currencyStore: undefined,
      taxStore: undefined,
      plantingTreesStore: undefined,
      productPricingStore: undefined,
      cartStore: undefined,
      cart: undefined,
      ecommerceStore: undefined,
      ecommerceController: undefined,
      shippingDestinationStore: undefined,
      quantityPickerUiController: undefined,
    }
  }

  const {
    CurrencyStore,
    TaxStore,
    PricingManager,
    ProductPricingStore,
    PlantingTreesStore,
    CartStore,
  } = await importDeps()

  const isInstantPurchase = (() => {
    const isDtpPreviewMode = uri.isDtpPreviewMode()

    if (isDtpPreviewMode) {
      return false
    }

    const isDbyMode = productDriver.state.productRenderPilot.isDbyMode()

    if (!isDbyMode) {
      return false
    }

    return uri.hasDesignLaterInUrl()
  })()

  const cart = await ecommerceApi.cart.get()
  const { designStatus, isOrdered } = rootBootstrapper.designMeta
  const isAfterPurchaseEdit = designStatus === "draft" && isOrdered

  productDriver.state.productRenderPilot.setAfterPurchaseEdit(
    isAfterPurchaseEdit
  )

  // DOMAIN: TAX, CURRENCY, TREES PRICING
  const currencyStore = new CurrencyStore(appConfig.locale)
  const taxStore = new TaxStore(
    getRoutingConfiguration().taxConfiguration,
    appConfig.locale
  )

  const plantingTreesStore = new PlantingTreesStore(currencyStore, taxStore)
  const pricingManager = new PricingManager(
    ecommerceApi.pricing,
    taxStore,
    appConfig.locale.productRegion
  )

  const productPricingStore = new ProductPricingStore(
    {
      pricingManager,
      authEventEmitter: authController?.authEventEmitter,
      ecommerceConfig: appConfig.api.ecommerce,
      ee,
    },
    {
      productStore: productDriver.productStore,
      productDesignStore,
      plantingTreesStore,
      taxStore,
    }
  )

  const shippingDestinationStore = await provideShippingDestinationStore(
    getShippingDestinationApiService(appConfig.api.ecommerce.mode)
  )

  const ecommerceStore = new EcommerceStore(
    { appConfig, productDriver },
    {
      productStore,
      productPricingStore,
      productDesignStore,
      currencyStore,
      dbyModeStore,
    },
    isAfterPurchaseEdit
  )

  const cartStore = new CartStore(
    ecommerceApi.cart,
    apiService,
    designApi,
    plantingTreesStore,
    ecommerceStore
  )

  cartStore.setIsInstantPurchase(isInstantPurchase)

  const dynamicVariantController = new DynamicVariantController(
    {
      variantApi: ecommerceApi.variant,
      ee,
      localeConfig: appConfig.locale,
      isDynamicPricesSupported:
        appConfig.api.ecommerce.features.ecommerce.dynamicPrices,
    },
    {
      ecommerceStore,
      productPricingStore,
      productStore,
      shippingDestinationStore,
    }
  )

  const ecommerceController = new EcommerceController(
    {
      ee,
      uri,
      dynamicVariantController,
    },
    {
      ecommerceStore,
      productPricingStore,
      productStore,
      shippingDestinationStore,
      cartStore,
      productDesignStore,
    },
    isInstantPurchase
  )

  changeProductController.setEcommerceDependencies(
    ecommerceController,
    ecommerceStore
  )

  productPricingStore.init(isInstantPurchase)
  ecommerceController.init()

  const quantityPickerUiController = new QuantityPickerUiController(
    {
      ecommerceController,
      ee,
      ecommerceConfig: appConfig.api.ecommerce,
    },
    {
      ecommerceStore,
      productStore,
      productDesignStore,
      productPricingStore,
      taxStore,
      currencyStore,
    }
  )

  return {
    available: true,
    currencyStore,
    taxStore,
    plantingTreesStore,
    productPricingStore,
    cartStore,
    cart,
    ecommerceStore,
    ecommerceController,
    shippingDestinationStore,
    quantityPickerUiController,
  }
}

export type EcommerceMaybeContainer =
  | {
      available: true
      currencyStore: CurrencyStore
      taxStore: TaxStore
      plantingTreesStore: PlantingTreesStore
      productPricingStore: ProductPricingStore
      cartStore: CartStore
      cart: CartDto
      ecommerceStore: EcommerceStore
      ecommerceController: EcommerceController
      shippingDestinationStore: ShippingDestinationStore
      quantityPickerUiController: QuantityPickerUiController
    }
  | {
      available: false
      currencyStore: undefined
      taxStore: undefined
      plantingTreesStore: undefined
      productPricingStore: undefined
      cartStore: undefined
      cart: undefined
      ecommerceStore: undefined
      ecommerceController: undefined
      shippingDestinationStore: undefined
      quantityPickerUiController: undefined
    }
