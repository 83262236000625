import React from "react"
import { Colour } from "../../../../models/colour"
import { PantoneColorPreset } from "../../../../libs/products-render-config/types"
import { PantoneColorsDatabase } from "../../../../libs/colors/pantone-colors.database"
import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import PantonePicker from "../../organisms/PantonePicker/PantonePicker"
import { AnalyticsSection } from "../../types"

export interface PantoneConfiguratorProps {
  selectedColor: Colour | undefined
  onColorSelect: (color: Colour) => void
  pantoneColorPreset?: PantoneColorPreset
  analyticsSection?: AnalyticsSection
}

export function PantoneConfigurator({
  selectedColor,
  onColorSelect,
  pantoneColorPreset,
  analyticsSection,
}: PantoneConfiguratorProps) {
  const pantoneDb = new PantoneColorsDatabase()

  const getSelectedPantoneColor = (): PantonePickerColorObject | undefined => {
    if (!selectedColor) {
      return
    }

    return pantoneDb.getByColour(selectedColor)
  }

  return (
    <PantonePicker
      selectedColor={getSelectedPantoneColor()}
      onColorSelect={onColorSelect}
      pantoneColorPreset={pantoneColorPreset}
      analyticsSection={analyticsSection}
    />
  )
}
