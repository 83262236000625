import { computed, makeObservable } from "mobx"
import type { SubTab } from "../../types/ui.types"
import type ProductDriver from "../../drivers/product.driver"
import { I18N } from "../../ui/i18n"
import type { AllEditorEventsEmitter } from "../../events/editor.events"
import { AbstractTabController } from "./abstract-tab.controller"
import type { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import type {
  EditContext,
  PantoneColorPreset,
  UIConfig,
} from "../../libs/products-render-config/types"
import { HexColorPreset } from "../../libs/products-render-config/types"
import type Colour from "../../models/colour"
import type BackgroundsDriver from "../../drivers/backgrounds.driver"
import type { BackgroundImageUiController } from "../background-image-ui.controller"
import type { ImageAssetItemStore } from "../../stores/image-asset-item.store"
import type { BackgroundImageStore } from "../../stores/background-image.store"
import type { AssetsStore } from "../../stores/assets.store"
import { HexColorsDatabase } from "../../libs/colors/hex-colors.database"
import type { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { colorsListDemoWithBlankColor } from "../../libs/colors/hex-colors.list"

const i18n = I18N.component.cameraControlsToolbar

export class BackgroundTabController extends AbstractTabController {
  private readonly productDriver: ProductDriver
  private readonly backgroundImageUiController: BackgroundImageUiController
  private readonly backgroundImageStore: BackgroundImageStore
  private readonly assetStore: AssetsStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
      backgroundImageUiController: BackgroundImageUiController
    },
    stores: {
      backgroundImageStore: BackgroundImageStore
      assetStore: AssetsStore
    }
  ) {
    super(services)

    this.productDriver = services.productDriver
    this.backgroundImageUiController = services.backgroundImageUiController
    this.backgroundImageStore = stores.backgroundImageStore
    this.assetStore = stores.assetStore

    makeObservable(this)
  }

  @computed
  public get pantoneColorPreset(): PantoneColorPreset | undefined {
    return this.productRenderPilot.getBackgroundPantoneColorPreset()
  }

  @computed
  public get hexColorsList(): ColorListArray[] {
    const hexDb = new HexColorsDatabase()
    const preset = this.productRenderPilot.getBackgroundHexColorPreset()

    if (preset === HexColorPreset.ALL) {
      return colorsListDemoWithBlankColor
    }

    return hexDb.getByPreset(preset)
  }

  public get subTabs(): SubTab[] {
    return this.productRenderPilot
      .getAvailableEditContexts()
      .map((editContext) => ({
        id: editContext,
        titleIntl: i18n.context[editContext],
        analyticsName: editContext,
      }))
  }

  public async setBackgroundColor(color?: Colour): Promise<void> {
    if (!this.editContext) {
      return
    }

    if (color) {
      return this.backgroundsDriver.paintProductEditContext(
        color,
        this.editContext
      )
    }

    return this.backgroundsDriver.paintProductEditContext(
      null,
      this.editContext
    )
  }

  public async uploadAndSetBackgroundColor(file: File): Promise<void> {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.uploadAndApplyBackgroundImage(
      this.editContext,
      file
    )
  }

  @computed
  public get isBackgroundImageUploading(): boolean {
    return this.backgroundImageStore.isUploading
  }

  @computed
  public get selectedBackgroundColor(): Colour | undefined {
    if (!this.editContext) {
      return
    }

    return this.backgroundsDriver.backgroundColor[this.editContext]
  }

  public async setBackgroundImage(image: ImageAssetItemStore): Promise<void> {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.applyBackgroundImage(
      this.editContext,
      image
    )
  }

  public async clearBackgroundImage(): Promise<void> {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.removeBackgroundImage(
      this.editContext
    )
  }

  public async removeImage(image: ImageAssetItemStore): Promise<void> {
    await this.backgroundImageUiController.removeBackgroundImagesByImageAssetStore(
      image
    )

    this.assetStore.removeAsset(image)
  }

  @computed
  public get selectedBackgroundImage(): ImageAssetItemStore | undefined {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.getBackgroundImageAssetStore(
      this.editContext
    )
  }

  @computed
  public get isBackgroundImageAvailable(): boolean {
    return this.isPrintActive && this.features.backgroundImage
  }

  @computed
  public get isBackgroundColorAvailable(): boolean {
    return this.isPrintActive && this.features.backgroundColor
  }

  @computed
  public get isPrintAdditionallyPaid(): boolean {
    if (!this.activeSubTab) {
      return false
    }

    return this.productRenderPilot.isPrintAdditionallyPaidFor(
      this.activeSubTab.id as EditContext
    )
  }

  @computed
  public get isPrintActive(): boolean {
    if (!this.activeSubTab) {
      return false
    }

    return this.productRenderPilot.isPrintActiveFor(
      this.activeSubTab.id as EditContext
    )
  }

  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }

  private get backgroundsDriver(): BackgroundsDriver {
    return this.productDriver.backgroundsDriver
  }

  private get features(): UIConfig["features"] {
    return this.productRenderPilot.uiConfig.features
  }

  private get editContext(): EditContext | undefined {
    if (!this.activeSubTab) {
      return
    }

    return this.activeSubTab.id as EditContext
  }
}
