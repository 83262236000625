import RegionConfig, {
  productRegions,
} from "../../../configs/region-config/interfaces/js/region-config"
import { Country } from "../../../configs/region-config/interfaces/js/region-config.types"
import { getUserCountry } from "../helpers/get-user-country"
import { includes } from "../helpers/includes"

export const getDefaultShippingCountry = (): Country | undefined => {
  const userCountry = getUserCountry()

  if (!userCountry) {
    return
  }

  const userCountryUpcased = userCountry.toUpperCase()

  const allowedCountries: string[] = RegionConfig.getKeyForCurrentRegion(
    "allowedShippingCountries"
  )

  if (!allowedCountries.includes(userCountryUpcased)) {
    return
  }

  return userCountryUpcased as Country
}

export const isProductRegion = (shippingCountry: string): boolean => {
  return includes(productRegions, shippingCountry.toLowerCase())
}
