import React, { useRef } from "react"
import cxBinder from "classnames/bind"
import styles from "./BaseTooltip.module.scss"
import { useOutsideClick } from "../../common/hooks/use-outside-click"
const cx = cxBinder.bind(styles)

type TooltipSize = "small" | "regular"

export enum TooltipDirections {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right"
}

export enum ArrowPlacements {
  top = "top",
  bottom = "bottom",
  center = "center",
  left = "left",
  right = "right"
}

interface ArrowProps extends React.HTMLProps<HTMLSpanElement> {
  type: TooltipDirections
  placement?: ArrowPlacements
}

export interface TooltipProps extends React.HTMLProps<HTMLDivElement> {
  /** top, bottom, left or right */
  direction: TooltipDirections
  /** for tooltip at left or right(at horizontal sides): top, center or bottom
   *
   * for tooltip at top or boottom(at vertical sides): left, center or right
   *
   * defaults to "center"
   */
  arrowPlacement?: ArrowPlacements
  /** for the BaseTooltip it defaults to "true" */
  open?: boolean
  /** This parameter is passed when the tooltip is being used within a wrapper  */
  wrapped?: boolean
  /** "small" or "regular"; defaults to "regular" */
  type?: TooltipSize
  handleClickOutside?: () => void
  fullWidth?: boolean
  children?: React.ReactNode
}

const Arrow = (props: ArrowProps) => {
  const { type, placement = ArrowPlacements.center } = props

  /**
   * we should test whether arrow placement is correct for a given tooltip direction
   * for tooltip at top or boottom(at vertical sides): left, center or right are correct
   * for tooltip at left or right(at horizontal sides): top, center or bottom are correct
   * (probably would require creating separate types for HorizontalArrowPlacementss and VerticalArrowPlacementss)
   * @todo we should throw a runtime error
   * (probably here is the best location for that)
   * if the types arent correct
   */

  return (
    <span
      className={cx(["arrow", `direction-${type}`, `align-${placement}`])}
    />
  )
}

const BaseTooltip = (props: TooltipProps) => {
  const {
    children,
    direction,
    arrowPlacement,
    open = true,
    wrapped = false,
    fullWidth = false,
    type = "regular",
    handleClickOutside,
    ...other
  } = props

  const wrappedClassnames = {
    wrapped,
    [`direction-${direction}`]: wrapped,
    [`placement-${arrowPlacement}`]: wrapped
  }

  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, handleClickOutside || (() => {}))

  return (
    // @ts-ignore
    <div
      className={cx(
        "default",
        {
          open,
          small: type === "small",
          full: fullWidth
        },
        wrapped && wrappedClassnames
      )}
      {...other}
      ref={ref}
    >
      <div className={cx("content")}>{children}</div>
      <Arrow type={direction} placement={arrowPlacement} />
    </div>
  )
}

export { BaseTooltip, BaseTooltip as default }
