import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Stepper.module.scss"
import { CheckIcon } from "../../assets/_svgr/icons/Check"

const cx = cxBinder.bind(styles)

export enum StepsEnumType {
  Completed = "completed",
  Current = "current"
}

export enum StepColor {
  LightishBlue = "lightish-blue"
}

interface StepperProps {
  status?: StepsEnumType
  color?: StepColor
}

const Stepper = (props: StepperProps) => {
  const { status, color } = props
  return (
    <div
      className={cx("base", {
        ["base--lightish-blue"]: color === StepColor.LightishBlue,
        completed: status === StepsEnumType.Completed,
        current: status === StepsEnumType.Current
      })}
      e2e-target="stepper"
      e2e-target-name={status}
    >
      {status === StepsEnumType.Completed && (
        <CheckIcon className={styles.icon} />
      )}
    </div>
  )
}

export { Stepper, Stepper as default }
