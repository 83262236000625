import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { EditableObjectTypes } from "../../../../../../types/asset.types"
import { MaskConfig, MaskType } from "./types"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { NotValidOperationError } from "./errors"
import { CANVAS_DIM } from "../../../../../types"
import { RotationHelper } from "../helpers/rotation.helper"
import fabric from "../../../../../../libs/vendors/Fabric"

export class MaskBuilder {
  constructor(
    private readonly maskParent: PackhelpEditableObject,
    private readonly vdEditor: VirtualDielineEditor
  ) {}

  public async buildGlobalClippingMask(
    config: MaskConfig
  ): Promise<PackhelpMaskObject> {
    if (typeof this.maskParent.originSpaceArea === "undefined") {
      throw new NotValidOperationError(
        "Global mask works only with editable objects"
      )
    }

    const angle = this.maskParent.angle || 0

    const { x, y } = RotationHelper.rotatePoint(
      new fabric.Point(this.maskParent.left!, this.maskParent.top!),
      this.maskParent.getCenterPoint(),
      -angle
    )

    const object = await this.getMaskShapeWithParams(config.shape, {
      left:
        x -
        CANVAS_DIM / 2 -
        (config.size.width - this.maskParent.getScaledWidth()) / 2,
      top:
        y -
        CANVAS_DIM / 2 -
        (config.size.height - this.maskParent.getScaledHeight()) / 2,
      width: config.size.width,
      height: config.size.height,
      originX: "left",
      originY: "top",
      clipPath: await this.buildGlobalClippingMaskClipPath(),
    })

    object.rotate(-angle)

    return object
  }

  public async buildGlobalClippingMaskClipPath(): Promise<
    PackhelpObject | undefined
  > {
    if (this.maskParent.isSpaceClippingDisabled) {
      return
    }

    const clipPath = await this.vdEditor.dielineNavigator.cloneSpaceToClipPath(
      this.maskParent.originSpaceArea,
      false,
      false
    )

    clipPath.absolutePositioned = true

    RotationHelper.rotateObjectAroundCanvasCenter(
      clipPath,
      this.vdEditor.dielineNavigator.getCurrentRotation()
    )

    return clipPath
  }

  public async buildMask(
    config: MaskConfig,
    type: MaskType
  ): Promise<PackhelpMaskObject> {
    return this.getMaskShapeWithParams(config.shape, {
      fill: config.color.getHex(),
      fillPantoneName: config.color.getPantoneName(),
      left: 0,
      top: 0,
      width: config.size.width,
      height: config.size.height,
      originX: "left",
      originY: "top",
      assetType: EditableObjectTypes.assetObject,
      originSpaceArea: this.maskParent.originSpaceArea,
      id: uuidv4(),
      lockUniScaling: !!config.shape.keepRatio,
      lockScalingX: config.isEditingDisabled,
      lockScalingY: config.isEditingDisabled,
      hasControls: !config.isEditingDisabled,
      indexConfig: this.maskParent.indexConfig,
      maskType: type,
      ...this.UIParams,
    })
  }

  public async buildTempClippingMask(
    config: MaskConfig
  ): Promise<PackhelpMaskObject> {
    return this.getMaskShapeWithParams(config.shape, {
      left: 0,
      top: 0,
      angle: 0,
      width: config.size.width,
      height: config.size.height,
      originX: "left",
      originY: "top",
    })
  }

  private async getMaskShapeWithParams(
    shape: PackhelpMaskObject,
    params: Partial<PackhelpMaskObject>
  ): Promise<PackhelpMaskObject> {
    let scaleX = 1
    let scaleY = 1

    const shapeWidth = shape.width!
    const shapeHeight = shape.height!

    if (params.width) {
      scaleX = params.width! / shapeWidth
    }

    if (params.height) {
      scaleY = params.height! / shapeHeight
    }

    return new Promise((resolve) => {
      shape.clone((clonedShape) => {
        clonedShape.set({
          ...this.defaultParams,
          ...params,
          width: shapeWidth,
          height: shapeHeight,
          scaleX: scaleX,
          scaleY: scaleY,
          assetObjectMeta: shape.assetObjectMeta,
          borderRadius: shape.borderRadius || shape.rx || 0,
          keepRatio: shape.keepRatio,
          maskParentId: this.maskParent.id,
          isSpaceClippingDisabled: this.maskParent.isSpaceClippingDisabled,
        })

        resolve(clonedShape)
      })
    })
  }

  public get UIParams(): Partial<PackhelpMaskObject> {
    return {
      hasRotatingPoint: false,
      lockRotation: true,
      borderColor: "#3b61ff",
      stroke: "#3b61ff",
      strokeUniform: true,
      strokeDashArray: [5, 15],
      centeredScaling: true,
      centeredRotation: true,
      lockScalingFlip: true,
    }
  }

  private get defaultParams(): Partial<PackhelpMaskObject> {
    return {
      clipPath: undefined,
      strokeWidth: 0,
      centeredScaling: true,
      centeredRotation: true,
      lockScalingFlip: true,
      assetType: undefined,
      originSpaceArea: undefined,
    }
  }
}
