import { Colour } from "../../../../../../models/colour"
import {
  PackhelpCanvas,
  PackhelpImage,
  PackhelpObject,
  VirtualDielineSpace,
} from "../../../object-extensions/packhelp-objects"
import { CreateTempBackgroundService } from "../services/create-temp-background"
import { TempLayers } from "../../../types/render-engine.types"
import fabric from "../../../../../../libs/vendors/Fabric"
import { isAssetImage } from "../../../../../../types/asset.types"
import FiltersModule from "../../filters.module"

class TempBackgroundController {
  constructor(private readonly fabricCanvas: PackhelpCanvas) {}

  public async createBackground(
    space: VirtualDielineSpace,
    backgroundSpace: PackhelpObject,
    config: {
      shadow?: fabric.IShadowOptions
      texture?: PackhelpImage
    }
  ): Promise<PackhelpObject> {
    if (typeof space.fill !== "string") {
      throw new Error("Space is not compatible")
    }

    const editZoneBackgroundService = new CreateTempBackgroundService(
      backgroundSpace,
      {
        ...config,
        color: new Colour(space.fill),
      }
    )

    return editZoneBackgroundService.generate()
  }

  public removeFromCanvas() {
    const background = this.getBackground()

    if (background) {
      this.fabricCanvas.remove(background)
    }
  }

  public getBackground() {
    return this.fabricCanvas
      .getObjects()
      .find((obj) => obj.id === TempLayers.TEMP_BACKGROUND)
  }

  public refreshBackground(space: VirtualDielineSpace) {
    const background = this.getBackground()

    if (!background || !isAssetImage(background)) {
      return
    }

    FiltersModule.setTintFilter(background, new Colour(space.fill as string))

    this.fabricCanvas.requestRenderAll()
  }
}

export { TempBackgroundController }
