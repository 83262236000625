/**
 * array.includes that works with strongly typed arrays
 * https://fettblog.eu/typescript-array-includes/
 * @param coll array
 * @param el element of the array
 */

export const includes = <T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T => coll.includes(el as T)
