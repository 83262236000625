import React from "react"
import { I18N } from "../../../../../i18n"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Tile } from "../../../../atoms/Tile/Tile"
import { AvailableEditorRelatedProduct } from "@ph/product-api"
import { ConditionalWrapper } from "../../../../atoms/ConditionalWrapper/ConditionalWrapper"
import { Popover } from "../../../../molecules/Popover/Popover"
import { prepareImgixUrl } from "shared-libs/src/js/libs/helpers/prepare-imgix-url"
import { FormGroup } from "../../../../molecules/FormGroup/FormGroup"

export interface RelatedProductsSectionProps {
  products: AvailableEditorRelatedProduct[]
  onProductClick: (sku: string) => void
  currentSku?: string
  isAnyActionDisabled?: boolean
}

export function RelatedProductsSection({
  products,
  onProductClick,
  currentSku,
  isAnyActionDisabled = false,
}: RelatedProductsSectionProps) {
  const t = useTranslate()

  return (
    <FormGroup label={t(I18N.generic.variant)}>
      <TileList cols={3}>
        {products.map((product) => {
          const sku = product.defaultVariant?.sku
          const onClick = () => sku && onProductClick(sku)
          const isActive = currentSku === sku
          const isDisabled = isActive || !sku || isAnyActionDisabled

          return (
            <ConditionalWrapper
              key={product.id}
              condition={!sku}
              wrapper={(children) => (
                <Popover text={t(I18N.generic.notAvailableForSelectedSize)}>
                  {children}
                </Popover>
              )}
            >
              <Tile
                label={product.title}
                onClick={onClick}
                disabled={isDisabled}
                active={isActive}
                analyticsSection="left-tab-configuration"
                analyticsType="thumbnail"
                analyticsName="variant"
                analyticsId={product.name}
              >
                {product.imageUrl && (
                  <figure>
                    <img
                      src={prepareImgixUrl(product.imageUrl, {
                        auto: "format",
                        w: 164,
                        q: 75,
                      })}
                      alt={product.title}
                    />
                  </figure>
                )}
              </Tile>
            </ConditionalWrapper>
          )
        })}
      </TileList>
    </FormGroup>
  )
}
