import { useEffect, useState } from "react"
import { getIntlInstance } from "../others/i18n"

import type { IntlShape } from "react-intl"
import type { Lang } from "../AsyncI18nLocales"

export function useIntlImperative(languageTag?: Lang) {
  const [intl, setIntl] = useState<IntlShape | null>(null)
  useEffect(() => {
    let isSubscribed = true

    getIntlInstance(languageTag)
      .then((intlInstance) => {
        if (isSubscribed) {
          setIntl(intlInstance)
        }
      })
      .catch((e) => {
        console.error(e)
        throw new Error("failed to load a language")
      })

    return () => {
      isSubscribed = false
    }
  }, [])

  return intl
}
