import { HexColorPreset } from "../products-render-config/types"
import { colorsListDemo } from "./hex-colors.list"
import { PANTONE_COLORS_DB_TISSUE_BACKGROUND_ALL } from "./pantone-colors.database"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"

export class HexColorsDatabase {
  public getByPreset(preset: HexColorPreset): ColorListArray[] {
    switch (preset) {
      case HexColorPreset.ALL:
        return colorsListDemo
      case HexColorPreset.TISSUE_BACKGROUND:
        return this.mapColors(PANTONE_COLORS_DB_TISSUE_BACKGROUND_ALL)
    }
  }

  private mapColors(colors: { color?: string }[]): ColorListArray[] {
    return colors.map(({ color }) => ({ uid: `color-${color}`, color }))
  }
}
