import _difference from "lodash/difference"

export const ALL_PRODUCTS = [
  "mailer-box-plain",
  "mailer-box-eco",
  "mailer-box-eco-white",
  "mailer-box-eco-color",
  "mailer-box-full-color",
  "delivery-mailer-box",
  "delivery-mailer-box-plain",
  "sleeved-mailer-box",
  "product-box",
  "product-box-two-pieces",
  "product-box-wine-box",
  "shipping-box",
  "shipping-box-plain",
  "paper-bag",
  "rigid-envelope",
  "poly-mailer",
  "paper-mailer",
  "sample-packs",
  "tissue-paper",
  "packing-paper",
  "bio-poly-mailer-plain",
]

export const DESIGNLESS_PRODUCTS = ["mailer-box-plain", "shipping-box-plain"]

export const ENVS = ["local", "dev", "staging", "production"]

// Malta and Cyprus is temporary turned off
export const EU_SHIPPING_COUNTRIES = [
  "AT",
  "BE",
  "BG",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB", // Can't exclude from EU, because we ship there and brr:/
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "NL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
]

export const NON_EU_SHIPPING_COUNTRIES = [
  "CH", // 40 € customs clearance
  "NO", // 40 € customs clearance
]

export const EU_ALLOWED_COUNTRIES = [
  "AT",
  "BE",
  "BG",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  // 'GB', Brexit baby
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
  "CY",
  "MT",
]

export const PROBLEMATIC_COUNTRIES = ["CY", "MT"]

export const NOT_EU_ALLOWED_COUNTRIES = [
  "GB", // brexit
  "CH",
  "NO",
]

export const PARTIALLY_SUPPORTED_COUNTRIES = ["AU", "CA", "US"]

export const DIGITAL_DESIGN_ALLOWED_COUNTRIES = ["PL"].concat(
  PARTIALLY_SUPPORTED_COUNTRIES
)

export const ALL_ALLOWED_COUNTRIES = EU_ALLOWED_COUNTRIES.concat(
  NOT_EU_ALLOWED_COUNTRIES
).concat(PARTIALLY_SUPPORTED_COUNTRIES)
export const REGION_SWITCHER_ALLOWED_COUNTRIES = _difference(
  ALL_ALLOWED_COUNTRIES,
  PROBLEMATIC_COUNTRIES
)

export const EU_OFFER_EXTRA_AVAILABLE_COUNTRIES = ["CH", "NO", "PL", "CY", "MT"]

export const UNSUPPORTED_STATE_IDS = [
  // SPAIN
  849, // Ceuta
  852, // Canarias
  859, // Melilla
  // FRANCE
  905, // Saint-Barthélemy
  907, // Clipperton
  912, // Guyane
  913, // Gouadeloupe
  920, // Saint-Martin
  921, // Martinique
  923, // Nouvelle Calédonie
  926, // Polynésie française
  927, // Saint-Pierre-et-Miquelon
  930, // La Réunion
  933, // Terres australes françaises
  936, // Wallis et Futuna
  937, // Mayotte
  // PORTUGAL
  2572, // Madeira
  2571, // Acores
  // USA
  3581, // Armed forces America
  3582, // Armed forces America
  3583, // Armed forces America
  3572, // United States Minor Outlying Islands
  3551, // Northern Mariana Islands
  3527, // American Samoa
  3524, // Alaska
  3536, // Guam
  3537, // Hawaii
  3575, // Virgin Islands,
  3566, // Puerto Rico
]

export const EU_ALLOWED_SHIPMENTS = {
  "shipping-method-courier": ["shipping-method-courier-free"],
  "shipping-method-sample-packs": [
    "shipping-method-sample-packs-free",
    "shipping-method-sample-packs-eu4",
  ],
  "shipping-method-pick-up": [],
}

export const EU_DELIVERY_TIME = {
  compartmentDeliveryTime: "3-5",
  avarageDeliveryTime: "5",
  samplePackAverageDeliveryTime: "5",
}

export const EU_ALLOWED_SHIPMENT_TYPES = ["shipping-method-courier"]

export const EU_ALLOWED_PAYMENT_METHODS = [
  "paypal",
  "check",
  "stripe",
  "p24",
  "ideal",
  "bancontact",
  "eps",
  "giropay",
  "card",
  "hokodo",
]

export const EU_ALLOWED_PRODUCT_TYPES = ALL_PRODUCTS

export const PL_ALLOWED_SHIPMENTS = {
  "shipping-method-courier": ["shipping-method-courier-free"],
  "shipping-method-pick-up": ["shipping-method-pick-up"],
  "shipping-method-polish-post": ["shipping-method-polish-post"],
  "shipping-method-sample-packs": ["shipping-method-courier-free"],
}

export const PL_DELIVERY_TIME = {
  compartmentDeliveryTime: "1-2",
  avarageDeliveryTime: "3",
  samplePackAverageDeliveryTime: "3",
}

export const PL_ALLOWED_SHIPMENT_TYPES = [
  "shipping-method-courier",
  "shipping-method-polish-post",
]

export const PL_ALLOWED_PAYMENT_METHODS = [
  "check",
  "stripe",
  "p24",
  "card",
  "hokodo",
]

export const PL_ALLOWED_PRODUCT_TYPES = ALL_PRODUCTS

export const GB_DELIVERY_TIME = {
  compartmentDeliveryTime: "3-5",
  avarageDeliveryTime: "5",
  samplePackAverageDeliveryTime: "3",
}

export const GB_ALLOWED_PAYMENT_METHODS = ["check", "stripe", "card", "hokodo"]

//TODO: REMOVE THIS export constS
export const EU_MAIN_DOMAIN = "https://packhelp.com"
export const UK_MAIN_DOMAIN = "https://packhelp.co.uk"
export const DE_MAIN_DOMAIN = "https://packhelp.de"
export const PL_MAIN_DOMAIN = "https://zapakuj.to"
export const NL_MAIN_DOMAIN = "https://packhelp.nl"
export const SE_MAIN_DOMAIN = "https://packhelp.se"
export const RO_MAIN_DOMAIN = "https://packhelp.ro"

export const SHARED_CURRENCY_PROPERTIES = {
  eur: {
    currency: "EUR",
    vatAmount: 23,
    alwaysShowAddedTax: false,
    currencyFormatter: {
      thousandSeparator: ",",
      decimalSeparator: ".",
      currencySign: "€",
      currencyPosition: "left",
    },
    productPricingValues: {
      vatAmount: 23,
      alwaysShowAddedTax: false,
    },
  },
  gbp: {
    currency: "GBP",
    vatAmount: 20,
    alwaysShowAddedTax: true,
    currencyFormatter: {
      thousandSeparator: ",",
      decimalSeparator: ".",
      currencySign: "£",
      currencyPosition: "left",
    },
    productPricingValues: {
      vatAmount: 20,
      alwaysShowAddedTax: true,
    },
  },
  pln: {
    currency: "PLN",
    vatAmount: 23,
    alwaysShowAddedTax: true,
    currencyFormatter: {
      thousandSeparator: " ",
      decimalSeparator: ",",
      currencySign: "zł",
      currencyPosition: "right",
    },

    productPricingValues: {
      vatAmount: 23,
      alwaysShowAddedTax: true,
    },
  },
  ron: {
    currency: "RON",
    vatAmount: 23,
    alwaysShowAddedTax: false,
    currencyFormatter: {
      thousandSeparator: ",",
      decimalSeparator: ".",
      currencySign: "RON",
      currencyPosition: "right",
    },

    productPricingValues: {
      vatAmount: 23,
      alwaysShowAddedTax: false,
    },
  },
  sek: {
    currency: "SEK",
    vatAmount: 23,
    alwaysShowAddedTax: false,
    currencyFormatter: {
      thousandSeparator: ",",
      decimalSeparator: ".",
      currencySign: "kr",
      currencyPosition: "right",
    },

    productPricingValues: {
      vatAmount: 23,
      alwaysShowAddedTax: false,
    },
  },
  usd: {
    currency: "USD",
    vatAmount: 0,
    alwaysShowAddedTax: false,
    currencyFormatter: {
      thousandSeparator: ",",
      decimalSeparator: ".",
      currencySign: "$",
      currencyPosition: "left",
    },

    productPricingValues: {
      vatAmount: 0, // ?
      alwaysShowAddedTax: false,
    },
  },
}

export const SHARED_ECOMMERCE_PROPERTIES = {
  eu: {
    allSupportedCountries: ALL_ALLOWED_COUNTRIES,
    partiallySupportedCountries: PARTIALLY_SUPPORTED_COUNTRIES,
    regionSwitcherAllowedCountries: REGION_SWITCHER_ALLOWED_COUNTRIES,
    allowedCountriesOutsideEu: NOT_EU_ALLOWED_COUNTRIES,
    unsupportedStates: UNSUPPORTED_STATE_IDS,
    allowedProductTypes: EU_ALLOWED_PRODUCT_TYPES,
    allowedShippingCountries: EU_SHIPPING_COUNTRIES.concat(
      NON_EU_SHIPPING_COUNTRIES
    ),
    offerExtraAvailableCountries: EU_OFFER_EXTRA_AVAILABLE_COUNTRIES,
    allowedShipments: EU_ALLOWED_SHIPMENTS,
    deliveryTime: EU_DELIVERY_TIME,
    allowedShipmentTypes: EU_ALLOWED_SHIPMENT_TYPES,
    allowedPaymentMethods: EU_ALLOWED_PAYMENT_METHODS,
    designlessProductIds: DESIGNLESS_PRODUCTS,
    shipmentsConfig: {
      allowedShipments: {
        "shipping-method-courier": [
          "shipping-method-courier-free",
          "shipping-method-sample-packs-eu4",
        ],
      },
      deliveryTime: {
        compartmentDeliveryTime: "1-2",
        avarageDeliveryTime: "5",
        samplePackAverageDeliveryTime: "5",
      },
    },
  },
  gb: {
    allSupportedCountries: ALL_ALLOWED_COUNTRIES,
    partiallySupportedCountries: PARTIALLY_SUPPORTED_COUNTRIES,
    regionSwitcherAllowedCountries: REGION_SWITCHER_ALLOWED_COUNTRIES,
    allowedCountriesOutsideEu: NOT_EU_ALLOWED_COUNTRIES,
    unsupportedStates: UNSUPPORTED_STATE_IDS,
    allowedProductTypes: EU_ALLOWED_PRODUCT_TYPES,
    allowedShippingCountries: ["GB"],
    offerExtraAvailableCountries: EU_OFFER_EXTRA_AVAILABLE_COUNTRIES,
    allowedShipments: EU_ALLOWED_SHIPMENTS,
    deliveryTime: GB_DELIVERY_TIME,
    allowedShipmentTypes: EU_ALLOWED_SHIPMENT_TYPES,
    allowedPaymentMethods: GB_ALLOWED_PAYMENT_METHODS,
    designlessProductIds: DESIGNLESS_PRODUCTS,
    shipmentsConfig: {
      allowedShipments: {
        "shipping-method-courier": [
          "shipping-method-courier-free",
          "shipping-method-sample-packs-eu4",
        ],
      },
      deliveryTime: {
        compartmentDeliveryTime: "1-2",
        avarageDeliveryTime: "5",
        samplePackAverageDeliveryTime: "5",
      },
    },
  },
  pl: {
    allSupportedCountries: ALL_ALLOWED_COUNTRIES,
    partiallySupportedCountries: PARTIALLY_SUPPORTED_COUNTRIES,
    regionSwitcherAllowedCountries: REGION_SWITCHER_ALLOWED_COUNTRIES,
    allowedCountriesOutsideEu: NOT_EU_ALLOWED_COUNTRIES,
    unsupportedStates: UNSUPPORTED_STATE_IDS,
    allowedShippingCountries: ["PL"],
    offerExtraAvailableCountries: [],
    allowedShipments: PL_ALLOWED_SHIPMENTS,
    deliveryTime: PL_DELIVERY_TIME,
    allowedProductTypes: PL_ALLOWED_PRODUCT_TYPES,
    allowedShipmentTypes: PL_ALLOWED_SHIPMENT_TYPES,
    allowedPaymentMethods: PL_ALLOWED_PAYMENT_METHODS,
    designlessProductIds: DESIGNLESS_PRODUCTS,
    shipmentsConfig: {
      allowedShipments: {
        "shipping-method-courier": ["shipping-method-courier-free"],
        "shipping-method-pick-up": ["shipping-method-pick-up"],
        "shipping-method-polish-post": ["shipping-method-polish-post"],
      },
      deliveryTime: {
        compartmentDeliveryTime: "3-5",
        avarageDeliveryTime: "3",
        samplePackAverageDeliveryTime: "3",
      },
    },
  },
}

export const DATES_FORMATTING_RULES_EU = {
  frontend: {
    standardFormat: "dddd, D MMMM",
    shortFormat: "D MMM",
    detailedFormat: "D MMMM YYYY, h:mmA",
  },
}

export const DATES_FORMATTING_RULES_PL = {
  frontend: {
    standardFormat: "dddd, D MMMM",
    shortFormat: "D MMM",
    detailedFormat: "D MMMM YYYY, H:mm",
  },
}

export const DATES_FORMATTING_RULES_US = {
  frontend: {
    standardFormat: "dddd, MMMM D",
    shortFormat: "MMM D",
    detailedFormat: "MMMM D YYYY, H:mm",
  },
}

// In checkout stages we prevent to fetch order assigned
// to last user order as procedeed order has priority
export const urlWhitelistedToBeFetchedFromUrl = [
  "/order/:orderNumber/payment",
  "/order/:orderNumber/details",
]

export const GOOGLEAPIS_KEY = "AIzaSyDk1Cv-RrgiQi2ByHys9mea9oUSdpFp_5c"

export const externalDomainPackhelpLinks = {
  iosAppStoreUrl: "https://itunes.apple.com/us/app/id1275418233",
  notionInvestorRelationsPage:
    "https://www.notion.so/packhelp/Packhelp-S-A-c8b84934d21b4ea1afb7df15859110f7",
  facebook: "https://facebook.com/packhelp/",
  instagram: "https://www.instagram.com/packhelp/",
  linkedin: "https://www.linkedin.com/company/packhelp",
  pinterest: "https://pinterest.com/packhelp",
  tiktok: "https://www.tiktok.com/@packhelp.com",
  twitter: "https://twitter.com/packhelpcom",
  youtube: "https://www.youtube.com/c/Packhelp",
  premade: "https://premade.packhelp.com",
}

export const TRUSTPILOT = {
  trustpilotId: "293161f9ed",
  trustpilotBusinessId: "58c952830000ff00059e87f2",
  trustpilotInvitationTemplateId: "6166c756ef0f407d3561e470",
}

export const GTAG_ID = {
  production: "G-WQ46LW8YC1",
  nonProduction: "G-EWR3WZTZBY",
}
