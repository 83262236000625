import React from "react"
import cxBinder from "classnames/bind"
import styles from "./FormField.module.scss"

const cx = cxBinder.bind(styles)

export interface FormFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  field: React.ReactNode
  hint?: React.ReactNode
  link?: React.ReactNode
  label?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  fieldID?: string
  error?: React.ReactNode
  e2eTarget?: string
  e2eTargetName?: string
  withBasicSpacing?: boolean
  mobileFlex?: boolean
  showErrorAboveField?: boolean
}

const FormFieldError = (props: {
  children: React.ReactNode
  e2eTarget?: string
  e2eTargetName?: string
  noMargin?: boolean
}) => {
  const { e2eTarget = "error-message", e2eTargetName, noMargin } = props

  return (
    <span
      className={cx("error", { "no-margin": noMargin })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {props.children}
    </span>
  )
}

const FormField = (props: FormFieldProps) => {
  const {
    fieldID,
    label,
    field,
    hint,
    link,
    subtitle,
    error,
    e2eTarget,
    e2eTargetName,
    withBasicSpacing,
    mobileFlex,
    showErrorAboveField,
    ...other
  } = props

  return (
    // @ts-ignore
    <div
      className={cx(styles.base, {
        "with-spacing": withBasicSpacing,
        "mobile-flex": mobileFlex
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      <div className={styles.legend}>
        {label && (
          <label className={styles.label} htmlFor={fieldID} e2e-target="label">
            {label}
          </label>
        )}
        {hint && (
          <span className={styles.hint} e2e-target="hint">
            {hint}
          </span>
        )}
        {link && (
          <span className={styles["link"]} e2e-target="link">
            {link}
          </span>
        )}
      </div>
      {showErrorAboveField && error && (
        <FormFieldError noMargin>{error}</FormFieldError>
      )}
      {field}
      {subtitle && (
        <span className={styles.subtitle} e2e-target="subtitle">
          {subtitle}
        </span>
      )}
      {!showErrorAboveField && error && (
        <FormFieldError>{error}</FormFieldError>
      )}
    </div>
  )
}

export { FormField, FormFieldError, FormField as default }
