import { computed, observable, makeObservable, action, autorun } from "mobx"
import ProductDriver from "../drivers/product.driver"
import {
  CanvasContextMenuItem,
  CanvasContextMenuPosition,
} from "../types/ui.types"
import { I18N } from "../ui/i18n"
import removeIconUrl from "../ui/assets/_icons/trash.svg"
import moreIconUrl from "../ui/assets/_icons/dots.svg"
import duplicateIconUrl from "../ui/assets/_icons/duplicate.svg"
import horizontalIconUrl from "../ui/assets/_icons/align-horizontal.svg"
import verticalIconUrl from "../ui/assets/_icons/align-vertical.svg"
import forwardIconUrl from "../ui/assets/_icons/send-forward.svg"
import backwardIconUrl from "../ui/assets/_icons/send-backward.svg"
import groupIconUrl from "../ui/assets/_icons/group.svg"
import ungroupIconUrl from "../ui/assets/_icons/ungroup.svg"
import { CanvasObjectControllable } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"

import ActiveObjectDriver, {
  ActiveObject,
} from "../drivers/active-object.driver"
import { EditableObjectTypes } from "../types/asset.types"

const i18n = I18N.components.canvasContextMenu

export class CanvasObjectContextMenuUiController {
  @observable public isDropdownOpen = false

  private readonly productDriver: ProductDriver

  constructor(services: { productDriver: ProductDriver }) {
    this.productDriver = services.productDriver

    makeObservable(this)

    autorun(() => {
      if (!this.activeObject) {
        this.setIsDropdownOpen(false)
      }
    })
  }

  public clearHighlight(): void {
    this.productDriver.clearHighlight()
  }

  @computed
  public get popoverPosition(): CanvasContextMenuPosition | undefined {
    const { activeObject, activeObjectComputable } =
      this.productDriver.activeObjectDriver

    if (!activeObject) {
      return
    }

    const vdEditor = this.productDriver.getVdEditor()
    const diff = vdEditor.getCanvasToSceneDiff()

    const { boundingTop, boundingLeft, boundingWidth, boundingHeight } =
      activeObjectComputable

    const left = boundingLeft - diff.width / 2
    const top = boundingTop - diff.height / 2
    const angle = Math.abs(activeObject.angle || 0)

    const topControlAngleRange = {
      min: 135,
      max: 225,
    }

    if (
      angle >= topControlAngleRange.min &&
      angle <= topControlAngleRange.max
    ) {
      return {
        position: "bottom",
        left: left + boundingWidth / 2,
        top: top + boundingHeight,
      }
    }

    return {
      position: "top",
      left: left + boundingWidth / 2,
      top: top,
    }
  }

  @computed
  public get items(): CanvasContextMenuItem[] {
    const items: CanvasContextMenuItem[] = []

    if (this.activeObjectController?.isGroupingAvailable()) {
      items.push({
        type: "group",
        titleIntl: i18n.group,
        iconUrl: groupIconUrl,
        onClick: () => this.activeObjectDriver.groupObject(),
      })
    }

    if (this.activeObjectController?.isUngroupingAvailable()) {
      items.push({
        type: "ungroup",
        titleIntl: i18n.ungroup,
        iconUrl: ungroupIconUrl,
        onClick: () => this.activeObjectDriver.ungroupObject(),
      })
    }

    if (this.activeObjectController?.isDuplicatingAvailable()) {
      items.push({
        type: "duplicate",
        titleIntl: i18n.duplicate,
        iconUrl: duplicateIconUrl,
        onClick: () => this.activeObjectDriver.duplicateObject(),
      })
    }

    items.push(
      {
        type: "remove",
        titleIntl: i18n.remove,
        iconUrl: removeIconUrl,
        onClick: () => this.activeObjectDriver.removeObject(),
      },
      {
        type: "more-options",
        titleIntl: i18n.moreOptions,
        iconUrl: moreIconUrl,
        onClick: () => this.toggleIsDropdownOpen(),
      }
    )

    return items
  }

  @computed
  public get dropdownItems(): CanvasContextMenuItem[] {
    const items: CanvasContextMenuItem[] = []

    items.push({
      type: "align-horizontal",
      titleIntl: i18n.horizontal,
      iconUrl: horizontalIconUrl,
      onClick: () => this.activeObjectDriver.alignObjectHorizontal(),
    })

    items.push({
      type: "align-vertical",
      titleIntl: i18n.vertical,
      iconUrl: verticalIconUrl,
      onClick: () => this.activeObjectDriver.alignObjectVertical(),
    })

    if (this.activeObjectController?.isMovingLayerUpDownAvailable()) {
      items.push(
        {
          type: "move-forward",
          titleIntl: i18n.front,
          iconUrl: forwardIconUrl,
          onClick: () => this.activeObjectDriver.moveObjectLayerUp(),
        },
        {
          type: "move-backward",
          titleIntl: i18n.back,
          iconUrl: backwardIconUrl,
          onClick: () => this.activeObjectDriver.moveObjectLayerDown(),
        }
      )
    }

    return items
  }

  @computed
  public get activeObjectType(): EditableObjectTypes | undefined {
    return this.activeObject?.assetType
  }

  private toggleIsDropdownOpen(): void {
    this.setIsDropdownOpen(!this.isDropdownOpen)
  }

  @action
  private setIsDropdownOpen(isOpen: boolean): void {
    this.isDropdownOpen = isOpen
  }

  private get activeObjectDriver(): ActiveObjectDriver {
    return this.productDriver.activeObjectDriver
  }

  @computed
  private get activeObject(): ActiveObject | null {
    return this.activeObjectDriver.activeObject
  }

  @computed
  private get activeObjectController(): CanvasObjectControllable | null {
    return this.activeObjectDriver.activeObjectController
  }
}
