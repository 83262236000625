import type { IntlShape } from "react-intl"
import React, { FC } from "react"
import { translateLocaleKeyToRegionConfigKey } from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import RegionConfig from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import _isString from "lodash/isString"
import _first from "lodash/first"
import _split from "lodash/split"
import _get from "lodash/get"

import { languageMap, Lang } from "shared-libs/src/js/libs/AsyncI18nLocales"

import { createIntl, createIntlCache, FormattedMessage } from "react-intl"
import { isNode } from "../utils"
import {
  useIntlViaContextOrImperative,
  useFormatMessage as useFormatMessage2,
} from "../../shared-components/i18n/useTranslate"

/**
 * Z (apakuj.to) (supported) Language
 */
export type ZLanguage =
  | "en"
  | "de"
  | "cs"
  | "fr"
  | "es"
  | "pl"
  | "it"
  | "se"
  | "pt"
  | "ro"
  | "nl"

/**
 * A locale is a language-COUNTRY(REGION) combination
 *
 * Please note that a language (see also ZLanguaue type)
 * can be native/active for multiple countries,
 * regions, territories, e.g. for "spanish" / "es" language the
 * list is as follows:
 *
 * es-AR
 * es-BO
 * es-CL
 * es-CO
 * es-CR
 * es-DO
 * es-EC
 * es-ES
 * es-GT
 * es-HN
 * es-MX
 * es-NI
 * es-PA
 * es-PE
 * es-PR
 * es-PY
 * es-SV
 * es-US
 * es-UY
 * es-VE
 */
type ZLocale =
  | "pl-PL"
  | "en-US"
  | "de-DE"
  | "cs-CZ"
  | "fr-FR"
  | "it-IT"
  | "es-ES"
  | "pt-PT"
  | "sv-SE"
  | "ro-RO"
  | "nl-NL"

/**
 * In our case, a languageTag the same as the locale
 */
export type ZLanguageTag = ZLocale

/**
 * Translation context with React hooks support
 */

const cache = createIntlCache()
let my_intl: ReturnType<typeof createIntl> | null = null
export function _getMyIntlInstance() {
  return my_intl
}

export type FormatMessage = (
  message: { id: string },
  values?: Record<string, string | number>
) => string

// Sometimes we force to load different locale on domain region
export const getLanguageTag = (): Lang => {
  if (isNode()) {
    return RegionConfig.getKeyForCurrentRegion("languageTag")
  }

  const localeFromUrl = new URLSearchParams(window.location.search).get(
    "locale"
  )
  const localeFromEnv = _get(globalThis, "ph.locale.lang")

  // This is temporary fix to bypass region config initialization for editor. It should be removed after locale refactoring
  if (localeFromEnv === "pt") {
    return Lang.ptPT
  }

  const forcedLocale = localeFromUrl || localeFromEnv

  if (forcedLocale) {
    // in case of fire - eg. not existing region, it's fallback to domain locale
    return RegionConfig.getKeyForRegion(
      translateLocaleKeyToRegionConfigKey(forcedLocale),
      "languageTag"
    )
  } else {
    return RegionConfig.getKeyForCurrentRegion("languageTag")
  }
}

export const loadIntlMessages = async (languageTag?: Lang) => {
  languageTag = languageTag || getLanguageTag()
  try {
    const messageLoader = languageMap[languageTag]
    const stuff = await messageLoader()
    return stuff
  } catch (e) {
    console.error(e)
    throw new Error("failed to load a language")
  }
}

export async function getIntlInstance(
  languageTag?: Lang
): Promise<ReturnType<typeof createIntl>> {
  if (my_intl != null) return my_intl
  const messages = await loadIntlMessages(languageTag)

  my_intl = createIntl(
    {
      locale: languageTag || getLanguageTag(),
      messages: messages as any,
      textComponent: "span",
    },
    cache
  )

  return my_intl
}

export const getFormatMessage = async (): Promise<
  IntlShape["formatMessage"]
> => {
  const myLocalIntl = await getIntlInstance()

  return myLocalIntl.formatMessage
}

export const useFormatMessage = () => {
  const translate = useFormatMessage2()
  return translate
}

export const useFormatMessageWithValues = (id, values) => {
  return <FormattedMessage id={id} values={values} />
}

export const useReactIntlObject = () => {
  const intl = useIntlViaContextOrImperative()
  return intl
}

export const BareFormattedMessage: FC<{ id: string; values?: any }> = ({
  id,
  values,
}) => {
  const translate = useFormatMessage()
  return <>{translate({ id: id }, values)}</>
}

// -- End of translation context for React Hooks

interface IntlData {
  locales: ZLanguageTag[]
  messages: { [k in ZLanguageTag]?: any }
}

export function getIntlData() {
  return {
    locales: [
      "pl-PL",
      "en-US",
      "de-DE",
      "cs-CZ",
      "fr-FR",
      "it-IT",
      "es-ES",
      "nl-NL",
      "pt-PT",
      "ro-RO",
      "sv-SE",
    ],
    messages: {
      // "pl-PL": plPL,
      // "en-US": enUS,
      // "de-DE": deDE
      // "cs-CZ": csCZ,
      // "fr-FR": frFR,
      // "it-IT": itIT,
      // "es-ES": esES
    },
  } as IntlData
}

export function translateMsgOrTextWithValues(labeli18n, i18nValues?: any) {
  return <FormattedMessage id={labeli18n} values={i18nValues} />
}

export function translatedMsgOrText(labeli18n, labelText) {
  let label

  if (_isString(labeli18n)) {
    label = <FormattedMessage id={labeli18n} />
  } else {
    label = labelText
  }
  return label
}

/**
 * Converts a locale (tag) (something like 'en-US')
 * to a language (something like 'en')
 */
export const getLanguageOfLanguageTag = (
  languageTag: ZLanguageTag
): ZLanguage => {
  const language = _first(_split(languageTag, "-"))
  switch (language) {
    case "cs":
    case "de":
    case "en":
    case "es":
    case "fr":
    case "it":
    case "pl":
    case "pt":
    case "nl":
    case "ro":
    case "se":
      return language
    default:
      throw new Error(
        `Unknown language ${language} of language-tag ${languageTag}`
      )
  }
}
