import React from "react"
import {
  PickerToggle,
  PickerToggleType,
} from "../../../atoms/PickerToggle/PickerToggle"
import { usePortal } from "../../../../hooks/usePortal"
import styles from "./ShapeConfigurator.module.scss"
import { Picker } from "../../../molecules/Picker/Picker"
import { Shape } from "../../../../../models/shape"
import { TileList } from "../../../atoms/Tile/TileList"
import { Tile } from "../../../atoms/Tile/Tile"
import { AnalyticsSection } from "../../../types"

interface ShapeConfiguratorProps {
  title?: string
  preventHideClickOutside?: boolean
  selectedShape?: Shape
  shapes: Shape[]
  onShapeSelect: (shape: Shape) => void
  analyticsSection?: AnalyticsSection
  analyticsName?: string
}

export function ShapeConfigurator({
  selectedShape,
  shapes,
  title,
  onShapeSelect,
  analyticsSection,
  analyticsName,
}: ShapeConfiguratorProps) {
  const { isPortalOpen, Portal, togglePortal } = usePortal({
    offsetTop: 6,
  })

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <PickerToggle
          onClick={togglePortal}
          type={PickerToggleType.rounded}
          analyticsSection={analyticsSection}
          analyticsType="picker-toggle"
          analyticsName={analyticsName}
        >
          {selectedShape && (
            <img
              src={selectedShape.src}
              loading="lazy"
              alt={selectedShape.title}
            />
          )}
        </PickerToggle>
      </header>

      {isPortalOpen && (
        <Portal>
          <div className={styles.portal}>
            <Picker>
              <TileList cols={3}>
                {shapes.map((shape) => {
                  return (
                    <Tile
                      key={shape.id}
                      onClick={() => onShapeSelect(shape)}
                      active={selectedShape?.id === shape.id}
                      analyticsSection={analyticsSection}
                      analyticsType="thumbnail"
                      analyticsName="mask"
                      analyticsId={shape.title}
                    >
                      <figure>
                        <img src={shape.src} alt={shape.title} />
                      </figure>
                    </Tile>
                  )
                })}
              </TileList>
            </Picker>
          </div>
        </Portal>
      )}
    </div>
  )
}
