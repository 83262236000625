import { ProductRenderPilot } from "../product-render-pilot"
import { DesignVersion } from "../../../types/design.version"
import {
  ColorMode,
  ColorPreset,
  DbyDielineType,
  EditContext,
  EditorMode,
  SpaceId,
  MonochromeColor,
  PantoneColorPreset,
  RotationMap,
  SpaceDimensions,
  HexColorPreset,
} from "../types"
import {
  AvailableProperty,
  PrintCoverage,
  ProductManager,
  Variant,
  VariantEditorAssets,
} from "@ph/product-api"
import { PimRenderConfigBuilder } from "./pim-render-config-builder"
import { PimUiConfigBuilder } from "./pim-ui-config-builder"
import { isPrintAdditionallyPaidFor, isPrintAvailableFor } from "./helpers"
import Colour from "../../../models/colour"
import _compact from "lodash/compact"
import { AvailableRotations } from "../../../render-engine/modules/vd-editor/modules/dieline-navigator/calculators/translation.calculator"
import { isMonochrome, isMonoPantone } from "../helpers"

export class PimProductRenderPilot extends ProductRenderPilot {
  constructor(
    product: ProductManager,
    designVersion: DesignVersion,
    editorMode: EditorMode
  ) {
    super(
      product,
      designVersion,
      editorMode,
      new PimRenderConfigBuilder(product).build(),
      new PimUiConfigBuilder(product, editorMode, false).build()
    )
  }

  public is3DProduct(): boolean {
    const renderType = this.variant.editorAssets.renderConfig?.type

    if (!renderType) {
      return super.is3DProduct()
    }

    return renderType === "3d"
  }

  public setAfterPurchaseEdit(isAfterPurchaseEdit: boolean) {
    this.isAfterPurchaseEdit = isAfterPurchaseEdit

    this.uiConfig = new PimUiConfigBuilder(
      this.product,
      this.editorMode,
      isAfterPurchaseEdit
    ).build()
  }

  public getColorPreset(): ColorPreset {
    const renderConfig = this.getPimRenderConfig()

    if (!renderConfig) {
      throw Error("Render config is not available!")
    }

    const { mode, blendFactor, defaultColor } = renderConfig.colorPreset

    const colorPreset = {
      mode: mode as ColorMode,
      colourSettings: {
        blendFactor,
      },
    }

    if (isMonoPantone(mode)) {
      return {
        ...colorPreset,
        colorPantoneDefault: new Colour(defaultColor.hex, defaultColor.pantone),
      }
    }

    if (isMonochrome(mode)) {
      return {
        ...colorPreset,
        colourMonochrome: defaultColor.hex as MonochromeColor,
      }
    }

    return colorPreset
  }

  public isDbyOnly(): boolean {
    const renderConfig = this.getPimRenderConfig()

    if (!renderConfig) {
      return true
    }

    return renderConfig.type === "dby"
  }

  public isPrintActiveFor(editContext: EditContext): boolean {
    const colorMode = this.product.addonsManager.getColorMode()

    switch (editContext) {
      case EditContext.INSIDE:
        return colorMode === PrintCoverage.OutsideInside
      case EditContext.BACK:
        return colorMode === PrintCoverage.FrontBack
      default:
        return [
          EditContext.OUTSIDE,
          EditContext.FRONT,
          EditContext.SLEEVE,
        ].includes(editContext)
    }
  }

  public getAvailableSpaces(editContext: EditContext): SpaceId[] {
    return this.configReader.getContextEditableSpaceIds(editContext)
  }

  public isPrintAvailableFor(editContext: EditContext): boolean {
    return isPrintAvailableFor(this.product, editContext, this.isDbyMode())
  }

  public isPrintAdditionallyPaidFor(editContext: EditContext): boolean {
    return isPrintAdditionallyPaidFor(this.product, editContext)
  }

  public getRotationMap(editContext: EditContext): RotationMap {
    const config = this.renderConfig.availableEditContextsMap[editContext]

    if (!config) {
      return {}
    }

    return Object.fromEntries(
      _compact(
        config.map((space) => space.rotation && [space.spaceId, space.rotation])
      )
    )
  }

  public getDielineRotation(editContext: EditContext): AvailableRotations {
    const configs = this.getPimRenderConfig()?.editContexts

    if (!configs) {
      return AvailableRotations.none
    }

    const editContextConfig = configs.find(
      (context) => context.name === editContext
    )

    return editContextConfig?.dielineRotation || AvailableRotations.none
  }

  public getHexColorPreset(): HexColorPreset | undefined {
    const palette = this.getPimRenderConfig()?.colorPreset?.hexPalette?.default

    if (!palette) {
      return HexColorPreset.ALL
    }

    return palette as HexColorPreset
  }

  public getPantoneColorPreset(): PantoneColorPreset | undefined {
    const palette =
      this.getPimRenderConfig()?.colorPreset?.pantonePalette?.default

    return palette as PantoneColorPreset
  }

  public getBackgroundHexColorPreset(): HexColorPreset | undefined {
    const palette =
      this.getPimRenderConfig()?.colorPreset?.hexPalette?.background

    if (!palette) {
      return HexColorPreset.ALL
    }

    return palette as HexColorPreset
  }

  public getBackgroundPantoneColorPreset(): PantoneColorPreset | undefined {
    const palette =
      this.getPimRenderConfig()?.colorPreset?.pantonePalette?.background

    return palette as PantoneColorPreset
  }

  public getAvailablePrintingModes(): AvailableProperty[] {
    return this.product?.addonsManager.getAvailablePrintingModes()
  }

  public getSpaceDimensions(
    editContext: EditContext,
    spaceId: SpaceId
  ): SpaceDimensions {
    return this.product?.renderConfigManager.calculateSpaceSize(
      spaceId === SpaceId.DEFAULT ? editContext : spaceId
    )
  }

  public getPredefinedDbyDielineUrl(
    type: DbyDielineType,
    _region = "eu"
  ): string | undefined {
    return this.product.variantManager.getDielineUrl(type)
  }

  private getPimRenderConfig(): VariantEditorAssets["renderConfig"] {
    return this.variant.editorAssets.renderConfig
  }

  private get variant(): Variant {
    return this.product.getDefaultVariant()
  }
}
