import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../../Tab/TabSection"
import { I18N } from "../../../../../i18n"
import { OptionBox } from "../../../../molecules/OptionBox/OptionBox"
import imageUrl from "../../../../../assets/_images/option-box--fsc.png"
import { FscShapeList } from "./FscShapeList"

const i18n = I18N.editorTools.fsc

export const FscSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.fscCertificate])

  if (!containerSet) {
    return null
  }

  const { fscCertificate } = containerSet

  if (!fscCertificate.available) {
    return null
  }

  const { fscCertificateStore, fscCertificateUiController } = fscCertificate
  const { isAvailable } = fscCertificateUiController
  const { isSelected } = fscCertificateStore

  if (!isAvailable) {
    return null
  }

  const onClick = () => {
    fscCertificateUiController.toggleFscCertificate()
  }

  return (
    <TabSection>
      <OptionBox
        title={t(i18n.title)}
        description={t(i18n.addFscFormBlock.description)}
        onClick={onClick}
        active={isSelected}
        imageUrl={imageUrl}
        price={fscCertificateStore.displayPrice}
        loading={fscCertificateStore.isLoadingFscCertificate}
        analyticsSection="left-tab-certificates"
        analyticsName="fsc-certificate"
        e2eTargetName="fsc-certificate"
      />

      <FscShapeList />
    </TabSection>
  )
})
