import React, { forwardRef, RefObject, useRef } from "react"
import cxBinder from "classnames/bind"
import ScrollWithShadow from "../../../../atoms/ScrollWithShadow/ScrollWithShadow"
import { QuantityItem } from "../../types"
import { PickerOption } from "../picker-option/PickerOption"
import styles from "./PickerOptionsList.module.scss"

const cx = cxBinder.bind(styles)

interface PickerOptionsListProps {
  consise?: boolean
  value: QuantityItem | undefined
  match?: QuantityItem
  options: QuantityItem[]
  isMobile?: boolean
  isMatchingEnabled?: boolean
  scrollRef?: RefObject<HTMLDivElement>
  onSelect: (item: QuantityItem, immediate?: boolean) => void
  onItemAction?: (item: QuantityItem) => void
}
export const PickerOptionsList = forwardRef<
  HTMLUListElement,
  PickerOptionsListProps
>((props, ref): any => {
  const {
    consise,
    value,
    match,
    isMobile,
    options,
    isMatchingEnabled,
    scrollRef,
    onSelect,
    onItemAction
  } = props

  const localScrollRef = useRef<HTMLDivElement>(null)

  const isSelected = (item: QuantityItem) =>
    item.quantity === (value && value.quantity)

  const isMatching = (item: QuantityItem) =>
    isMatchingEnabled && item.quantity === (match && match.quantity)

  return (
    <div ref={scrollRef || localScrollRef} className={styles["scroll-wrapper"]}>
      <ScrollWithShadow>
        <div
          className={cx("scroll", {
            "scroll--mobile": isMobile,
            "scroll--consise": consise
          })}
        >
          <ul
            ref={ref}
            className={cx("list", { "list--empty": !options.length })}
          >
            {options.map((quantityItem) => {
              // hack created to avoid render artifacts caused by a lack of a better key
              // more on that: https://github.com/packhelp/packhelp/pull/14292
              const randomString = () => Math.random().toString(36).substring(8)

              return (
                <PickerOption
                  key={randomString()}
                  isSelected={isSelected(quantityItem)}
                  isMatching={isMatching(quantityItem)}
                  isMobile={isMobile}
                  item={quantityItem}
                  onSelect={onSelect}
                  onItemAction={onItemAction}
                  scrollRef={scrollRef || localScrollRef}
                />
              )
            })}
          </ul>
        </div>
      </ScrollWithShadow>
    </div>
  )
})
