import { DielineStore } from "../../../stores/dieline.store"
import {
  ModelConfig,
  ModelSettings,
  ObjModelConfig,
  SvgModelConfig,
} from "./types"
import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot"
import {
  EditContext,
  ModelContext,
} from "../../../libs/products-render-config/types"

export class ModelConfigProvider {
  constructor(private readonly dielineStore: DielineStore) {}

  public async call(
    productRenderPilot: ProductRenderPilot,
    modelContext: ModelContext
  ): Promise<ModelConfig> {
    if (productRenderPilot.hasObjModel()) {
      return this.buildObjModelConfig(productRenderPilot, modelContext)
    }

    return this.buildSvgModelConfig(productRenderPilot, modelContext)
  }

  private async buildSvgModelConfig(
    productRenderPilot: ProductRenderPilot,
    context: ModelContext
  ): Promise<SvgModelConfig> {
    const settings = this.buildSettings(productRenderPilot, context)
    const svg = await this.dielineStore.getVirtualDielineSvg(
      productRenderPilot.getProduct(),
      productRenderPilot.getDefaultEditContext()
    )

    if (!svg) {
      throw new Error("Could not get dieline SVG")
    }

    const textureDefinitions = [EditContext.OUTSIDE, EditContext.INSIDE].map(
      (editContext) => {
        const textureDefinition = productRenderPilot.getVirtualDielineTexture(
          editContext,
          false
        )

        return {
          type: editContext,
          path: textureDefinition?.path,
        }
      }
    )

    return {
      svg,
      textureDefinitions,
      settings,
      context,
    }
  }

  private buildObjModelConfig(
    productRenderPilot: ProductRenderPilot,
    context: ModelContext
  ): ObjModelConfig {
    const settings = this.buildSettings(productRenderPilot, context)
    const objFilePath = productRenderPilot.getObjModelPath(context)
    const textureDefinitions = productRenderPilot.getObjModelTextures(context)

    return {
      objFilePath,
      textureDefinitions,
      context,
      settings,
    }
  }

  private buildSettings(
    productRenderPilot: ProductRenderPilot,
    modelContext: ModelContext
  ): ModelSettings {
    const coloursPreset = productRenderPilot.getColorPreset()

    return {
      blendOpacity: coloursPreset.colourSettings.blendFactor,
      colorMode: coloursPreset.mode,
      cameraSettings: {
        default: productRenderPilot.getCameraSettings(modelContext),
        screenshot: productRenderPilot.getCameraSettings(modelContext),
        thumbnail: productRenderPilot.getThumbCameraSettings(modelContext),
      },
    }
  }
}
