import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./CanvasSpaceContextMenu.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../hooks/useTranslate"
import cxBinder from "classnames/bind"
import { DropdownButton } from "../../atoms/Dropdown/DropdownButton"
import { I18N } from "../../../i18n"
import editIconUrl from "../../../assets/_icons/edit.svg"

const cx = cxBinder.bind(styles)

export const CanvasSpaceContextMenu = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { canvasSpaceContextMenuUiController } = containerSet.ui
  const { popoverPosition } = canvasSpaceContextMenuUiController

  if (!popoverPosition) {
    return null
  }

  const { left, top, position } = popoverPosition

  const onClick = () => {
    canvasSpaceContextMenuUiController.showSpace()
  }

  const onHover = () => {
    canvasSpaceContextMenuUiController.clearHighlight()
  }

  return (
    <div
      className={cx("wrapper", position)}
      style={{ top, left }}
      e2e-target="canvas-context-menu"
      e2e-target-name="space"
      onMouseEnter={onHover}
    >
      <DropdownButton
        label={t(I18N.generic.enterTheWallView)}
        iconUrl={editIconUrl}
        onClick={onClick}
      />
    </div>
  )
})
