import type { Alpha2CountryCode, Country } from "@ph/api-studio"
import { action, makeObservable, observable, computed } from "mobx"
import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"
import type {
  ShippingDestination,
  ShippingDestinationError,
} from "../utils/types"
import type { ShippingDestinationSavedEvent } from "./shipping-destination.store-events"
import { ShippingDestinationSaved } from "./shipping-destination.store-events"
import { getDefaultShippingCountry } from "../../../../libs/shipping-countries/get-default-shipping-country"

export interface ShippingDestinationApiServiceInterface {
  user: {
    getUserMe: () => Promise<
      | { shipping_destination?: { country: Country; zip_code: string } }
      | undefined
    >
    saveShippingDestination: ({
      countryIso,
      zipCode,
    }: {
      countryIso: Alpha2CountryCode
      zipCode: string
    }) => Promise<any>
  }
}

export class ShippingDestinationStore {
  @observable error?: ShippingDestinationError
  @observable isModalOpen = false
  @observable isSaving = false
  @observable shippingCountry: Country = {}
  @observable shippingZipCode?: string

  constructor(
    private readonly apiService: ShippingDestinationApiServiceInterface
  ) {
    makeObservable(this)

    document.addEventListener(ShippingDestinationSaved, ((
      event: ShippingDestinationSavedEvent
    ) => this.setShippingDestination(event.detail)) as EventListener)
  }

  public static async init(
    apiService: ShippingDestinationApiServiceInterface
  ): Promise<ShippingDestinationStore> {
    const store = new ShippingDestinationStore(apiService)

    const user = await apiService.user.getUserMe()

    const { shipping_destination: userShippingDestination } = user

    if (userShippingDestination) {
      store.setShippingDestination({
        country: userShippingDestination.country,
        zipCode: userShippingDestination.zip_code,
      })
    } else {
      store.setDefaultShippingCountry()
    }

    return store
  }

  @computed public get isShippingDestinationDefined(): boolean {
    return Boolean(this.shippingCountry) && Boolean(this.shippingZipCode)
  }

  @computed get shippingDestination(): ShippingDestination | undefined {
    if (!this.shippingCountry || !this.shippingZipCode) {
      return
    }

    return {
      country: this.shippingCountry,
      zipCode: this.shippingZipCode,
    }
  }

  @action public setIsModalOpen(isOpen: boolean): void {
    this.isModalOpen = isOpen

    if (!isOpen) {
      this.setError()
    }
  }

  public saveShippingDestination = async (
    countryIso: Alpha2CountryCode,
    zipCode: string
  ): Promise<void> => {
    try {
      this.setIsSaving(true)

      await this.apiService.user.saveShippingDestination({
        countryIso,
        zipCode,
      })

      this.dispatchShippingDestinationSavedEvent(countryIso, zipCode)

      this.setIsModalOpen(false)
    } catch (error) {
      if (window.Sentry) {
        window.Sentry.captureException(error)
      }

      const typedError = error as { response?: { data?: any } }

      const { response } = typedError

      if (!response) {
        return this.setError({ type: "generic", list: [] })
      }

      const { data } = response

      if (data.blocking_line_items) {
        return this.setError({
          type: "blocking-line-items",
          list: data.blocking_line_items.map(({ name }) => name),
        })
      }

      return this.setError({ type: "generic", list: [] })
    } finally {
      this.setIsSaving(false)
    }
  }

  private dispatchShippingDestinationSavedEvent(
    countryIso: Alpha2CountryCode,
    zipCode: string
  ): void {
    const country: Country = { iso: countryIso }

    const event: ShippingDestinationSavedEvent = new CustomEvent(
      ShippingDestinationSaved,
      {
        bubbles: true,
        detail: { country, zipCode },
      }
    )

    document.dispatchEvent(event)
  }

  @action private setError(error?: ShippingDestinationError): void {
    this.error = error
  }

  @action private setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving
  }

  @action private setShippingDestination(
    shippingDestination: ShippingDestination
  ): void {
    this.shippingCountry = shippingDestination.country
    this.shippingZipCode = shippingDestination.zipCode
  }

  @action private setDefaultShippingCountry() {
    const currentISOCountry = RegionConfig.getCurrentISOCountry()

    const defaultCountry =
      currentISOCountry === "EU"
        ? currentISOCountry
        : getDefaultShippingCountry() || currentISOCountry

    this.shippingCountry.name = defaultCountry
    this.shippingCountry.iso = defaultCountry as Alpha2CountryCode
  }
}
