export const config = {
  thickness: 2,
  rotation: {
    initial: 90,
  },
  folding: {
    target: {
      closed: 100,
      opened: 80,
    },
  },
  spaces: {
    left: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: 90,
        step: 3,
      },
    },
    left_foldline: {
      parent: "left",
      folding: {
        axis: "y",
        angle: 90,
        step: 4,
      },
    },
    left_inside: {
      parent: "left_foldline",
      folding: {
        axis: "y",
        angle: 89,
        step: 5,
      },
    },
    right: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: -90,
        step: 3,
      },
    },
    right_foldline: {
      parent: "right",
      folding: {
        axis: "y",
        angle: -90,
        step: 4,
      },
    },
    right_inside: {
      parent: "right_foldline",
      folding: {
        axis: "y",
        angle: -89,
        step: 5,
      },
    },
    front_inside: {
      parent: "bottom",
      folding: {
        axis: "x",
        angle: -90,
        step: 2,
      },
    },
    back: {
      parent: "bottom",
      folding: {
        axis: "x",
        angle: 90,
        step: 2,
      },
    },
    back_left_flap: {
      parent: "back",
      folding: {
        axis: "y",
        angle: 90,
        step: 1,
      },
    },
    back_left_flap_foldline: {
      parent: "back_left_flap",
      folding: {
        axis: "y",
        angle: 0,
        step: 1,
      },
    },
    back_left_flap_inside: {
      parent: "back_left_flap_foldline",
      folding: {
        axis: "x",
        angle: 0,
        step: 1,
      },
    },
    back_right_flap: {
      parent: "back",
      folding: {
        axis: "y",
        angle: -90,
        step: 1,
      },
    },
    back_right_flap_foldline: {
      parent: "back_right_flap",
      folding: {
        axis: "y",
        angle: 0,
        step: 1,
      },
    },
    back_right_flap_inside: {
      parent: "back_right_flap_foldline",
      folding: {
        axis: "x",
        angle: 0,
        step: 1,
      },
    },
    front_inside_left_flap: {
      parent: "front_inside",
      folding: {
        axis: "y",
        angle: 90,
        step: 1,
      },
    },
    front_inside_right_flap: {
      parent: "front_inside",
      folding: {
        axis: "y",
        angle: -90,
        step: 1,
      },
    },
    top: {
      parent: "back",
      folding: {
        axis: "x",
        angle: 90,
        step: 7,
      },
    },
    top_left_flap: {
      parent: "top",
      folding: {
        axis: "y",
        angle: 90,
        step: 6,
      },
    },
    top_right_flap: {
      parent: "top",
      folding: {
        axis: "y",
        angle: -90,
        step: 6,
      },
    },
    front: {
      parent: "top",
      folding: {
        axis: "x",
        angle: 90,
        step: 8,
      },
    },
    front_left_flap: {
      parent: "front",
      folding: {
        axis: "y",
        angle: 90,
        step: 6,
      },
    },
    front_right_flap: {
      parent: "front",
      folding: {
        axis: "y",
        angle: -90,
        step: 6,
      },
    },
    glue_flap: {
      parent: "front",
      folding: {
        axis: "x",
        angle: 90,
        step: 8,
      },
    },
  },
} as const
