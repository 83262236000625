import fabric from "editor/src/libs/vendors/Fabric"
import {
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import {
  MaskBorderRadius,
  MaskOffset,
  MaskPosition,
  MaskScale,
  MaskSize,
  DEFAULT_MINIMAL_MARGIN,
} from "../types"
import { MaskParentCalculator } from "./mask-parent.calculator"
import { RotationHelper } from "../../helpers/rotation.helper"

export class MaskCalculator {
  private maskParentCalculator: MaskParentCalculator

  constructor(
    private readonly maskParent: PackhelpObject,
    private readonly mask: PackhelpMaskObject
  ) {
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)
  }

  public calcPosition(maskOffset: MaskOffset): MaskPosition {
    const maskParentPosition = this.maskParentCalculator.calcPosition()
    const maskParentRotation = this.maskParentCalculator.calcRotation()

    const strokeWidth = this.mask.strokeWidth || 0
    const left = maskParentPosition.left + maskOffset.offsetW - strokeWidth / 2
    const top = maskParentPosition.top + maskOffset.offsetH - strokeWidth / 2

    const objectOriginPoint = new fabric.Point(left, top)
    const center = new fabric.Point(
      maskParentPosition.left,
      maskParentPosition.top
    )
    const newPosition = RotationHelper.rotatePoint(
      objectOriginPoint,
      center,
      maskParentRotation
    )

    return {
      left: newPosition.x,
      top: newPosition.y,
    }
  }

  public calcScale(
    maskOffset: MaskOffset,
    minimalMargin = DEFAULT_MINIMAL_MARGIN
  ): MaskScale {
    const maskParentSize = this.maskParentCalculator.calcSize()
    const offsetW = Math.max(maskOffset.offsetW, minimalMargin)
    const offsetH = Math.max(maskOffset.offsetH, minimalMargin)

    const newSize = {
      width: maskParentSize.width + offsetW,
      height: maskParentSize.height + offsetH,
    }

    const scaleX = newSize.width / this.mask.width!
    const scaleY = newSize.height / this.mask.height!

    if (this.mask.keepRatio) {
      const scale = Math.max(scaleX, scaleY)

      return {
        scaleX: scale,
        scaleY: scale,
      }
    }

    return {
      scaleX,
      scaleY,
    }
  }

  public calcSize(): MaskSize {
    const strokeWidth = this.mask.strokeWidth || 0

    return {
      width: this.mask.getScaledWidth() - strokeWidth,
      height: this.mask.getScaledHeight() - strokeWidth,
    }
  }

  public calcBorderRadius(): MaskBorderRadius {
    if (!this.mask.borderRadius) {
      return {
        rx: 0,
        ry: 0,
      }
    }

    return {
      rx: this.mask.borderRadius / this.mask.scaleX!,
      ry: this.mask.borderRadius / this.mask.scaleY!,
    }
  }

  public calcMaskParentAndMaskSizeDiff(): MaskOffset {
    const maskSize = this.calcSize()
    const maskParentSize = this.maskParentCalculator.calcSize()

    const offsetW = (maskSize.width - maskParentSize.width) / -2
    const offsetH = (maskSize.height - maskParentSize.height) / -2

    return {
      offsetH,
      offsetW,
    }
  }
}
