import {
  PackhelpGroup,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import {
  SpaceId,
  EditableSpaceConfig,
  NonEditableSpaceConfig,
} from "../../../../../libs/products-render-config/types"
import { isGroup } from "../../../../../types/asset.types"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import { CANVAS_DIM } from "../../../../types"
import { ClippingHelper } from "../assets-module/helpers/clipping.helper"
import { RotationHelper } from "../assets-module/helpers/rotation.helper"

export class SpaceClippingController {
  constructor(
    private readonly dielineNavigator: DielineNavigator,
    private readonly objectToClip: PackhelpObject
  ) {}

  public getClipSpaceIds(): SpaceId[] {
    if (!this.clipPath) {
      return []
    }

    return this.clipPath
      .getObjects()
      .map((object) => object.id)
      .filter((id) =>
        Object.values(SpaceId).some((spaceId) => spaceId === id)
      ) as SpaceId[]
  }

  public async toggleClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    if (this.isClippingActive(spaceConfig.spaceId)) {
      return this.removeClipping(spaceConfig)
    }

    await this.addClipping(spaceConfig)
  }

  public async refreshClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    this.removeClipping(spaceConfig)
    await this.addClipping(spaceConfig)
  }

  private removeClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ) {
    if (!this.clipPath) {
      return
    }

    const spaceIdsToRemove = spaceConfig.children.map(
      (childItem) => childItem.spaceId
    )

    spaceIdsToRemove.push(spaceConfig.spaceId)

    for (const clipPathObject of this.clipPath.getObjects()) {
      if (spaceIdsToRemove.includes(clipPathObject.id as SpaceId)) {
        this.clipPath.remove(clipPathObject)
        this.clipPath.set({
          dirty: true,
        })
      }
    }

    if (this.clipPath.getObjects().length === 0) {
      this.objectToClip.set({
        clipPath: undefined,
      })
    }

    this.objectToClip.set({
      dirty: true,
    })
  }

  private async addClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    const mainSpace = await this.dielineNavigator.cloneSpaceToClipPath(
      spaceConfig.spaceId,
      false,
      false
    )

    const dependantSpaces = await Promise.all(
      spaceConfig.children.map((child) => {
        return this.dielineNavigator.cloneSpaceToClipPath(
          child.spaceId,
          false,
          false
        )
      })
    )

    const spaceClipPathObjects = [mainSpace, ...dependantSpaces]

    if (this.clipPath) {
      RotationHelper.rotateClipPath(
        this.clipPath,
        -this.dielineNavigator.getCurrentRotation()
      )
    } else {
      this.objectToClip.set({
        dirty: true,
        clipPath: ClippingHelper.buildClipPathGroup(),
      })
    }

    this.applySpaceClipObjects(spaceClipPathObjects)

    RotationHelper.rotateClipPath(
      this.clipPath!,
      this.dielineNavigator.getCurrentRotation()
    )
  }

  public isClippingActive(spaceId: SpaceId): boolean {
    if (!this.clipPath) {
      return false
    }

    const spaceClipPath = this.clipPath
      .getObjects()
      .find((obj) => obj.id === spaceId)

    return !!spaceClipPath
  }

  private applySpaceClipObjects(spaceClipPathObjects: PackhelpObject[]) {
    if (!this.clipPath) {
      return
    }

    for (const spaceClipPathObject of spaceClipPathObjects) {
      spaceClipPathObject.set({
        absolutePositioned: true,
        top: spaceClipPathObject.top! - CANVAS_DIM / 2,
        left: spaceClipPathObject.left! - CANVAS_DIM / 2,
      })

      this.clipPath.add(spaceClipPathObject)
    }

    this.objectToClip.set({
      dirty: true,
    })

    this.clipPath.set({
      dirty: true,
    })
  }

  private get clipPath(): PackhelpGroup | undefined {
    const clipPath = this.objectToClip.clipPath as PackhelpObject

    if (!clipPath || !isGroup(clipPath)) {
      return
    }

    return clipPath
  }
}
