import {
  ColorMode,
  EditContext,
  EditorMode,
  ModelContext,
  MonochromeColor,
  RenderConfig,
} from "./types"
import { AvailableProperty, ProductManager } from "@ph/product-api"

export function getEditContextByModelContext(
  modelContext: ModelContext
): EditContext {
  if (modelContext === ModelContext.OPENED) {
    return EditContext.INSIDE
  }

  return EditContext.OUTSIDE
}

export function getModelContextByEditContext(
  editContext: EditContext
): ModelContext {
  if (editContext === EditContext.INSIDE) {
    return ModelContext.OPENED
  }

  return ModelContext.CLOSED
}

export function findProductPropertyBySku<T extends AvailableProperty>(
  properties: T[],
  sku: string
): T | undefined {
  return properties.find((property) => property.sku === sku)
}

export function loadProductConfig(
  product: ProductManager,
  renderConfigMap
): RenderConfig {
  return renderConfigMap[product.variantManager.getSize()] || renderConfigMap
}

export const getTextureConfigCreator =
  (url: string) => (path: string, type: string) => ({
    path: url + path,
    type,
  })

export const bubble = <T>(keys: string[], bubbled: T) => {
  const result: Record<string, T> = {}

  keys.forEach((key) => {
    result[key] = bubbled
  })

  return result
}

interface TabsAvailability {
  logo: boolean
  logoPlaceholder: boolean
  style: boolean
  background: boolean
  patterns: boolean
  eco: boolean
  images: boolean
  text: boolean
  shapes: boolean
  templates: boolean
  materialAndColor: boolean
  replicablePatterns: boolean
  fsc: boolean
  productConfiguration: boolean
}

export const getDbyTabs = (
  isFscAvailable: boolean,
  isBackgroundTabAvailable: boolean,
  isAfterPurchaseEdit?: boolean
): TabsAvailability => {
  return {
    logo: false,
    logoPlaceholder: false,
    style: false,
    background: !!isBackgroundTabAvailable && !isAfterPurchaseEdit,
    patterns: false,
    eco: !isAfterPurchaseEdit,
    images: false,
    text: false,
    shapes: false,
    templates: false,
    materialAndColor: false,
    replicablePatterns: false,
    fsc: isFscAvailable && !isAfterPurchaseEdit,
    productConfiguration: !isAfterPurchaseEdit,
  }
}

export function isFscCertificateAvailable(
  editorMode: EditorMode,
  product: ProductManager
): boolean {
  const isDesignerMode = editorMode === "designer"
  const isFscAvailable = product.variantManager.isFscCertificateAvailable()

  return !isDesignerMode && isFscAvailable
}

export function isMono(colorMode: string): boolean {
  return isMonochrome(colorMode) || isMonoColor(colorMode)
}

export function isMonochrome(colorMode: string): boolean {
  return colorMode === ColorMode.MONOCHROME
}

export function isMonoColor(colorMode: string): boolean {
  return isMonoPantone(colorMode) || isMonoCmyk(colorMode)
}

export function isMonoPantone(colorMode: string): boolean {
  return colorMode === ColorMode.MONO_PANTONE
}

export function isMonoCmyk(colorMode: string): boolean {
  return colorMode === ColorMode.MONO_CMYK
}

export function isMultiColor(colorMode: string): boolean {
  const isFullColor = colorMode === ColorMode.FULL_COLOR
  const isEcoColor = colorMode === ColorMode.ECO_COLOR
  const isMultiCmyk = colorMode === ColorMode.MULTI_CMYK

  return isFullColor || isEcoColor || isMultiCmyk
}

export function isWhite(color?: string): boolean {
  return (
    color === MonochromeColor.WHITE ||
    color === MonochromeColor.WHITE.substr(0, 4)
  )
}

export function isBlack(color?: string): boolean {
  return (
    color === MonochromeColor.BLACK ||
    color === MonochromeColor.BLACK.substr(0, 4)
  )
}
