import React from "react"
import styles from "../Select.module.scss"
import BaseInput from "../../BaseInput/BaseInput"
import { SearchProps } from "../types"

const BaseSearch = (props: SearchProps) => {
  const { children, isOpen, innerRef, ...other } = props
  return (
    <div className={styles["search-wrap"]}>
      <BaseInput innerRef={innerRef} {...other} medium />
      {children}
    </div>
  )
}
const Search = React.forwardRef((props: SearchProps, ref): any => (
  <BaseSearch innerRef={ref} {...props} />
))

export { Search, Search as default }
