import Basil from "shared-libs/src/js/libs/storage/basil.js"
import { trackEvent } from "../analytics/segment/segment-analytics.methods"
import {
  cookieKeyWithServerInstance,
  REGION_COOKIE_NAME,
} from "./cookie.helpers"
import { getRedirectUrlForLanding } from "./region-switcher.helpers"
import RegionConfig from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import { getAnonymousId } from "../services/consent-service"
import { Ga4Service } from "../analytics/google/ga4-service"

export const regionBarCookieHidden = "z_region_bar_hidden"
export const regionBarPageSeen = "region_switcher_page_seen"

export const getPartnerParams = () => {
  const partnerParams = ["gspk", "gsxid", "ref", "ref_token"]
  const URLparams = new URLSearchParams(window.location.search)
  let queryString = ""
  for (const key of partnerParams) {
    const value = URLparams.get(key)
    if (value) {
      queryString += `&${key}=${value}`
    }
  }
  return queryString
}

export const setHideBarCookie = (key: string) => {
  const domain = RegionConfig.getDomainHostnameWithDot()
  Basil.cookie.set(key, true, {
    expireDays: 1,
    domain: domain,
    path: "/",
  })
}

export const setRegionCookie = (region: string) => {
  const domain = RegionConfig.getDomainHostnameWithDot()
  Basil.cookie.set(cookieKeyWithServerInstance(REGION_COOKIE_NAME), region, {
    expireDays: 365,
    domain: domain,
    path: "/",
  })
}

export const getRegionCookie = () => {
  return Basil.cookie.get(cookieKeyWithServerInstance(REGION_COOKIE_NAME))
}

export const getRegionUrlString = (region) => {
  // Prepare pure string of page link without http/https and page links etc.
  const regExpRemoveHttp = /(^\w+:|^)\/\/(w+.)?/
  const regExpRemoveDirectLinks = /\/\b(?!sv-se\b)(?!ro-ro\b)(?!nl-nl\b).+/

  return getRedirectUrlForLanding(region)
    .replace(regExpRemoveHttp, "")
    .replace(regExpRemoveDirectLinks, "")
    .replace(/\/$/, "")
}

export const setRegionCookieAndRedirect = async (
  detectedRegion: string,
  region: string,
  bussinesUnit: string,
  location: string
) => {
  trackEvent({
    event: "Region Detection Modal Country Language Chosen",
    properties: {
      bussines_unit: bussinesUnit,
      location: location,
      region_detected: detectedRegion,
      region_selected: region,
    },
  })

  const partnerParams = getPartnerParams()
  // @ts-ignore
  const anonymousId = getAnonymousId().anonymous_id

  setRegionCookie(region)
  let newLocation = new URL(getRedirectUrlForLanding(region))

  // GA4 section
  await Ga4Service.addGtagDataToUrl(newLocation)

  // if there is anonymousID available - pass it through as URL param
  anonymousId && newLocation.searchParams.set("anonymousId", anonymousId)

  newLocation.searchParams.set("rememberRegion", region)
  window.location.href = `${newLocation.href + partnerParams}`
}

export const setNthPageViewCookie = (key: string) => {
  Basil.cookie.set(key, true)
}

export const getCookieByKey = (key: string) => Basil.cookie.get(key)
