/* TECH-DEBT:
 * This service, types and other stuff (listings, navigation, etc.)
 * really belongs to a pim package that should be created one day.
 * Product-api package should be a base of that package,
 * but it needs to be refactored before we start putting more stuff in.
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

export abstract class PimApi {
  protected readonly ax: AxiosInstance

  constructor(
    protected readonly apiUrl: string,
    protected readonly token?: string
  ) {
    this.ax = this.getAxiosInstance()
  }

  private getAxiosInstance(): AxiosInstance {
    const params: AxiosRequestConfig = {
      baseURL: this.apiUrl,
    }

    if (this.token) {
      params.headers = {
        Authorization: `Bearer ${this.token}`,
      }
    }

    return axios.create(params)
  }
}
