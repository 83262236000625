import * as React from "react"

export const CheckCircle = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.6a9.6 9.6 0 0 0 9.6-9.6 9.6 9.6 0 1 0-9.6 9.6Zm-1.016-7.09 5.87-5.87.906.905-6.776 6.775-4.744-4.744.905-.905 3.84 3.84Z"
      fill="#3B61FF"
    />
  </svg>
)
