import React from "react"
import { formatPriceWithCurrency } from "./format-price-with-currency"

interface FormattedCurrencyProps {
  value: number | string
  currency: string
  e2eTarget?: string
  e2eTargetName?: string
}

export const FormattedCurrency = (props: FormattedCurrencyProps) => {
  const { value, currency, e2eTarget, e2eTargetName } = props

  return (
    <span
      e2e-target={e2eTarget || "money-amount-with-currency-symbol"}
      e2e-target-name={e2eTargetName}
      // e2e-data={_round(value, 2)}
    >
      {formatPriceWithCurrency(value.toString(), currency)}
    </span>
  )
}
