import React from "react"
import cxBinder from "classnames/bind"
import styles from "./PickerColorPalette.module.scss"
import {
  ColorListArray,
  SideEditorColorObject
} from "../PickerColor/PickerColorTypes"

const cx = cxBinder.bind(styles)

export interface PickerColorPaletteProps {
  onColorSelect: (color: SideEditorColorObject) => void
  selectedColor?: string
  colorsList: ColorListArray[]
  analyticsSection?: string
  children?: React.ReactNode
}

const PickerColorPalette = (props: PickerColorPaletteProps) => {
  const getBorderColor = (object: ColorListArray): string | undefined => {
    if (!object.color || object.color === "#FFFFFF") {
      return "#000"
    }

    return object.color
  }

  return (
    <ul className={styles.list} e2e-target="color-picker" e2e-target-name="hex">
      {props.colorsList.map((object) => {
        const selected = props.selectedColor === object.color
        const style = selected
          ? {
              borderColor: getBorderColor(object)
            }
          : {}

        return (
          <li key={object.uid} className={styles.item}>
            <div
              className={cx("color", {
                selected
              })}
              style={style}
              onClick={() => props.onColorSelect(object)}
            >
              <div
                className={cx("color-inner", {
                  transparent: !object.color
                })}
                style={{
                  backgroundColor: object.color
                }}
                e2e-target="button"
                e2e-target-name="color"
                analytics-section={props.analyticsSection}
                analytics-type="picker"
                analytics-name="color"
                analytics-id={object.pantoneName || object.color}
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export { PickerColorPalette, PickerColorPalette as default }
