import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./CanvasObjectContextMenu.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { IconList } from "../../atoms/IconList/IconList"
import { IconListItem } from "../../atoms/IconList/IconListItem"
import { Dropdown } from "../../atoms/Dropdown/Dropdown"
import { DropdownButton } from "../../atoms/Dropdown/DropdownButton"
import { useTranslate } from "../../../hooks/useTranslate"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export const CanvasObjectContextMenu = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { canvasObjectContextMenuUiController } = containerSet.ui
  const {
    popoverPosition,
    items,
    dropdownItems,
    isDropdownOpen,
    activeObjectType,
  } = canvasObjectContextMenuUiController

  if (!popoverPosition) {
    return null
  }

  const { left, top, position } = popoverPosition

  const onHover = () => {
    canvasObjectContextMenuUiController.clearHighlight()
  }

  return (
    <div
      className={cx("wrapper", position)}
      style={{ top, left }}
      e2e-target="canvas-context-menu"
      e2e-target-name={activeObjectType}
      onMouseEnter={onHover}
    >
      <IconList>
        {items.map((item) => (
          <IconListItem
            iconUrl={item.iconUrl}
            key={item.titleIntl}
            label={t(item.titleIntl)}
            onClick={item.onClick}
            size="s"
            analyticsSection="main-canvas-area"
            analyticsType="context-menu"
            analyticsName={item.type}
            e2eTarget="canvas-context-menu-item"
            e2eTargetName={item.type}
          />
        ))}
      </IconList>

      {isDropdownOpen && (
        <div className={styles.dropdown}>
          <Dropdown>
            {dropdownItems.map((item) => (
              <DropdownButton
                key={item.titleIntl}
                iconUrl={item.iconUrl}
                label={t(item.titleIntl)}
                onClick={item.onClick}
                analyticsSection="main-canvas-area"
                analyticsType="context-menu"
                analyticsName={item.type}
                e2eTarget="canvas-context-menu-item"
                e2eTargetName={item.type}
              />
            ))}
          </Dropdown>
        </div>
      )}
    </div>
  )
})
