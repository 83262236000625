import React from "react"
import InputRange from "react-input-range"
import cxBinder from "classnames/bind"
import styles from "./Slider.module.scss"
import "react-input-range/lib/css/index.css"

const cx = cxBinder.bind(styles)

const appropriateLabel = (value: number) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
interface SliderProps {
  min: number
  max: number
  value?: any
  basicLabel?: boolean
  advancedLabel?: boolean
  disabled?: boolean
  onChange?: any
  step?: number
  noMinHeight?: boolean
  hideTrack?: boolean
  bigSlider?: boolean
  children?: React.ReactNode
}

const Slider = (props: SliderProps) => {
  const {
    min,
    max,
    disabled,
    value,
    onChange,
    basicLabel,
    advancedLabel,
    noMinHeight,
    step,
    hideTrack,
    bigSlider
  } = props
  const basicLabelDisabled = !basicLabel && "basic-label-disabled"
  const advancedLabelDisabled = !advancedLabel && "basic-label-disabled"

  return (
    <div className={cx("wrapper")}>
      <InputRange
        maxValue={max}
        minValue={min}
        formatLabel={appropriateLabel}
        value={value}
        onChange={onChange}
        step={step ? step : 1}
        classNames={{
          activeTrack: `input-range__track input-range__track--active ${cx(
            "track",
            { "track--hidden": hideTrack }
          )}`,
          disabledInputRange: "input-range input-range--disabled",
          inputRange: `input-range ${cx("range", {
            "range-disabled": disabled,
            "range-small": noMinHeight
          })}`,
          labelContainer: `input-range__label-container ${styles["label-block"]}`,
          minLabel: `input-range__label input-range__label--min ${cx([
            "min-label",
            basicLabelDisabled
          ])}`,
          maxLabel: `input-range__label input-range__label--max ${cx([
            "max-label",
            basicLabelDisabled
          ])}`,
          slider: `input-range__slider ${cx("dot", { "dot--big": bigSlider })}`,
          sliderContainer: `input-range__slider-container ${styles.container}`,
          track: `input-range__track input-range__track--background ${cx(
            "track-background",
            { "track-background--transparent": hideTrack }
          )}`,
          valueLabel: `input-range__label input-range__label--value ${cx([
            "label",
            advancedLabelDisabled
          ])}`
        }}
      />
    </div>
  )
}

export { Slider, Slider as default }
