import EventEmitter from "eventemitter3"
import FabricAssetLoader from "../../../libs/services/loaders/fabric-asset.loader"
import { VirtualDielineImporter } from "./services/virtual-dieline.importer"
import { VirtualDielineExporter } from "./services/virtual-dieline.exporter"
import {
  isAssetGroup,
  isInteractiveCanvas,
  VirtualDielineDataLessObject,
} from "../../../types/asset.types"
import { Size, TempLayers } from "./types/render-engine.types"
import {
  PackhelpCanvas,
  PackhelpGroup,
  PackhelpImage,
  PackhelpObject,
  VirtualDieline,
} from "./object-extensions/packhelp-objects"
import { DielineNavigator } from "./modules/dieline-navigator/dieline-navigator"
import { SafeZonesModule } from "./modules/safe-zones-module"
import { SpaceHighlightModule } from "./modules/space-highlight-module"
import { GlueStripModule } from "./modules/glue-strip-module"
import { BackgroundsModule } from "./modules/backgrounds.module"
import AssetsModule from "./modules/assets.module"
import {
  EditContext,
  SpaceId,
  ThumbnailConfig,
} from "../../../libs/products-render-config/types"
import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot"
import { SpaceClippingHelper } from "./modules/assets-module/helpers/space-clipping.helper"
import { CanvasObjectControllerFactory } from "./modules/assets-module/canvas-object-controller/canvas-object-controller.factory"
import {
  AllEditorEventsEmitter,
  eventTree,
} from "../../../events/editor.events"
import { DesignVersion } from "../../../types/design.version"
import { LayerHelper } from "./modules/assets-module/helpers/layer.helper"
import { CanvasPreviewPreparator } from "./services/canvas-preview.preparator"
import { NoPrintModule } from "./modules/no-print-module/no-print.module"
import { TempDielineCreator } from "./services/temp-dieline.creator"
import _debounce from "lodash/debounce"
import { DragModeController } from "./modules/dieline-navigator/controllers/drag-mode.controller"
import { CanvasEvent } from "../../../events/partials/domain.events"

export type SceneDimensions = {
  width: number
  height: number
  centerX: number
  centerY: number
}

class VirtualDielineEditor {
  public isEditMode = false
  public fabricCanvas!: PackhelpCanvas
  public productRenderPilot!: ProductRenderPilot
  public dielineNavigator!: DielineNavigator
  public safeZonesModule!: SafeZonesModule
  public spaceHighlightModule!: SpaceHighlightModule
  public glueStripModule!: GlueStripModule
  public noPrintModule!: NoPrintModule
  public backgroundsModule!: BackgroundsModule
  public assetsModule!: AssetsModule

  private isDisposed: boolean = false
  public texture?: PackhelpImage
  public virtualDieline!: VirtualDieline

  private debouncedRefreshDieline = _debounce(this.refreshDieline.bind(this))

  private dragModeController?: DragModeController

  constructor(
    private readonly canvasDimensions: Size,
    public readonly editContext: EditContext,
    //TODO: we should move those events to AllEditorEventsEmitter
    public readonly eventEmitter: EventEmitter,
    public readonly ee: AllEditorEventsEmitter
  ) {
    this.debug(this.editContext)
  }

  public async init({
    fabricCanvas,
    virtualDieline,
    productRenderPilot,
    texture,
  }: {
    fabricCanvas: PackhelpCanvas
    virtualDieline: VirtualDieline
    productRenderPilot: ProductRenderPilot
    texture?: PackhelpImage
  }): Promise<VirtualDielineEditor> {
    this.fabricCanvas = fabricCanvas
    this.isDisposed = false
    this.virtualDieline = virtualDieline
    this.texture = texture
    this.productRenderPilot = productRenderPilot

    this.virtualDieline.set({
      visible: true,
      left: (this.canvasDimensions.width - this.virtualDieline.width!) / 2,
    })
    this.safeZonesModule = new SafeZonesModule(this)
    this.spaceHighlightModule = new SpaceHighlightModule(this)
    this.glueStripModule = new GlueStripModule(this)
    this.noPrintModule = new NoPrintModule(this)
    this.backgroundsModule = new BackgroundsModule(this)
    this.dielineNavigator = new DielineNavigator(
      this,
      this.backgroundsModule,
      this.assetsModule,
      this.productRenderPilot,
      this.editContext
    )
    this.assetsModule = new AssetsModule(this)
    const backgroundTexture =
      await this.backgroundsModule.prepareTextureForThreeDimensionalVisualization()
    this.fabricCanvas.backgroundColor = undefined
    this.addOnCanvas(backgroundTexture)
    this.attachEventListeners()

    this.dragModeController = new DragModeController(this.fabricCanvas)

    return this
  }

  public setCanvasElementSize(size: Size): void {
    this.fabricCanvas.setWidth(size.width)
    this.fabricCanvas.setHeight(size.height)
  }

  public setVirtualDieline(virtualDieline: VirtualDieline) {
    this.virtualDieline = virtualDieline

    this.virtualDieline.set({
      visible: true,
      left: (this.canvasDimensions.width - this.virtualDieline.width) / 2,
    })
  }

  public async prepareEditing(): Promise<void> {
    const contextEditableSpaces =
      this.productRenderPilot.getContextEditableSpaces(this.editContext)

    await Promise.all([
      this.safeZonesModule.createSafeZones(contextEditableSpaces),
      this.spaceHighlightModule.init(contextEditableSpaces),
      this.glueStripModule.createGlueStrip(contextEditableSpaces),
      this.noPrintModule.createNoPrintZone(contextEditableSpaces),
    ])
  }

  public setProductRenderPilot(productRenderPilot: ProductRenderPilot): void {
    this.productRenderPilot = productRenderPilot
    this.dielineNavigator.setProductRenderPilot(productRenderPilot)
  }

  public getCanvasToSceneDiff(): Size {
    const canvasDimensions = this.getCanvasDimensions()
    const sceneDimensions = this.getSceneDimensions()

    return {
      width: Math.max(0, sceneDimensions.width - canvasDimensions.width),
      height: Math.max(0, sceneDimensions.height - canvasDimensions.height),
    }
  }

  public getSceneDimensions(): SceneDimensions {
    let { width, height } = this.canvasDimensions
    const containerElement = this.fabricCanvas.wrapperEl?.parentElement

    if (containerElement) {
      const { width: containerWidth, height: containerHeight } =
        containerElement.getBoundingClientRect()

      /**
       * If container width or height is equal to 0 it means that the canvas is unmounted or in static mode.
       * In this case we need to use default values (e.g. for proper thumb generation).
       */
      if (containerWidth > 0 && containerHeight > 0) {
        width = containerWidth
        height = containerHeight
      }
    }

    return {
      width,
      height,
      centerX: width / 2,
      centerY: height / 2,
    }
  }

  public getEditContext(): EditContext {
    return this.editContext
  }

  public dispose(): void {
    if (!this.isDisposed) {
      this.fabricCanvas.dispose()
      this.isDisposed = true
    }
  }

  public async import(
    designData: VirtualDielineDataLessObject,
    designDataFormatVersion?: DesignVersion
  ): Promise<void> {
    const virtualDielineImporter = new VirtualDielineImporter(this, false)
    await virtualDielineImporter.import(designData, designDataFormatVersion)
  }

  public isSpaceClippingTogglingAvailable(): boolean {
    return (
      this.productRenderPilot.getAvailableSpaces(this.editContext).length > 1
    )
  }

  public async setPreviewMode(): Promise<void> {
    if (!isInteractiveCanvas(this.fabricCanvas)) {
      return
    }

    this.fabricCanvas.selection = false

    for (const object of this.fabricCanvas.getObjects()) {
      object.set({
        selectable: false,
        evented: false,
      })

      if (isAssetGroup(object)) {
        object.set({
          subTargetCheck: false,
        })
      }
    }

    this.assetsModule.set2dInterfaceObjectsVisibility(false)

    this.fabricCanvas.renderAll()
  }

  public async export(): Promise<VirtualDielineDataLessObject> {
    const virtualDielineExporter = new VirtualDielineExporter(this)
    return virtualDielineExporter.export()
  }

  public async showSpace(spaceId: SpaceId): Promise<void> {
    await this.hideDieline()

    this.ee.emit(eventTree.productDriver.showSpaceStarted)

    const isPrintActive = this.productRenderPilot.isPrintActiveFor(
      this.editContext
    )

    this.showCanvas(false)
    this.showGlobalLayers(false)

    this.assetsModule.clearActiveObject()
    await this.evokeAssetsClipPaths()
    await this.dielineNavigator.resetPanning()
    this.assetsModule.set2dInterfaceObjectsVisibility(isPrintActive, spaceId)
    this.assetsModule.showAssetsInSpace(spaceId, isPrintActive)
    this.assetsModule.showAssetsNotInSpace(spaceId, false)
    this.glueStripModule.showGlueStripInSpace(spaceId)
    await this.dielineNavigator.panToSpace(spaceId)

    this.fabricCanvas.renderAll()
    this.showCanvas(true)

    this.ee.emit(eventTree.productDriver.showSpaceEnded, spaceId)
  }

  public async resetDielinePosition(): Promise<void> {
    await this.dielineNavigator.resetPanning()

    const isPrintActive = this.productRenderPilot.isPrintActiveFor(
      this.editContext
    )
    this.assetsModule.setEditableObjectsVisibility(isPrintActive)
    this.assetsModule.set2dInterfaceObjectsVisibility(false)
    this.showGlobalLayers(isPrintActive)

    this.fabricCanvas.renderAll()
  }

  public async enterDragMode(): Promise<void> {
    await this.escapeEditMode()

    this.dragModeController?.start()
  }

  public async escapeDragMode(): Promise<void> {
    await this.enterEditMode()

    this.dragModeController?.stop()

    this.ee.emit(eventTree.productDriver.escapeDragModeEnded)
  }

  public async escapeEditMode(): Promise<void> {
    if (!this.isEditMode || !isInteractiveCanvas(this.fabricCanvas)) {
      return
    }

    this.isEditMode = false
    this.fabricCanvas.selection = false

    this.assetsModule.setEditableObjectsSelectability(false)
    this.assetsModule.clearActiveObject()
    this.switchOffCanvasEventsRegistry()
    this.glueStripModule.hideGlueStripInSpace(
      this.dielineNavigator.getActiveSpaceId()!
    )

    this.ee.emit(eventTree.productDriver.escapeEditModeEnded)
  }

  public async enterEditMode(): Promise<void> {
    if (this.isEditMode || !isInteractiveCanvas(this.fabricCanvas)) {
      return
    }

    this.isEditMode = true
    this.fabricCanvas.selection = true

    this.assetsModule.setEditableObjectsSelectability(true)
    this.spaceHighlightModule.clearHighlight()
    this.switchOnCanvasEventsRegistry()
  }

  private async evokeAssetsClipPaths(): Promise<void> {
    await Promise.all(
      this.assetsModule
        .getEditableObjects()
        .map((object) =>
          SpaceClippingHelper.evokeSpaceClipping(
            this,
            object.originSpaceArea,
            object
          )
        )
    )
  }

  public async showDieline(): Promise<void> {
    this.ee.emit(eventTree.productDriver.showDielineStarted)

    this.showCanvas(false)
    this.showGlobalLayers(false)

    const globalBackground = this.backgroundsModule.getGlobalBackground()
    const { dieline, stroke, clipPath } = await new TempDielineCreator(
      this.getProductVirtualDieline(),
      {
        texture: this.texture,
        background: globalBackground,
      }
    ).call()

    this.fabricCanvas.clipPath = clipPath

    this.addOnCanvas(dieline)
    this.addOnCanvas(stroke)

    this.assetsModule.clearActiveObject()
    this.assetsModule.setEditableObjectsVisibility(true)
    this.assetsModule.set2dInterfaceObjectsVisibility(true)

    this.dielineNavigator.setDielineZoom(1)
    this.dielineNavigator.panToDieline()

    this.showCanvas(true)
    this.showGlobalLayers(true)

    this.ee.on(
      eventTree.productDriver.backgroundChanged,
      this.debouncedRefreshDieline
    )

    this.ee.emit(eventTree.productDriver.showDielineEnded)
  }

  public async refreshDieline(): Promise<void> {
    const currentDieline = this.getCanvasObjectById<PackhelpGroup>(
      TempLayers.TEMP_DIELINE
    )
    const background = this.backgroundsModule.getGlobalBackground()

    if (!currentDieline || !background) {
      return
    }

    const currentRotation = this.dielineNavigator.getCurrentRotation()
    this.dielineNavigator.resetRotation()

    const { dieline } = await new TempDielineCreator(
      this.getProductVirtualDieline(),
      {
        texture: this.texture,
        background,
      }
    ).call()

    this.fabricCanvas.remove(currentDieline)
    this.addOnCanvas(dieline)

    this.dielineNavigator.panToDieline()
    this.dielineNavigator.rotateView(currentRotation)
  }

  public async hideDieline(): Promise<void> {
    const dieline = this.dielineNavigator.getTempDieline()

    if (!dieline) {
      return
    }

    const stroke = this.getCanvasObjectById(TempLayers.TEMP_DIELINE_STROKE)

    await this.dielineNavigator.resetPanning()

    for (const tempObject of [dieline, stroke]) {
      tempObject && this.fabricCanvas.remove(tempObject)
    }

    this.fabricCanvas.renderAll()

    this.ee.off(
      eventTree.productDriver.backgroundChanged,
      this.debouncedRefreshDieline
    )
  }

  private showCanvas(shouldShow: boolean): void {
    if (this.fabricCanvas.wrapperEl) {
      this.fabricCanvas.wrapperEl.style.opacity = shouldShow ? "1" : "0"
    }
  }

  public showGlobalLayers(shouldShow: boolean): void {
    const globalBackground = this.backgroundsModule.getGlobalBackground()
    const globalPattern = this.backgroundsModule.getGlobalPattern()
    const globalBackgroundImage =
      this.backgroundsModule.getGlobalBackgroundImage()

    if (globalBackground) {
      globalBackground.set({
        visible: shouldShow,
        evented: false,
        selectable: false,
      })
    }

    if (globalPattern) {
      globalPattern.set({
        visible: shouldShow,
        evented: false,
        selectable: false,
      })
    }

    if (globalBackgroundImage) {
      globalBackgroundImage.set({
        visible: shouldShow,
        evented: false,
        selectable: false,
      })
    }
  }

  public async setTexture(textureConfig?: { path?: string }): Promise<void> {
    const path = textureConfig?.path

    if (path === this.texture?.getSrc()) {
      return
    }

    this.texture = path ? await FabricAssetLoader.loadAsset(path) : undefined
  }

  public getTexture() {
    return this.texture
  }

  public getCanvasTexture() {
    return this.fabricCanvas.lowerCanvasEl
  }

  /**
   * Heads up, this is CPU intesive function. It consumes ~100ms on M1.
   *
   * We could try to optimize this by calling `decorateSceneWithBackground`
   * on "non-active" VD, but this leads to race conditions and needs further
   * debugging
   *
   * For thumbnails we need background
   * For DTP we don't need that bg. product texture
   */

  public async getCanvasToPreview(
    config: ThumbnailConfig,
    space?: SpaceId
  ): Promise<HTMLCanvasElement> {
    const preparator = new CanvasPreviewPreparator(this)
    return preparator.getCanvasToPreview(config, space)
  }

  public getCanvasDimensions() {
    return this.canvasDimensions
  }

  public getProductVirtualDieline(): VirtualDieline {
    return this.virtualDieline
  }

  public addOnCanvas(object, withRender = true) {
    this.fabricCanvas.add(object)
    this.sortObjectsOnCanvas()

    if (withRender) {
      this.fabricCanvas.renderAll()
    }

    this.eventEmitter.emit("onVirtualDielineObjectAdded", object)
  }

  public sortObjectsOnCanvas() {
    const objects = this.fabricCanvas.getObjects()

    const bottomLayerObjects = LayerHelper.getBottomLayerObjects(objects)
    const middleLayerObjects = LayerHelper.getMiddleLayerObjects(objects)
    const topLayerObjects = LayerHelper.getTopLayerObjects(objects)

    bottomLayerObjects.sort(LayerHelper.compareLayers)
    middleLayerObjects.sort(LayerHelper.compareLayers)
    topLayerObjects.sort(LayerHelper.compareLayers)

    const merged = [
      ...bottomLayerObjects,
      ...middleLayerObjects,
      ...topLayerObjects,
    ]

    merged.forEach((obj, index) => this.fabricCanvas.moveTo(obj, index))

    this.fabricCanvas.getObjects().sort(LayerHelper.compareLayers)
  }

  public getCanvasObjectById<T = PackhelpObject>(id: string): T | undefined {
    return this.fabricCanvas
      .getObjects()
      .find((object) => object.id === id) as T
  }

  public clearEditableObjects() {
    for (const object of this.assetsModule.getEditableObjects()) {
      const objectController = new CanvasObjectControllerFactory(
        this
      ).getController(object)
      objectController.remove()
    }

    this.fabricCanvas.renderAll()
  }

  public rotateView(): void {
    this.assetsModule.clearActiveObject()

    const currentRotation = this.dielineNavigator.getCurrentRotation()
    const newRotation = currentRotation + 90
    const rotation = newRotation === 270 ? -90 : newRotation

    this.dielineNavigator.rotateView(rotation)
    this.fabricCanvas.renderAll()
  }

  public resetRotation(): void {
    this.dielineNavigator.rotateView(0)
    this.fabricCanvas.renderAll()
  }

  public zoomDieline(zoom: number): void {
    this.dielineNavigator.setDielineZoom(zoom)
    this.dielineNavigator.zoomToDieline()
  }

  private switchOnCanvasEventsRegistry() {
    this.assetsModule.switchOnAssetsModuleEvents()
    this.safeZonesModule.switchOnEvents()
    this.glueStripModule.switchOnEvents()
    this.noPrintModule.switchOnEvents()
  }

  private switchOffCanvasEventsRegistry() {
    this.assetsModule.switchOffAssetsModuleEvents()
    this.safeZonesModule.switchOffEvents()
    this.glueStripModule.switchOffEvents()
    this.noPrintModule.switchOffEvents()
  }

  private attachEventListeners(): void {
    this.fabricCanvas.on(CanvasEvent.mouseWheel, ({ e }: { e: WheelEvent }) => {
      if (this.dielineNavigator.isDielineZoomActive) {
        this.assetsModule.clearActiveObject()
        this.dielineNavigator.moveDieline(-e.deltaX, -e.deltaY)
      }
    })

    const parentElement = this.fabricCanvas?.wrapperEl?.parentElement

    if (parentElement) {
      new ResizeObserver((entries) => {
        const entry = entries[0]

        if (entry) {
          this.dielineNavigator.onResize()
          this.eventEmitter.emit("vdEditorResized")
        }
      }).observe(parentElement)
    }
  }

  private debug(context): void {
    if (!globalThis.virtualDielineEditor) {
      globalThis.virtualDielineEditor = {}
    }

    if (!globalThis.virtualDielineEditor[context]) {
      globalThis.virtualDielineEditor[context] = this
    }
  }
}

export default VirtualDielineEditor
