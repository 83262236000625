import { EditableSpaceConfig, EditContext } from "../types"
import {
  EditContextConfig,
  PrintCoverage,
  ProductManager,
} from "@ph/product-api"

function getAvailablePrintingModes(product: ProductManager): string[] {
  return product.addonsManager
    .getAvailablePrintingModes()
    .map((mode) => mode.name)
}

export function isPrintAdditionallyPaidFor(
  product: ProductManager,
  editContext: EditContext
): boolean {
  const availableModes = getAvailablePrintingModes(product)

  if (editContext === EditContext.INSIDE) {
    return availableModes.includes(PrintCoverage.Outside)
  }

  if (editContext === EditContext.BACK) {
    return availableModes.includes(PrintCoverage.Front)
  }

  return false
}

export function isPrintAvailableFor(
  product: ProductManager,
  editContext: EditContext,
  isDby: boolean
): boolean {
  if (!isDby && !isEditContextConfigAvailable(product, editContext)) {
    return false
  }

  const modes = getAvailablePrintingModes(product)

  switch (editContext) {
    case EditContext.INSIDE:
      return modes.includes(PrintCoverage.OutsideInside)
    case EditContext.BACK:
      return modes.includes(PrintCoverage.FrontBack)
    default:
      return [
        EditContext.OUTSIDE,
        EditContext.FRONT,
        EditContext.SLEEVE,
      ].includes(editContext)
  }
}

export function isEditContextConfigAvailable(
  product: ProductManager,
  editContext: EditContext
): boolean {
  const variant = product.getDefaultVariant()
  const renderConfig = variant.editorAssets.renderConfig
  const editContexts = renderConfig?.editContexts

  if (!editContexts) {
    return false
  }

  return renderConfig.editContexts.some(({ name }) => name === editContext)
}

export function buildEditContextsMap(
  editContexts: EditContextConfig[],
  isChildDisabled: boolean
): {
  [key in EditContext]?: EditableSpaceConfig[]
} {
  const map = {}

  for (const editContext of editContexts) {
    const config: EditableSpaceConfig[] = []

    for (const space of editContext.spaces) {
      config.push({
        spaceId: space.name,
        rotation: space.rotation,
        isDefault: space.default,
        children: space.children.map((child) => ({
          spaceId: child,
          context: editContext.name as EditContext,
          children: [],
          disabled: isChildDisabled,
        })),
        context: editContext.name as EditContext,
        editable: space.editable,
      })
    }

    map[editContext.name] = sortSpaceConfigs(config)
  }

  return map
}

function sortSpaceConfigs(
  configs: EditableSpaceConfig[]
): EditableSpaceConfig[] {
  const sortedSpaceIds = [
    "default",
    "top",
    "front",
    "left",
    "right",
    "bottom",
    "back",
    "front_inside",
  ]

  return [...configs].sort(
    (a, b) =>
      sortedSpaceIds.indexOf(a.spaceId) - sortedSpaceIds.indexOf(b.spaceId)
  )
}
