// import React from "react"
import { useIntl } from "react-intl"
import { useIntlImperative } from "../../libs/i18n/use-imperative-intl"

import type { IntlShape } from "react-intl"

/**
 * WARNING: DO NOT REPEAT THIS PATTERN
 * DANGER ZONE: DO NOT REPEAT THIS PATTERN
 *
 * This try/catch is violation of the rules of hooks and react core.
 *
 * But here it is fine as it SHOULD work :D
 *
 * May break in the future though.
 * But can be EASILY fixed. Just wrap SPA mount points
 * with AsyncIntContainer.
 */
export function useIntlViaContextOrImperative(): IntlShape | null {
  try {
    return useIntl()
  } catch (e) {
    return useIntlImperative()
  }
}

type OriginalTranslate = IntlShape["formatMessage"]

function mockTranslate(
  descriptor: Parameters<OriginalTranslate>[0],
  values?: Parameters<OriginalTranslate>[1]
): string {
  // @ts-ignore
  return descriptor.id
}

/**
 * Leave this code for now.
 * There might be issues with the new approach.
 */
export const useFormatMessage = () => {
  const intl = useIntlViaContextOrImperative()
  if (intl != null) return intl.formatMessage

  // fallback for loading state that should never happen
  // throw new Error("should never happen")
  return mockTranslate
}

type TranslationValue = string | number // add more if necessary
export type TranslationValues = { [key: string]: TranslationValue }
export type TranslateFunction = (
  translationId: string,
  values?: TranslationValues | undefined,
  pluralNumber?: number | undefined
) => string

export const useTranslate = (): TranslateFunction => {
  const intl = useIntlViaContextOrImperative()

  // @ts-expect-error
  if (intl == null) return mockTranslate
  return function (
    translationId: string,
    values?: TranslationValues,
    pluralNumber?: number
  ) {
    if (!translationId) return ""

    if (pluralNumber) {
      const pluralType = intl.formatPlural(pluralNumber, { type: "ordinal" })
      return intl.formatMessage(
        { id: `${translationId}.${pluralType}` },
        values
      )
    }
    return intl.formatMessage({ id: translationId }, values)
  }
}
