import { GetItemPropsOptions } from "downshift"
import { BadgeColorTypes } from "../Badge/Badge"
import React from "react"

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type DivWitoutSize = Omit<React.HTMLProps<HTMLDivElement>, "size">

export enum SelectSizes {
  xsmall = "xsmall",
  small = "small",
  medium = "medium",
  large = "large"
}

export enum SelectDirection {
  up = "up",
  down = "down"
}
export interface SelectItemData {
  data?: any
  name: string
  tag?: React.ReactNode
  tagColor?: BadgeColorTypes
  description?: string
  thumb?: string
  id: any
  specialTagColor?: BadgeColorTypes
  specialTag?: React.ReactNode
  itemFooter?: React.ReactNode
}

export interface SelectCommonProps extends DivWitoutSize {
  children?: never
  disabled?: boolean
  validation?: any
  selectedItem?: SelectItemData | null
  handleSelectedItemChange?: any
  isOpen?: boolean
  handleIsOpenChange?: any
  hideInput?: boolean
  inputValue?: string
  handleInputValueChange?: any
  placeholder?: string
  searchPlaceholder?: string
  renderMenuHeader?: () => JSX.Element
  renderMenuFooter?: () => JSX.Element
  disableNoResultsIndicator?: boolean
  autoWidth?: boolean
  searchSelect?: boolean
  matchSorter?(items: SelectItemData[], filter: any): SelectItemData[]
  onOpenMenuCallback?: () => void
  renderSearch?(
    searchRef: any,
    disabled?: boolean,
    getInputProps?: any,
    onFocus?: () => any,
    onClick?: () => any,
    isOpen?: boolean
  ): any
  stateReducer?(state: any, changes: any): any
  withPulsatingGlow?: boolean
  withError?: boolean
  analyticsSection?: string
  analyticsType?: string
  analyticsName?: string
  analyticsId?: string
}

export interface SelectBaseProps extends SelectCommonProps {
  size?: SelectSizes
  direction?: SelectDirection
  renderItem?: (item: SelectItemData, isSelected?: boolean) => any
  autocomplete?: boolean
  items: SelectItemData[]
  withMenuFluid?: boolean
  withMobileMenuFluid?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  itemsListWrapperId?: string
  inputValueBasedItemIndex?: number
  onSortItems?: (items: SelectItemData[]) => void
  shouldSort?: boolean
  hideMenu?: boolean
  noBackground?: boolean
}

export interface BaseMenuProps {
  items: SelectItemData[]
  getMenuProps: (params?: object) => {}
  highlightedIndex: number | null
  getItemProps: (options: GetItemPropsOptions<any>) => {}
  inputValue?: string | null
  selectedItem?: SelectItemData | null
  isOpen?: boolean
  itemsListWrapperId?: string
  inputValueBasedItemIndex?: number
}

export interface MenuProps
  extends React.HTMLProps<HTMLUListElement>,
    Omit<BaseMenuProps, "getMenuProps"> {
  isSelected?: boolean
  autocomplete?: boolean
  renderSearch?(searchRef: any, disabled?: boolean, getInputProps?: any): any
  searchRef: any
  innerRef: any
  menuRef: any
  disabled?: boolean
  getInputProps: any
  searchPlaceholder?: string
  disableNoResultsIndicator?: boolean
  renderItem(item: SelectItemData, isSelected?: boolean): any
  defaultHighlightedIndex: any
  selectedItem: SelectItemData
  renderMenuHeader?: () => JSX.Element
  renderMenuFooter?: () => JSX.Element
  direction?: SelectDirection
  withMenuFluid?: boolean
  withMobileMenuFluid?: boolean
  autoWidth?: boolean
  e2eTarget?: string
  hideMenu?: boolean
}

export interface SearchProps extends React.HTMLProps<HTMLInputElement> {
  isOpen?: boolean
  innerRef?: any
}

export interface ItemProps extends React.HTMLProps<HTMLLIElement> {
  isSelected?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  children?: React.ReactNode
}

export interface SelectProps extends SelectCommonProps {
  size?: SelectSizes
  items: SelectItemData[]
  withMenuFluid?: boolean
  renderItem?(item: SelectItemData, isSelected?: boolean): any
  e2eTarget?: string
  e2eTargetName?: string
  hideMenu?: boolean
}
