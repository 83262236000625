import { useIntlImperative } from "shared-libs/src/js/libs/i18n/use-imperative-intl"

export const useFormatMessage = () => {
  const intl = useIntlImperative()
  if (intl == null) return (id: string) => id
  return intl.formatMessage
}

export const useTranslate = () => {
  const intl = useIntlImperative()
  if (intl == null) return (id: string) => id

  return function (translationId: string, values?: any) {
    return intl.formatMessage({ id: translationId }, values)
  }
}
