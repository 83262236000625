import { PimApi } from "../../pim-services/pim.api"
import {
  ListingApiResponse,
  ListingDataPayload,
} from "../utils/listing-services.types"

export class ListingApiService extends PimApi {
  constructor(directusUrl: string, token?: string) {
    const apiUrl = new URL("listings", directusUrl).toString()

    super(apiUrl, token)
  }

  public async getDataForParameters(
    payload: ListingDataPayload
  ): Promise<ListingApiResponse> {
    const response = await this.ax.get("/listing", { params: payload })

    return response.data
  }
}
