import {
  SpaceId,
  ThumbnailConfig,
  ViewMode,
} from "../../../../libs/products-render-config/types"
import VirtualDielineEditor from "../virtual-dieline-editor"
import { CANVAS_DIM } from "../../../types"
import { VirtualDielineExporter } from "./virtual-dieline.exporter"
import { ImageLoader } from "../../../../libs/services/loaders/image.loader"
import FiltersModule from "../modules/filters.module"
import Colour from "../../../../models/colour"
import FabricAssetLoader from "../../../../libs/services/loaders/fabric-asset.loader"

type CanvasPreviewPreparatorConfig = {
  enableRetinaScaling: boolean
}

export class CanvasPreviewPreparator {
  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly config: CanvasPreviewPreparatorConfig = {
      enableRetinaScaling: true,
    }
  ) {}

  public async getCanvasToPreview(
    config: ThumbnailConfig = {
      backgroundTextureEnabled: true,
      editZoneBackgroundTextureEnabled: false,
    },
    space?: SpaceId
  ): Promise<HTMLCanvasElement> {
    const { productRenderPilot } = this.vdEditor
    const clonedVdEditor = await this.cloneVdEditor()

    if (productRenderPilot.isViewModeAvailable(ViewMode.THREE_DIMENSIONAL)) {
      return this.getModelPreview(clonedVdEditor)
    }

    if (productRenderPilot.is3DProduct()) {
      return this.getDielinePreview(clonedVdEditor)
    }

    return this.getSpacePreview(clonedVdEditor, config, space)
  }

  private getModelPreview(vdEditor: VirtualDielineEditor): HTMLCanvasElement {
    this.hideInterfaceObjects(vdEditor)

    return vdEditor.getCanvasTexture()
  }

  private async getDielinePreview(
    vdEditor: VirtualDielineEditor
  ): Promise<HTMLCanvasElement> {
    this.hideInterfaceObjects(vdEditor)
    await vdEditor.showDieline()

    return vdEditor.getCanvasTexture()
  }

  private async getSpacePreview(
    vdEditor: VirtualDielineEditor,
    config: ThumbnailConfig = {
      backgroundTextureEnabled: true,
      editZoneBackgroundTextureEnabled: false,
    },
    space?: SpaceId
  ): Promise<HTMLCanvasElement> {
    const { productRenderPilot } = this.vdEditor

    if (
      config.backgroundTextureEnabled ||
      config.editZoneBackgroundTextureEnabled
    ) {
      await vdEditor.showSpace(
        space || productRenderPilot.getDefaultSpace(this.vdEditor.editContext)
      )
    }

    if (!config.editZoneBackgroundTextureEnabled) {
      vdEditor.dielineNavigator.removeTempBackground()
    }

    this.hideInterfaceObjects(vdEditor)
    const canvas = vdEditor.getCanvasTexture()

    if (config.backgroundTextureEnabled) {
      await this.decorateCanvasWithBackgroundTexture(vdEditor, canvas)
    }

    return canvas
  }

  private hideInterfaceObjects(clonedVdEditor: VirtualDielineEditor) {
    clonedVdEditor.spaceHighlightModule.clear()
    clonedVdEditor.assetsModule.set2dInterfaceObjectsVisibility(false)
    clonedVdEditor.fabricCanvas.renderAll()
  }

  private async decorateCanvasWithBackgroundTexture(
    clonedVdEditor: VirtualDielineEditor,
    canvas: HTMLCanvasElement
  ) {
    const ctx = canvas.getContext("2d")

    if (!ctx) {
      return
    }

    const bgTexture = await this.getBackgroundTexture(clonedVdEditor)

    if (!bgTexture) {
      return
    }

    const canvasPreview = await ImageLoader.loadImage(
      canvas.toDataURL("image/png")
    )

    let bgTextureWidth, bgTextureHeight
    const bgTextureRatio = bgTexture.width / bgTexture.height

    if (bgTextureRatio >= 1) {
      bgTextureWidth = CANVAS_DIM
      bgTextureHeight = CANVAS_DIM / bgTextureRatio
    } else {
      bgTextureHeight = CANVAS_DIM
      bgTextureWidth = CANVAS_DIM * bgTextureRatio
    }

    ctx.drawImage(
      bgTexture,
      (CANVAS_DIM - bgTextureWidth) / 2,
      (CANVAS_DIM - bgTextureHeight) / 2,
      bgTextureWidth,
      bgTextureHeight
    )
    ctx.drawImage(canvasPreview, 0, 0, CANVAS_DIM, CANVAS_DIM)
  }

  private async getBackgroundTexture(
    clonedVdEditor: VirtualDielineEditor
  ): Promise<HTMLImageElement | undefined> {
    const bgTextureUrl =
      clonedVdEditor.productRenderPilot.getSceneBackgroundTextureUrl(
        clonedVdEditor.editContext
      )

    if (!bgTextureUrl) {
      return
    }

    const space = clonedVdEditor.dielineNavigator.getActiveVirtualDielineSpace()
    const color = space?.fill ? new Colour(space.fill as string) : undefined

    if (!color) {
      return ImageLoader.loadImage(bgTextureUrl)
    }

    const bgTexture = await FabricAssetLoader.loadAsset(bgTextureUrl, {})

    FiltersModule.setBlendColorFilter(bgTexture, color)

    return ImageLoader.loadImage(bgTexture.toDataURL())
  }

  private async cloneVdEditor(): Promise<VirtualDielineEditor> {
    const virtualDielineExporter = new VirtualDielineExporter(this.vdEditor)

    return await virtualDielineExporter.cloneVd(this.config)
  }
}
