import type { FscCertificateStore } from "../stores/fsc-certificate.store"
import type { FscCertificateUiController } from "../controllers/fsc-certificate-ui.controller"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap.container"
import type { ApiSessionContainer } from "./api-session.container"
import type { EcommerceMaybeContainer } from "./ecommerce-maybe.container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import { UtilEnvContainer } from "./util.container"

async function importDeps() {
  const { CertificateManager } = await import(
    "../services/managers/certificate.manager"
  )
  const { FscCertificateStore } = await import(
    "../stores/fsc-certificate.store"
  )
  const { FscCertificateUiController } = await import(
    "../controllers/fsc-certificate-ui.controller"
  )

  return {
    CertificateManager,
    FscCertificateStore,
    FscCertificateUiController,
  }
}

export async function provideFscCertificateMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  designAndProduct: DesignAndProductDriverContainer,
  apiSessionContainer: ApiSessionContainer,
  ecommerceContainer: EcommerceMaybeContainer
): Promise<FscCertificateMaybeContainer> {
  const { productRenderPilot } = rootBootstrapper
  const { appConfig } = utilEnvContainer

  if (
    !ecommerceContainer.available ||
    !appConfig.api.ecommerce.features.ecommerce.fsc
  ) {
    return {
      available: false,
      fscCertificateStore: undefined,
      fscCertificateUiController: undefined,
    }
  }

  const {
    CertificateManager,
    FscCertificateStore,
    FscCertificateUiController,
  } = await importDeps()
  const { apiService, directusApiService } = apiSessionContainer
  const { taxStore, productPricingStore } = ecommerceContainer
  const { productDesignStore, productDriver } = designAndProduct
  const { ee, uri } = utilEnvContainer

  const certificateManager = new CertificateManager(
    apiService,
    directusApiService,
    productDriver
  )
  const fscCertificateStore = new FscCertificateStore(
    certificateManager,
    ee,
    appConfig.locale.region,
    {
      taxStore,
      productPricingStore,
      productDesignStore,
    }
  )

  if (productRenderPilot.uiConfig.features.fsc) {
    fscCertificateStore.init(productDriver.productStore.product)
  }

  const fscCertificateUiController = new FscCertificateUiController(
    {
      productDriver,
      uri,
      ee,
    },
    {
      fscCertificateStore,
      productDesignStore,
    }
  )

  return {
    available: true,
    fscCertificateStore,
    fscCertificateUiController,
  }
}

export type FscCertificateMaybeContainer =
  | {
      available: true
      fscCertificateStore: FscCertificateStore
      fscCertificateUiController: FscCertificateUiController
    }
  | {
      available: false
      fscCertificateStore: undefined
      fscCertificateUiController: undefined
    }
