import { action, observable, observe, makeObservable } from "mobx"
import { Colour } from "../models/colour"
import { Pattern } from "../models/pattern"
import { ee, eventTree } from "../events/editor.events"
import ProductDriver from "./product.driver"
import { EditContext, SpaceId } from "../libs/products-render-config/types"
import { PackhelpObject } from "../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { ImageAsset } from "../models/image-asset"
import { ImageCreator } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-creator/image.creator"
import VirtualDielineEditor from "../render-engine/modules/vd-editor/virtual-dieline-editor"
import { AssetColorController } from "../render-engine/modules/vd-editor/modules/assets-module/asset-color.controller"

type BackgroundColors = {
  [key in EditContext]?: Colour | null
}

type BackgroundImages = {
  [key in EditContext]?: PackhelpObject | null
}

class BackgroundsDriver {
  @observable public backgroundColor: BackgroundColors | {} = observable({})
  @observable public backgroundImage: BackgroundImages | {} = observable({})
  @observable public editedSpaceColor?: Colour
  @observable public isPatternVisibleOnEditedSpace: boolean = false
  @observable public isBackgroundImageVisibleOnEditedSpace: boolean = false

  constructor(private readonly productDriver: ProductDriver) {
    makeObservable(this)
    observe(this.productDriver.state, (change) => {
      if (change.name === "activeSpace" && change.object.activeSpace) {
        const color = this.getSpaceColor(change.object.activeSpace)
        this.setEditedSpaceColor(color)
        this.setEditedSpacePatternVisibility(change.object.activeSpace)
        this.setEditedSpaceBackgroundImageVisibility(change.object.activeSpace)
      }
    })

    this.initListeners()
  }

  private initListeners() {
    if (!this.productDriver.state.productRenderPilot.isDbyMode()) {
      ee.on(eventTree.productDriver.productChanged, this.refreshAll.bind(this))
    }
    ee.on(
      eventTree.patterns.configChanged,
      this.handlePatternConfigChanged.bind(this)
    )
    ee.on(eventTree.templates.selected, this.refreshAll.bind(this))
    ee.on(eventTree.templates.removed, this.refreshAll.bind(this))
    ee.on(eventTree.templates.designerModeLoaded, this.refreshAll.bind(this))
  }

  @action
  public init() {
    this.refreshBackgroundColorState()
    this.refreshBackgroundImageState()
  }

  private async refreshAll() {
    this.refreshBackgroundColorState()
    this.refreshBackgroundImageState()
    await this.refreshBackgroundLayersAndEditableObjects()
  }

  private async refreshBackgroundLayersAndEditableObjects(): Promise<void> {
    const { renderEngine, activeSpace, productRenderPilot } = this.getState()
    const is3dView = !activeSpace

    for (const vdEditor of renderEngine!.getVirtualDielineEditors()) {
      const isPrintActive = productRenderPilot.isPrintActiveFor(
        vdEditor.editContext
      )
      vdEditor.assetsModule.setEditableObjectsVisibility(isPrintActive)
      if (is3dView) {
        vdEditor.assetsModule.set2dInterfaceObjectsVisibility(false)
      }
      vdEditor.showGlobalLayers(!activeSpace && isPrintActive)

      await vdEditor.dielineNavigator.refreshTempBackgroundImageOnActiveSpace()
    }

    renderEngine!.rerender()

    ee.emit(eventTree.productDriver.backgroundLayersRefreshed)
  }

  @action
  private refreshBackgroundColorState() {
    const { renderEngine } = this.getState()
    renderEngine!.getVirtualDielineEditors().forEach((virtualDielineEditor) => {
      const editContext = virtualDielineEditor.getEditContext()
      const background =
        virtualDielineEditor.backgroundsModule.getSpaceBackground(
          this.productDriver.state.productRenderPilot.getDefaultSpace(
            editContext
          )
        )

      if (background) {
        this.setBackgroundColor(editContext, background)
      }
    })
  }

  @action
  private refreshBackgroundImageState() {
    const { renderEngine } = this.getState()
    renderEngine!.getVirtualDielineEditors().forEach((vdEditor) => {
      const backgroundImage =
        vdEditor.backgroundsModule.getGlobalBackgroundImage()
      this.setBackgroundImage(vdEditor.editContext, backgroundImage)
    })
  }

  @action
  private async handlePatternConfigChanged(patterns: Pattern[]) {
    const { renderEngine } = this.getState()
    const virtualDielineEditor = renderEngine!.getVirtualDielineEditor(
      this.productDriver.state.productRenderPilot.getDefaultEditContext()
    )
    const fabricGlobalPattern =
      virtualDielineEditor.backgroundsModule.getGlobalPattern()

    if (!fabricGlobalPattern) {
      ee.emit(eventTree.patterns.configChangeHandled)

      return
    }

    const { patternSourceConfig } = fabricGlobalPattern

    if (typeof patternSourceConfig === "undefined") {
      throw new Error("Retrived canvas object is not a pattern")
    }

    const currentlyAppliedPattern = patterns.find(
      (pattern) => pattern.id === patternSourceConfig.id
    )

    if (!currentlyAppliedPattern) {
      throw new Error(`Cannot match pattern with id:${patternSourceConfig.id}`)
    }

    await this.addPattern(currentlyAppliedPattern)

    ee.emit(eventTree.patterns.configChangeHandled)
  }

  @action
  public async togglePatternVisibilityOnSpace(spaceId: SpaceId): Promise<void> {
    const { renderEngine, productRenderPilot, activeContext } = this.getState()
    this.productDriver.setDesignTouched(true)
    const spaceConfig = productRenderPilot.getSpaceConfig(
      activeContext,
      spaceId
    )

    if (!spaceConfig) {
      return
    }

    const virtualDielineEditor =
      renderEngine!.getVirtualDielineEditor(activeContext)
    await virtualDielineEditor.backgroundsModule.togglePatternVisibilityOnSpace(
      spaceConfig
    )
    this.setIsPatternVisibleOnEditedSpace(!this.isPatternVisibleOnEditedSpace)
    virtualDielineEditor.fabricCanvas.renderAll()
  }

  public async applyBackgroundImage(
    editContext: EditContext,
    imageAsset: ImageAsset
  ): Promise<void> {
    const { renderEngine, productRenderPilot } = this.getState()

    if (!renderEngine) {
      return
    }

    const vdEditor = renderEngine.getVirtualDielineEditor(editContext)
    const { backgroundsModule } = vdEditor

    const imageCreator = new ImageCreator(
      vdEditor,
      productRenderPilot.getDefaultSpace(editContext)
    )
    const backgroundImage = await imageCreator.create(imageAsset)

    await backgroundsModule.applyBackgroundImage(backgroundImage)
    this.setBackgroundImage(editContext, backgroundImage)

    vdEditor.fabricCanvas.renderAll()
    renderEngine.touchModelTextures()
    this.productDriver.setDesignTouched(true)
  }

  public async removeBackgroundImage(editContext: EditContext) {
    const { renderEngine } = this.getState()

    if (!renderEngine) {
      return
    }

    const vdEditor = renderEngine.getVirtualDielineEditor(editContext)
    await vdEditor.backgroundsModule.removeBackgroundImage()
    this.setBackgroundImage(editContext, null)

    renderEngine.touchModelTextures()
    this.productDriver.setDesignTouched(true)
  }

  public async toggleBackgroundImageVisibilityOnSpace(
    spaceId: SpaceId
  ): Promise<void> {
    const { renderEngine, productRenderPilot, activeContext } = this.getState()

    if (!renderEngine) {
      return
    }

    const spaceConfig = productRenderPilot.getSpaceConfig(
      activeContext,
      spaceId
    )

    const vdEditor = renderEngine.getVirtualDielineEditor(activeContext)
    await vdEditor.backgroundsModule.toggleBackgroundImageVisibilityOnSpace(
      spaceConfig
    )
    this.setIsBackgroundImageVisibleOnEditedSpace(
      !this.isBackgroundImageVisibleOnEditedSpace
    )
    this.productDriver.setDesignTouched(true)
    vdEditor.fabricCanvas.renderAll()
  }

  @action
  public async addPattern(pattern: Pattern) {
    const { renderEngine, productRenderPilot, activeContext } = this.getState()
    if (!renderEngine) {
      return
    }

    this.productDriver.setDesignTouched(true)

    const vdEditor = renderEngine.getVirtualDielineEditor(
      productRenderPilot.getDefaultEditContext()
    )

    const { backgroundsModule } = vdEditor
    const patternObject = await backgroundsModule.createPattern(pattern)
    await backgroundsModule.applyPattern(patternObject)

    const colorController = new AssetColorController(vdEditor)
    colorController.recolorDielineColorableObjectsIfNeeded(
      colorController.getObjectColor(patternObject as PackhelpObject)
    )

    ee.emit(eventTree.patterns.applied, pattern, activeContext)
    vdEditor.fabricCanvas.renderAll()
    renderEngine.touchModelTextures()
  }

  public isClippingMaskAvailable(editContext: EditContext): boolean {
    return !!this.getVirtualDielineEditor(
      editContext
    )?.backgroundsModule.isClippingMaskAvailable()
  }

  public isGlobalPatternActive(editContext: EditContext): boolean {
    return !!this.getVirtualDielineEditor(
      editContext
    )?.backgroundsModule.isGlobalPatternActive()
  }

  public isGlobalBackgroundImageActive(editContext: EditContext): boolean {
    return !!this.getVirtualDielineEditor(
      editContext
    )?.backgroundsModule.isGlobalBackgroundImageActive()
  }

  public async removePattern(): Promise<void> {
    const { renderEngine } = this.getState()

    if (!renderEngine) {
      return
    }

    const virtualDielineEditor = renderEngine.getVirtualDielineEditor(
      this.productDriver.state.productRenderPilot.getDefaultEditContext()
    )
    await virtualDielineEditor.backgroundsModule.removeGlobalPattern()

    ee.emit(eventTree.patterns.removed)

    renderEngine.touchModelTextures()
  }

  @action
  public async paintProductEditContext(
    colour: Colour | null,
    editContext: EditContext
  ) {
    const { renderEngine } = this.getState()
    const virtualDielineEditor =
      renderEngine!.getVirtualDielineEditor(editContext)

    await virtualDielineEditor.backgroundsModule.applyColorOnProduct(colour)
    this.setBackgroundColor(editContext, colour)
    this.productDriver.setDesignTouched(true)
  }

  @action
  private setEditedSpacePatternVisibility(activeSpace: SpaceId) {
    const { activeContext, renderEngine } = this.getState()
    const virtualDielineEditor =
      renderEngine!.getVirtualDielineEditor(activeContext)
    const isPatternVisibleOnSpace =
      virtualDielineEditor.backgroundsModule.isPatternVisibleOnSpace(
        activeSpace
      )
    this.setIsPatternVisibleOnEditedSpace(isPatternVisibleOnSpace)
  }

  @action
  private setEditedSpaceBackgroundImageVisibility(activeSpace: SpaceId) {
    const { activeContext, renderEngine } = this.getState()
    const virtualDielineEditor =
      renderEngine!.getVirtualDielineEditor(activeContext)
    const isBackgroundImageVisibleOnSpace =
      virtualDielineEditor.backgroundsModule.isBackgroundImageVisibleOnSpace(
        activeSpace
      )

    this.setIsBackgroundImageVisibleOnEditedSpace(
      isBackgroundImageVisibleOnSpace
    )
  }

  public async paintProductCurrentSpace(color: Colour) {
    const { activeContext, activeSpace } = this.getState()
    if (!activeSpace) {
      throw new Error("Not valid operation")
    }
    this.productDriver.setDesignTouched(true)
    this.setEditedSpaceColor(color)
    return this.paintProductSpace(color, { activeSpace, activeContext })
  }

  public async paintProductSpace(
    colour: Colour,
    {
      activeContext,
      activeSpace,
    }: { activeContext: EditContext; activeSpace: SpaceId }
  ) {
    const { renderEngine, productRenderPilot } = this.getState()

    const spaceConfig = productRenderPilot.getSpaceConfig(
      activeContext,
      activeSpace
    )

    if (!spaceConfig) {
      throw new Error(`Not found space with provided id ${activeSpace}`)
    }

    const virtualDielineEditor =
      renderEngine!.getVirtualDielineEditor(activeContext)

    await virtualDielineEditor.backgroundsModule.applyColorOnSpaces(
      spaceConfig,
      colour
    )
  }

  private getSpaceColor(activeSpace: SpaceId): Colour | undefined {
    const { activeContext, renderEngine } = this.getState()
    const virtualDielineEditor =
      renderEngine!.getVirtualDielineEditor(activeContext)

    if (!activeSpace) {
      throw new Error(
        `Operation not available if active space is ${activeSpace}`
      )
    }

    return virtualDielineEditor.backgroundsModule.getSpaceBackground(
      activeSpace
    )
  }

  @action
  private setIsPatternVisibleOnEditedSpace(isVisible: boolean) {
    this.isPatternVisibleOnEditedSpace = isVisible
  }

  @action
  private setIsBackgroundImageVisibleOnEditedSpace(isVisible: boolean) {
    this.isBackgroundImageVisibleOnEditedSpace = isVisible
  }

  @action
  private setBackgroundColor(editContext: EditContext, color: Colour | null) {
    this.backgroundColor[editContext] = color
  }

  @action
  private setBackgroundImage(
    editContext: EditContext,
    backgroundImage?: PackhelpObject | null
  ) {
    this.backgroundImage[editContext] = backgroundImage
  }

  @action
  private setEditedSpaceColor(color?: Colour) {
    this.editedSpaceColor = color
  }

  private getState() {
    return this.productDriver.state
  }

  private getVirtualDielineEditor(
    editContext: EditContext
  ): VirtualDielineEditor | undefined {
    const { renderEngine } = this.getState()

    return renderEngine?.getVirtualDielineEditor(editContext)
  }
}

export default BackgroundsDriver
