import React from "react"
import { ToolbarEditor } from "../../dsl/organisms/toolbars/ToolbarEditor/ToolbarEditor"
import { BackToPreviousViewButton } from "../../dsl/organisms/BackToPreviousViewButton/BackToPreviousViewButton"
import { NotificationsBar } from "../../dsl/organisms/NotificationsBar/NotificationsBar"
import { CameraControls } from "../../dsl/organisms/CameraControls/CameraControls"
import styles from "./SceneUi.module.scss"
import cxBinder from "classnames/bind"
import { AssetPlacementCta } from "../../dsl/molecules/AssetPlacementCta/AssetPlacementCta"

const cx = cxBinder.bind(styles)

export interface SceneUiProps {
  isSelectingAssetPlacement: boolean
}

export function SceneUi({ isSelectingAssetPlacement }: SceneUiProps) {
  return (
    <div className={styles.ui}>
      {isSelectingAssetPlacement ? (
        <div className={styles["floating-cta"]}>
          <AssetPlacementCta />
        </div>
      ) : (
        <>
          <div className={styles.toolbar}>
            <ToolbarEditor />
          </div>
          <div className={styles.nav}>
            <BackToPreviousViewButton />
          </div>
          <div className={styles.notifications}>
            <NotificationsBar />
          </div>
        </>
      )}

      <div className={cx("camera", { disabled: isSelectingAssetPlacement })}>
        <CameraControls />
      </div>
    </div>
  )
}
