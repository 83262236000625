import { UIConfig } from "../types"

export const getUiConfig = (): UIConfig => {
  return {
    features: {
      mask: false,
      textEffects: true,
      templates: false,
      patterns: false,
      replicablePatterns: false,
      plantingTrees: false,
      backgroundColor: false,
      backgroundImage: false,
      fsc: false,
    },
    tabs: {
      product: false,
      configuration: false,
      logo: false,
      logoPlaceholder: false,
      templatesAndPatterns: false,
      replicablePatterns: false,
      text: true,
      textOptions: true,
      fonts: true,
      background: false,
      elements: true,
      certificates: false,
      devTools: false,
      size: false,
      dby: false,
      upload: false,
    },
    fontSize: {
      dtp: {
        min: 14,
      },
      editor: {
        min: 14,
        max: 200,
      },
    },
    editZone: {
      rulers: {
        lines: "none",
        dimensions: false,
      },
      controlsTheme: "dark",
      safeZoneSizeMm: 0,
      zoom: {
        available: false,
        activeByDefault: false,
      },
    },
    globalDropzone: {
      mode: "images",
    },

    /**
     * probably doesn't really apply here
     */
    thumbnail: {
      backgroundTextureEnabled: false,
      editZoneBackgroundTextureEnabled: false,
    },
    bleedSizeMm: 0,
    disclaimers: {
      lightColorsLimitation: false,
      colorsOnKraft: false,
    },
  }
}
