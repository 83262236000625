import React, { useState } from "react"
import { Picker, PickerProps } from "../../molecules/Picker/Picker"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { PantoneColorPreset } from "../../../../libs/products-render-config/types"
import { PickerColorPalette } from "dsl/src/organisms/PickerColorPalette/PickerColorPalette"
import { PantoneColorsDatabase } from "../../../../libs/colors/pantone-colors.database"
import PantoneColorsGroupingProvider from "./PantoneColorsGroupingProvider"
import {
  OnApplyColor,
  PantonePickerColorObject,
  SideEditorColorObject,
} from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { PantonePickerComponent } from "./PantonePickerComponent"
import Colour from "../../../../models/colour"
import { AnalyticsSection } from "../../types"

const i18n = I18N.component.colorPicker

export interface PantonePickerProps {
  onColorSelect: (color: Colour) => void
  selectedColor?: PantonePickerColorObject
  pantoneColorPreset?: PantoneColorPreset
  analyticsSection?: AnalyticsSection
}

function getColorsProvider(pantoneColorPreset: PantoneColorPreset): {
  groupingProvider?: PantoneColorsGroupingProvider
  colorsList?: PantonePickerColorObject[]
} {
  const pantoneDb = new PantoneColorsDatabase()

  if (
    [
      PantoneColorPreset.COVERING_BLACK_FOIL,
      PantoneColorPreset.COVERING_GREY_FOIL,
      PantoneColorPreset.TISSUE_BACKGROUND,
      PantoneColorPreset.PATTERN_REPLICATOR_PALETTE,
    ].includes(pantoneColorPreset)
  ) {
    return {
      colorsList: pantoneDb.getByPreset(pantoneColorPreset),
    }
  }

  return {
    groupingProvider: new PantoneColorsGroupingProvider(
      pantoneDb.getByPreset(pantoneColorPreset)
    ),
  }
}

function PantonePicker({
  selectedColor,
  onColorSelect,
  pantoneColorPreset = PantoneColorPreset.ALL,
  analyticsSection,
}: PantonePickerProps) {
  const t = useTranslate()
  const [isMoreColorsVisible, setIsMoreColorsVisible] = useState<boolean>(false)

  const { groupingProvider, colorsList } = getColorsProvider(pantoneColorPreset)

  const onFooterLinkClick = () => {
    setIsMoreColorsVisible(!isMoreColorsVisible)
  }

  const defaultColorsList =
    colorsList || groupingProvider?.groups.Defaults || []

  const onApplyColor = (pantoneColor: OnApplyColor | SideEditorColorObject) => {
    onColorSelect(new Colour(pantoneColor.color, pantoneColor.pantoneName))
  }

  const link: PickerProps["link"] = !!groupingProvider
    ? {
        label: isMoreColorsVisible ? t(i18n.lessColors) : t(i18n.moreColors),
        onClick: onFooterLinkClick,
        analyticsSection: analyticsSection,
        analyticsType: "button",
        analyticsName: "more-colors-toggle",
        e2eTarget: "button",
        e2eTargetName: isMoreColorsVisible ? "less-colors" : "more-colors",
      }
    : undefined

  return (
    <Picker link={link}>
      {isMoreColorsVisible ? (
        <PantonePickerComponent
          selectedColor={selectedColor}
          onApplyColor={onApplyColor}
          placeholderString={t(i18n.pantone.search)}
          noSearchResultsString={(searchValue) =>
            `${t(i18n.pantone.noResults)} ${searchValue}`
          }
          colorsList={colorsList}
          groupingProvider={groupingProvider}
          analyticsSection={analyticsSection}
        />
      ) : (
        <PickerColorPalette
          selectedColor={selectedColor?.color}
          onColorSelect={onApplyColor}
          colorsList={defaultColorsList}
          analyticsSection={analyticsSection}
        />
      )}
    </Picker>
  )
}

export default PantonePicker
