import React, {
  ChangeEventHandler,
  forwardRef,
  KeyboardEventHandler
} from "react"
import cxBinder from "classnames/bind"
import TextField from "../../../../atoms/TextField/TextField"
import styles from "./PickerOptionsSearch.module.scss"

const cx = cxBinder.bind(styles)

interface PickerOptionsSearchProps {
  value?: string
  placeholder?: string
  isMobile?: boolean
  onChange: (value?: number) => void
  onConfirm?: () => void
  onFocus?: () => void
  onBlur?: () => void
}
export const PickerOptionsSearch = forwardRef<
  HTMLInputElement,
  PickerOptionsSearchProps
>((props, ref): any => {
  const { placeholder, isMobile, value, onChange, onConfirm, onFocus, onBlur } =
    props

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValue = +event.target.value
    if (Number.isNaN(currentValue)) {
      return
    }
    onChange(currentValue)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const isEnter = event.key === "Enter"
    if (isEnter && onConfirm) {
      onConfirm()
    }
  }

  return (
    <div className={cx("search", { "search--mobile": isMobile })}>
      <TextField
        ref={ref}
        value={value}
        placeholder={placeholder}
        pattern="[0-9]*"
        type="tel"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
})
