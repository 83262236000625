import fabric from "../../../../libs/vendors/Fabric"
import { ColourSettings } from "../../../../models/colour"
import { TextConfigParams } from "../../../../models/text"
import { PatternSourceObject } from "../../../../models/pattern"
import { ClipPathModes } from "../services/clip-path.generator"
import {
  FontWeight,
  FontStyle,
  TextAlign,
} from "../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { Fonts } from "../../../../libs/services/fonts-loader-service/fonts-loader.config"
import { MaskController } from "../modules/assets-module/mask-controller/mask.controller"
import { ShapeConfigParams } from "../../../../models/shape"
import {
  MaskConfigExport,
  MaskType,
} from "../modules/assets-module/mask-controller/types"
import { EditableGroupController } from "../modules/assets-module/editable-group-controller/editable-group.controller"
import {
  EditableObjectTypes,
  ImageAssetMetaT,
} from "../../../../types/asset.types"
import { ReplicablePatternConfigWithData } from "../modules/assets-module/pattern-replicator/types"
import { WithSnapping } from "./snapping"
import { SpaceId } from "../../../../libs/products-render-config/types"

export const DEFAULT_REMOVE_WHITE_COLOR_FILTER_DISTANCE = 0.5

export enum PackhelpGroupType {
  activeSelection = "activeSelection",
}

export type ActiveObjectType =
  | EditableObjectTypes
  | PackhelpGroupType.activeSelection

export enum IndexConfigFragments {
  BOTTOM = "bottom",
  MIDDLE = "middle",
  TOP = "top",
}

/**
 * !!! IMPORTANT !!!
 *
 * Do not change numeric values. Otherwise you will make a mess in old designs.
 */
export enum IndexConfigFragmentBottom {
  TEMP_DIELINE_SHADOW = -667,
  TEMP_DIELINE = -666,
  BACKGROUND_TEXTURE = 0,
  TEMP_BACKGROUND = 1,
  BACKGROUND_IMAGE = 1.5,
  TEMP_BACKGROUND_IMAGE = 1.51,
  GLOBAL_PATTERN = 2,
  TEMP_PATTERN = 3,
  TEMP_DIELINE_STROKE = 3,
  REPLICABLE_PATTERN = 4,
  SMART_GUIDELINES = 5,
}

export enum IndexConfigFragmentMiddle {
  FSC = 999999, // FSC badge should be always on top
}

export interface PackhelpObject extends fabric.Object {
  id: string
  rotation?: number
  colourSettings?: ColourSettings
  originSpaceArea?: SpaceId
  assetType?: EditableObjectTypes
  assetTextMeta?: TextConfigParams
  assetObjectMeta?: ShapeConfigParams
  assetImageMeta?: ImageAssetMetaT
  patternSourceConfig?: PatternSourceObject
  replicablePatternConfig?: ReplicablePatternConfigWithData
  patternSizePx: number
  clipMode: ClipPathModes
  alphaModeClipping: boolean
  filters?: any[]
  fontFamily: Fonts
  isEditable?: boolean
  isOverscaled?: boolean
  isMinScaleLimitReached?: boolean
  indexConfig: {
    fragment: IndexConfigFragments
    index: number
  }
  points?: fabric.Point[]
  getPatternSourceConfig: () => PatternSourceObject
  groupId?: string
  fillPantoneName?: string
  maskParentId?: string
  clippingMask?: PackhelpMaskObject
  collisionPoints?: fabric.Point[]
  templateId?: number
  maxWidth?: number
  maxHeight?: number
  maskType?: MaskType
  keepRatio?: boolean
  predefinedTextId?: string
  lockUniScaling?: boolean // removed from Fabric 4.0, but we use it :)
  version?: string
  isSpaceClippingDisabled?: boolean
  isSafeZoneTouched?: boolean
  isSpaceEdgeCrossed?: boolean
}

export interface VirtualDieline extends fabric.Group {
  id: string
  height: number
  width: number
  top: number
  left: number
  sceneDecoration?: SceneDecoration

  getObjects: (type?: string) => VirtualDielineSpace[]
}

export interface VirtualDielineSpace extends PackhelpObject {
  id: SpaceId
  group: VirtualDieline
  height: number
  width: number
  top: number
  left: number
  sceneDecoration?: SceneDecoration
}

export type BackgroundTexturePerMaterial = {
  materialId: string
  textureUrl: string
}

export interface SceneDecoration {
  editZone: {
    zoom: number
  }
  backgroundTextures: BackgroundTexturePerMaterial[]
}

export interface PackhelpGroup extends PackhelpObject, fabric.Group {
  getObjects: (type?: string) => PackhelpObject[]
}

export interface PackhelpMaskObject extends PackhelpObject {
  maskParentId: string
  maskType: MaskType
  assetObjectMeta: ShapeConfigParams
  borderRadius?: number
  rx?: number
  ry?: number
}

export interface PackhelpImage extends PackhelpObject, fabric.Image {
  filters: any[]
  isLogo?: boolean
  getImageThreshold: () => number
  isImageInverted: () => boolean
  isBackgroundRemoved: () => boolean
}

export interface PackhelpEditableObject extends PackhelpObject, WithSnapping {
  originSpaceArea: SpaceId
  maskController?: MaskController
  maskConfig?: MaskConfigExport
  _renderControls: (
    ctx: CanvasRenderingContext2D,
    styleOverrideOpt: Object
  ) => void
}

export interface PackhelpEditableSvgImage extends PackhelpEditableObject {
  assetImageMeta: ImageAssetMetaT
  isLogo?: boolean
}

export interface PackhelpEditableShape extends PackhelpEditableObject {
  assetObjectMeta: ShapeConfigParams
  isFsc?: boolean
}

export interface PackhelpEditableImage extends PackhelpImage, WithSnapping {
  originSpaceArea: SpaceId
  maskController?: MaskController
  maskConfig?: MaskConfigExport
  assetImageMeta: ImageAssetMetaT
  _renderControls: (
    ctx: CanvasRenderingContext2D,
    styleOverrideOpt: Object
  ) => void
}

export interface PackhelpEditableGroup extends PackhelpGroup, WithSnapping {
  originSpaceArea: SpaceId
  maskController?: MaskController
  maskConfig?: MaskConfigExport
  groupController?: EditableGroupController
  getObjects: (type?: string) => PackhelpEditableObject[]
  _renderControls: (
    ctx: CanvasRenderingContext2D,
    styleOverrideOpt: Object
  ) => void
}

export interface PackhelpEditableRect extends PackhelpEditableObject {
  rx?: number
  ry?: number
}

export interface PackhelpActiveSelection extends PackhelpEditableGroup {
  type: PackhelpGroupType.activeSelection
}

export interface PackhelpEditableText extends PackhelpEditableObject {
  text: string
  fontStyle: FontStyle
  fontWeight: FontWeight
  textAlign: TextAlign
  fontFamily: Fonts
  fontSize: number
  setSelectionStyles: (styles) => void
  isEditing: boolean
  lineHeight: number
  charSpacing: number
}

export type LogoPlaceholderState =
  | "interactive"
  | "nonInteractive"
  | "loading"
  | "designer"

export interface PackhelpEditableLogoPlaceholderSlot
  extends PackhelpEditableGroup {
  enabled: boolean
  enable: (isVisible?: boolean, state?: LogoPlaceholderState) => void
  disable: () => void
  setLoading: () => void
  setNonInteractive: () => void
  setInteractive: () => void
  placeholderReady: Promise<void> | undefined
  attachEventListeners: (options: {
    onPlusClicked: () => void
    onTrashClicked: () => void
  }) => void
}

export interface PackhelpStaticCanvas extends fabric.StaticCanvas {
  getObjects: (type?: string) => PackhelpObject[]
  wrapperEl: HTMLDivElement
  lowerCanvasEl: HTMLCanvasElement
  clipPath?: PackhelpObject
  activeSpaceId?: SpaceId | null
}

export interface PackhelpInteractiveCanvas extends fabric.Canvas {
  getObjects: (type?: string) => PackhelpObject[]
  wrapperEl: HTMLDivElement
  lowerCanvasEl: HTMLCanvasElement
  contextTop: CanvasRenderingContext2D
  clipPath?: PackhelpObject
  activeSpaceId?: SpaceId | null
}

export type PackhelpCanvas = PackhelpStaticCanvas | PackhelpInteractiveCanvas
